import React, { useState } from "react";
import { Box, makeStyles, Typography, Select, MenuItem } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import API from "apis/API";
import FormInput from "components/form/FormInput";
import Validator, { Required, validate, Custom } from "utils/Validator";
import PrimaryButton from "components/Buttons/PrimaryButton";
import {useToasts} from "react-toast-notifications";
import { red } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    background: "#FFF",
    minHeight: "100vh",
    "& > .MuiBox-root": {
      width: "100%",
      maxWidth: 1017,
      padding: 0,
      margin: "0 16px",
    },
    [theme.breakpoints.up("lg")]: {
      background: "none",
      "& > .MuiBox-root": {
        padding: "32px 0",
        margin: "0 32px",
      },
    },
  },
  pageTitle: {
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: 20,
    color: "#003366",
    margin: "24px 0",
    letterSpacing: -2,
    [theme.breakpoints.up("lg")]: {
      margin: 0,
      marginBottom: 16,
      fontSize: 32,
    },
  },
  card: {
    background: "#FFFFFF",
    padding: "0",
    [theme.breakpoints.up("lg")]: {
      borderRadius: 8,
      border: "1px solid #DDE3EE",
      padding: "38px 48px 18px",
    },
  },
  title: {
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: 20,
    color: "#454D5F",
    marginBottom: 42,
    display: "none",
    [theme.breakpoints.up("lg")]: {
      display: "flex",
    },
  },
  field: {
    marginBottom: 16,
  },
  button: {
    margin: 0,
    width: "100%",
    marginTop: 24,
    color: "white",
  },
  error: {
    color: "red",
    padding: 0,
    margin: 0,
  },
  placeHolder: {
    opacity: 0.7
  }
}));

const Reports = () => {
  const { t } = useTranslation();
  const {addToast} = useToasts();
  const classes = useStyles();
  const [errors, setErrors] = useState(null);
  const [data, setData] = useState({
    columns: [],
  });

  const columns = [
    { id: 'patient_id', name: 'Nr pacjenta' },
    { id: 'probe_code', name: 'Nr Próbki' },
    { id: 'type_of_probe', name: 'Rodzaj badanego materiału' },
    { id: 'patients_group', name: 'Numer Grupy' },
    { id: 'biochemistry_results_alt', name: 'ALT' },
    { id: 'biochemistry_results_ast', name: 'AST' },
    { id: 'biochemistry_results_creatine', name: 'Kreatynina' },
    { id: 'biochemistry_results_bilirubine', name: 'Bilirubina' },
    { id: 'first_medicine_concentrations_result', name: 'Lek 1' },
    { id: 'second_medicine_concentrations_result', name: 'Lek 2' },
    { id: 'third_medicine_concentrations_result', name: 'Lek 3' },
    { id: 'visit_results_qrs', name: 'QRS' },
    { id: 'visit_results_qtcb', name: 'QTCB' },
  ];

  const checkLength = () => {
    if (data.columns.length === 0) {
      return t("reports.empty");
    } else if (data.columns.length < 2) {
      return t("reports.notValid");
    } else {
      return "";
    }
  }

  const Validators = {
    columns: new Validator(Custom(checkLength)),
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setData(prev => ({ ...prev, [name]: value }));
  }

  const handleGenerate = () => {
    let _errors = validate(data, Validators);
    setErrors(_errors);
    if (_errors) return;
    const blobInterceptor = API.interceptors.request.use(
      (config) => {
        config.responseType = 'blob';
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );
    API.generateReports(data).then(res => {
      API.interceptors.request.eject(blobInterceptor);
      var fileURL = window.URL.createObjectURL(new Blob([res.data]));
      var fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", 'data.xlsx');
      document.body.appendChild(fileLink);
      fileLink.click();
    })
    .catch((error) => {
      addToast(t("reports.fail"), { appearance: "error" });
    });
  }

  return (
    <Box className={classes.root}>
      <Box>
        <Typography className={classes.pageTitle}>
          {t("reports.title")}
        </Typography>
        <Box className={classes.card}>
          <Select
            value={data?.columns}
            multiple
            displayEmpty={true}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <em className={classes.placeHolder}>{t("reports.empty")}</em>;
              } else {
                const result = selected.map(id => {
                  let name;
                  columns.map(item => {
                    if (id === item.id) {
                      name = item.name;
                    }
                  });
                  return name;
                });
                return result.join(', ');
              }
            }}
            fullWidth
            name="columns"
            onChange={handleChange}
            variant="outlined"
            className={classes.field}
            // error={!!errors?.columns}
          >
            <MenuItem disabled value="">
              <em>{t("reports.empty")}</em>
            </MenuItem>
            {columns.map((column) => {
              return (
                <MenuItem key={column.id} value={column.id}>
                  {column.name}
                </MenuItem>
              );
            })}
          </Select>
          {errors?.columns ? <p className={classes.error}>{errors?.columns}</p> : ''}
          <PrimaryButton
            className={classes.button}
            onClick={handleGenerate}
          >
            {t('reports.title')}
          </PrimaryButton>
        </Box>
      </Box>
    </Box>
  );
};

export default Reports;
