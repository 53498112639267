import React from "react";
import {
  Box,
  Button,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import SortableIcon from "assets/images/icons/sort.svg";
import * as Actions from "store/actions/CardAction";
import Routes from "router/Routes";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTable-root": {
      "& .MuiTableHead-root": {
        "& .MuiTableCell-root": {
          background: "#F7F8FB",
          "& > .MuiBox-root": {
            display: "flex",
            "& .MuiTypography-root": {
              fontSize: 10,
              fontWeight: 700,
              textTransform: "uppercase",
              color: "#454D5F",
              marginRight: 8,
            },
            "& img": {
              cursor: "pointer",
            },
          },
        },
      },
      "& .MuiTableBody-root": {
        "& .MuiTableRow-root": {
          "&:hover": {
            background: "#FFF3F2",
          },
          "& .MuiTableCell-root": {
            fontSize: 16,
            fontWeight: 700,
            color: "#003366",
            "& .MuiTypography-root": {
              fontSize: 16,
              fontWeight: 700,
              color: "#003366",
            },
          },
        },
      },
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  peselTxt: {
    fontSize: "14px!important",
    color: "#6C7689!important",
    fontWeight: "400!important",
  },
  lekiTxt: {
    fontSize: "16px!important",
    color: "#22272F!important",
    fontWeight: "400!important",
  },
  limitTxt: {
    fontSize: "14px!important",
    color: "#6E0C0C!important",
  },
  patientCardButton: {
    height: 56,
    background: "#003366",
    borderRadius: 8,
    fontSize: 16,
    color: "#FFF",
    fontWeight: 700,
    textTransform: "none",
    padding: "0 24px",
    whiteSpace: "nowrap",
    "&:hover": {
      background: "#003366",
    },
  },
  appointmentButton: {
    padding: "0 24px",
    textTransform: "none",
    height: 56,
    border: "1px solid #003366",
    background: "#FFF",
    borderRadius: 8,
    color: "#003366",
    fontSize: 16,
    fontWeight: 700,
    "& img": {
      marginRight: 8,
    },
  },
}));

const DataTable = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const cols = [
    { id: "id", label: "ID", sortable: true },
    { id: "surname", label: "Nazwisko i imię", sortable: true },
    { id: "gr", label: "GR." },
    { id: "email", label: "Dane kontaktowe", sortable: true },
    { id: "address_street", label: "adres Zamieszkania", sortable: true },
    { id: "lastVisitedAt", label: "OSTATNIA wizyta", sortable: true },
    { id: "nextVisitAt", label: "następna wizyta", sortable: true },
    { id: "action", label: "KARTA PACJENTA" },
  ];

  const getMedicines = (arr, patient_inclusion_criteria_sub_group) => {
    const clonedArr = [];
    arr.map((medicine) => clonedArr.push(medicine.medicine.name));

    if (patient_inclusion_criteria_sub_group?.subgroup?.medicines !== "")
    {
      clonedArr.push(patient_inclusion_criteria_sub_group?.subgroup?.medicines)
    }
    
    return clonedArr.join(", ");
  };

  const handleViewCardPage = (id) => {
    dispatch(Actions.setSelectedPatientID(id));
    history.push(Routes.Patients.Card(id));
  };

  return (
    <TableContainer className={classes.root}>
      <Table>
        <TableHead>
          <TableRow>
            {cols.map((col) => {
              return (
                <TableCell key={col.id}>
                  <Box>
                    <Typography>{col.label}</Typography>
                    {col.sortable && (
                      <img
                        src={SortableIcon}
                        alt=":( Not Found"
                        onClick={() => props.handleSort(col.id)}
                      />
                    )}
                  </Box>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row, index) => {
            return (
              <TableRow key={row.id}>
                <TableCell>{row.id}</TableCell>
                <TableCell>
                  <Typography>
                    {row.surname} {row.firstname}
                  </Typography>
                  <Typography className={classes.peselTxt}>
                    PESEL: {row.pesel}
                  </Typography>
                  <Typography>
                    {
                      row.patient_inclusion_criteria_sub_group?.group?.name
                    }
                  </Typography>
                  <Typography>
                    {row.patient_inclusion_criteria_sub_group && (
                      <>
                        {
                          row.patient_inclusion_criteria_sub_group?.subgroup
                            ?.name
                        }{" "}
                        -{" "}
                        {
                          row.patient_inclusion_criteria_sub_group?.subgroup
                            ?.description
                        }
                      </>
                    )}
                  </Typography>
                  <Typography>Leki:</Typography>
                  <Typography className={classes.lekiTxt}>
                    {row.medicines && getMedicines(row.medicines, row.patient_inclusion_criteria_sub_group)}
                  </Typography>
                  {/* {row.limitDate && (
                    <Typography className={classes.limitTxt}>
                      Zakończenie udziału: {row.limitDate}
                    </Typography>
                  )} */}
                </TableCell>
                <TableCell>
                  {row.patient_inclusion_criteria_sub_group?.group?.nr}
                </TableCell>
                <TableCell>
                  {row.phone_number}
                  <br />
                  {row.email}
                </TableCell>
                <TableCell>
                  <Typography>{row.address_postcode ? row.address_postcode + ", " : ""} {row.address_city}</Typography>
                  <Typography>{row.address_street}</Typography>
                </TableCell>
                <TableCell>
                  {row.past_visit_date ? (
                    <>
                      {moment(row.past_visit_date.date).format("DD.MM.YYYY")}
                      <br />
                      {row.past_visit_date.started_at.slice(0, -3)}
                    </>
                  ) : (
                    <>-</>
                  )}
                </TableCell>
                <TableCell>
                  {row.next_visit_date ? (
                    <>
                      {moment(row.next_visit_date.date).format("DD.MM.YYYY")}
                      <br />
                      {row.next_visit_date.started_at.slice(0, -3)}
                    </>
                  ) : (
                    <>-</>
                  )}
                </TableCell>
                <TableCell>
                  <Button
                    className={classes.patientCardButton}
                    onClick={() => handleViewCardPage(row.id)}
                  >
                    Karta pacjenta
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DataTable;
