import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  makeStyles,
  LinearProgress,
  TableCell,
  TableRow,
  Typography,
  MenuItem,
  Checkbox,
  Select
} from "@material-ui/core";
import moment from "moment";
import _ from 'lodash';

import API from "apis/API";
import Routes from "router/Routes";
// import Select from "components/form/Select";
import DataTable from "components/DataTable";
import Title from "components/text/Title";
import PrimaryButton from "components/Buttons/PrimaryButton";
import RangePickerSelect from "../../components/form/RangePickerSelect";
import DateRangePickerModal from "./DateRangePickerModal";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "22px 32px",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2, 0, 10),
      background: "#FFF",
    },
  },
  toolbar: {
    padding: theme.spacing(0, 2),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 26,
    [theme.breakpoints.down("md")]: {
      marginBottom: 16,
    },
  },
  dateCell: {
    textAlign: "center",
    '& .MuiTypography-colorTextSecondary': {
      color: '#6C7689 !important'
    }
  },
  filterSelect: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginRight: 10,
    width: 250
  },
  loadingTable: {
    display: 'none',
  },
  formControl: {
    width: 392,
    marginRight: 24,
    "& .MuiInputBase-root": {
        width: "100%",
        "& .MuiSelect-root": {
            fontSize: 16,
            color: "#22272F",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #A6AEBF",
            borderRadius: 8,
        },
    },
    [theme.breakpoints.down("md")]: {
        width: "100%",
    },
  },
}));

const ProbesList = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const columns = [
    { id: 'date_of_sample', label: 'Data utworzenia', sortable: true },
    { id: 'code', label: 'Numer próbki', sortable: true },
    { id: 'patient_id', label: 'Pacjent', sortable: true },
    { id: 'type_of_material', label: 'Rodzaj materiału', sortable: true },
    { id: 'shipment.status', label: 'Status Przesyłki', sortable: false },
    { id: 'action', label: '' },
  ];
  const dateRangeList = [
    { value: 'from_beginning', label: t('audit_trial.from_beginning') },
    { value: 'yesterday', label: t('audit_trial.yesterday') },
    { value: 'today', label: t('audit_trial.today') },
    { value: 'last_7days', label: t('audit_trial.last_7days') },
    { value: 'last_14days', label: t('audit_trial.last_14days') },
    { value: 'last_30days', label: t('audit_trial.last_30days') },
    { value: 'custom_date_range', label: t('audit_trial.custom_date_range') }
  ];
  const [patients, setPatients] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [additionalFilters, setAdditionalFilters] = useState({
    patientIds: []
  });
  const [dateRangeValue, setDateRangeValue] = useState('from_beginning');
  const [openDateRangeModal, setOpenDateRangeModal] = useState(false);

  const [selectedPatients, setSelectedPatients] = useState([]);

  useEffect(() => {
    API.patient.all().then(res => setPatients(res.data?.data));
  }, []);

  useEffect(() => {
    loadFileterdSets();
  }, [filters]);

  const loadFileterdSets = () => {
    API.probes.index({...additionalFilters, ...filters}).then(res => {
      setData(res.data.data);
      setTotalCount(res.data.total);
    });
  };

  useEffect(() => {
    setAdditionalFilters(prev => {
        prev = {...prev, patientIds: selectedPatients?.map(patient => patient.id).join(",")};
        handleChangeFilters(prev);
        return prev;
    });
  }, [selectedPatients]);

  // const handleChangeDateRange = e => {
  //   const target = e.target;
  //   setDateRangeValue(target.value);

  //   let dateRange = null;
  //   let today = moment();
  //   let format = 'YYYY-MM-DD';
  //   let todayStr = today.format(format);
  //   switch (target.value) {
  //     case 'yesterday':
  //       dateRange = { from: today.subtract(1, 'days').format(format), to: todayStr };
  //       break;
  //     case 'today':
  //       dateRange = { from: todayStr, to: todayStr };
  //       break;
  //     case 'last_7days':
  //       dateRange = { from: today.subtract(1, 'week').format(format), to: todayStr };
  //       break;
  //     case 'last_14days':
  //       dateRange = { from: today.subtract(2, 'week').format(format), to: todayStr };
  //       break;
  //     case 'last_30days':
  //       dateRange = { from: today.subtract(1, 'month').format(format), to: todayStr };
  //       break;
  //     case 'custom_date_range':
  //       setOpenDateRangeModal(true);
  //       return;
  //     default:
  //       dateRange = null;
  //   }
  //   handleCloseDateRangeModal(dateRange);
  // }

  const handleChangeDateRange = date => {
    setAdditionalFilters(prev => {
      prev = {...prev, 'from_date': date?.from, 'to_date': date?.to};
      handleChangeFilters(prev);
      return prev;
    });
  };

  const handleCloseDateRangeModal = dateRange => {
    setOpenDateRangeModal(false);
    setAdditionalFilters(prev => {
      prev = { ...prev, 'from_date': dateRange?.from, 'to_date': dateRange?.to };
      handleChangeFilters(prev);
      return prev;
    });
  }

  const handleChangeAdditionalFilters = e => {
    const target = e.target;
    setAdditionalFilters(prev => {
      prev = { ...prev, [target.name]: target.value };
      handleChangeFilters(prev);
      return prev;
    });
  }

  const handleChangeFilters = _filters => {
    if (!_.isEqual(_filters, filters)) {
      setFilters(_filters);
    }
    // setIsLoading(true);
    // API.probes.index({ ...additionalFilters, ...filters, ..._filters }).then(res => {
    //   setData(res.data.data);
    //   setTotalCount(res.data.total);
    //   setIsLoading(false);
    // });
  }

  const renderPatientsValue = () => {
    let preparedString = '';

    selectedPatients?.forEach((selected, index, arr) => {
      let lastIndex = index === arr.length - 1;

      if (selectedPatients?.length > 1 && !lastIndex) {
        preparedString += selected?.firstname + ' ' + selected?.surname + ', ';
        return;
      }

      return preparedString += selected?.firstname + ' ' + selected?.surname + '.';
    });

    return preparedString;
  };

  const handleChangePatient = (patient) => {
    if (!!selectedPatients?.find(selected => selected?.id === patient.id)) {
      setSelectedPatients(selectedPatients?.filter(selected => selected?.id !== patient.id));
      return;
    }

    setSelectedPatients([...selectedPatients, patient]);
  };

  const renderAdditionalFilters = filters => {
    setFilters(filters);
    return (
      <Box display="flex">
        <Box className={classes.formControl}>
          <Typography className={classes.formLabel}>{t('patient')}</Typography>
          <Select
            value={selectedPatients}
            multiple
            variant="outlined"
            renderValue={renderPatientsValue}
          >
            {patients.map((patient) => {
              return (
                <MenuItem
                  key={patient.id}
                  value={patient.id}
                  onClick={() => handleChangePatient(patient)}
                >
                  <Checkbox
                    color="primary"
                    checked={!!selectedPatients?.find(selectedPatient => selectedPatient.id === patient.id)}
                  />
                  <Typography>
                    {patient.firstname + ' ' + patient.surname}
                  </Typography>
                </MenuItem>
              )
            })}
          </Select>
        </Box>
        <Box className={classes.formControl}>
          <Typography className={classes.formLabel}>{t('date_range')}</Typography>
          <RangePickerSelect onRangeSelect={handleChangeDateRange}/>
        </Box>
      </Box>
    );
  }

  const renderRows = () => (
    data.map(row => (
      <TableRow key={row.id}>
        <TableCell className={classes.dateCell}>
          <Typography>{moment(row.created_at).format('DD.MM.YYYY')}</Typography>
          <Typography>{moment(row.created_at).format('HH:mm')}</Typography>
        </TableCell>
        <TableCell>{row?.code}</TableCell>
        <TableCell>{row?.patient?.surname} {row?.patient?.firstname}</TableCell>
        <TableCell>
          <Typography>{t(`probes.${row.type_of_material}`)}</Typography>
          {/*<Typography>{row?.laboratory?.postcode}, {row?.laboratory?.city}</Typography>*/}
        </TableCell>
        {/*<TableCell>
          <Typography>{row?.collect_place == 'home' ? 'do pobrania w domu' : 'do pobrania w przychodni'}</Typography>
        </TableCell>*/}
        <TableCell>
          <Typography>{row?.set?.shipment?.last_status ? row?.set?.shipment?.last_status : '-'}</Typography>
        </TableCell>
        <TableCell>
          <Link to={Routes.Probes.Show(row.id)}>
            <PrimaryButton title={t('probes.detail')} />
          </Link>
        </TableCell>
      </TableRow>
    ))
  );

  return (
    <Box className={classes.root}>
      <Box className={classes.toolbar}>
        <Title>{t('probes.list')}</Title>
      </Box>
      {isLoading && <LinearProgress />}
      <Box className={ isLoading ? classes.loadingTable : '' }>
        <DataTable
          searchLabel={t('probes.search')}
          searchPlaceholder={t('probes.search_placeholder')}
          columns={columns}
          totalCount={totalCount}
          onChangeFilters={handleChangeFilters}
          renderRows={renderRows}
          renderAdditionalFilters={renderAdditionalFilters}
        />

        <DateRangePickerModal
          openModal={openDateRangeModal}
          onClose={handleCloseDateRangeModal}
        />
      </Box>
    </Box>
  );
};

export default ProbesList;
