import React from "react";
import {
  Box,
  makeStyles,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import SortableIcon from "assets/images/icons/sort.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTable-root": {
      "& .MuiTableHead-root": {
        "& .MuiTableCell-root": {
          background: "#F7F8FB",
          "& > .MuiBox-root": {
            display: "flex",
            "& .MuiTypography-root": {
              fontSize: 10,
              fontWeight: 700,
              textTransform: "uppercase",
              color: "#454D5F",
              marginRight: 8,
            },
            "& img": {
              cursor: "pointer",
            },
          },
        },
      },
      "& .MuiTableBody-root": {
        "& .MuiTableRow-root": {
          "&:hover": {
            background: "#FFF3F2",
          },
          "& .MuiTableCell-root": {
            fontSize: 16,
            fontWeight: 700,
            color: "#003366",
            "& .MuiTypography-root": {
              fontSize: 16,
              fontWeight: 700,
              color: "#003366",
            },
          },
        },
      },
    },
  },
  mobileHead: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  }
}));

const Table = props => {
  const { columns, onSort, renderRows, mobileMode } = props;
  const classes = useStyles();

  return (
    <TableContainer className={classes.root}>
      <MuiTable>
        <TableHead className={clsx({ [classes.mobileHead]: mobileMode })}>
          <TableRow>
            {columns.map(col => (
              <TableCell key={col.id}>
                <Box>
                  <Typography>{col.label}</Typography>
                  {col.sortable && (
                    <img
                      src={SortableIcon}
                      alt=":( Not Found"
                      onClick={() => onSort(col.id)}
                    />
                  )}
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {renderRows()}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};

export default Table;
