import React, { useEffect, useState } from "react";
import { makeStyles, Modal as MuiModal, Backdrop, Fade, Card, Typography, Box, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import CloseIcon from "assets/images/icons/close.svg";
import IconButton from "components/Buttons/IconButton";
import FormInput from "components/form/FormInput";
import PrimaryButton from "components/Buttons/PrimaryButton";

import Validator, { Required, validate } from 'utils/Validator';
import DefaultButton from "components/Buttons/DefaultButton";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    padding: "25px",
    width: "500px",
  },
  title: {
    fontWeight: "bold",
    fontSize: 20,
    lineHeight: 1.4,
    color: "#003366"
  },
  subTitle: {
    fontWeight: "bold",
    color: "#454D5F",
    marginBottom: theme.spacing(1)
  },
}));

const Modal = props => {
  const { openModal, onClose, onSave, editingData } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const [data, setData] = useState();
  const [errors, setErrors] = useState(null);

  const Validators = {
    name: new Validator(Required),
    street: new Validator(Required),
    building_nr: new Validator(Required),
    postcode: new Validator(Required),
    city: new Validator(Required),
  };

  useEffect(() => {
    setData(prev => {
      prev = editingData
        ? editingData
        : {
          name: '',
          street: '',
          building_nr: '',
          postcode: '',
          city: '',
        }
      return prev;
    });
    setErrors(null);
  }, [editingData]);

  const handleSave = () => {
    let _errors = validate(data, Validators);
    setErrors(_errors);
    if (_errors) return;

    onSave(data);
    onClose();
  }

  const handleChange = e => {
    const target = e.target;
    setData(prev => ({ ...prev, [target.name]: target.value }));
  }

  return (
    <MuiModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openModal}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModal}>
        <Card className={classes.paper}>
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography className={classes.title}>{editingData ? t('units.edit') : t('units.add')}</Typography>
            <IconButton icon={CloseIcon} onClick={onClose} />
          </Box>
          <Typography className={classes.subTitle}>{t('personal_information')}</Typography>

          <FormInput
            name="name"
            title={t('units.name')}
            value={data?.name}
            onChange={handleChange}
            error={errors?.name}
            required
          />

          <Typography className={classes.subTitle}>{t('address')}</Typography>
          <FormInput
            name="street"
            title={t('street')}
            value={data?.street}
            onChange={handleChange}
            error={errors?.street}
            required
          />
          <Grid container justifyContent="space-between">
            <Grid item sm={5} xs={12}>
              <FormInput
                name="building_nr"
                title={t('building_nr')}
                value={data?.building_nr}
                onChange={handleChange}
                error={errors?.building_nr}
                required
              />
            </Grid>
            <Grid item sm={5} xs={12}>
              <FormInput
                name="postcode"
                title={t('postcode')}
                value={data?.postcode}
                onChange={handleChange}
                error={errors?.postcode}
                required
              />
            </Grid>
          </Grid>
          <FormInput
            name="city"
            title={t('city')}
            value={data?.city}
            onChange={handleChange}
            error={errors?.city}
            required
          />
          <Box display="flex" justifyContent="flex-end">
            <DefaultButton
              title={t('cancel')}
              onClick={onClose}
            />
            <PrimaryButton
              title={t('save')}
              onClick={handleSave}
            />
          </Box>
        </Card>
      </Fade>
    </MuiModal>
  );
};

export default Modal;
