import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Routes from "./Routes";
import Admin from "layouts/Admin";

import Login from "pages/Auth/Login";
import UnitsList from "pages/Units/List";
import UnitCalendar from "pages/Units/Calendar";
import DoctorsList from "pages/Doctors/List";
import AdminsList from "pages/Admins/List";
import PatientList from "pages/Patients/List";
import CreatePatient from "pages/Patients/Create";
import UpdatePatient from "pages/Patients/Update";
import PatientCard from "pages/Patients/Card/index";
import ProbesList from "pages/Probes/List";
import ProbesShow from "pages/Probes/Detail";
import AuditTrialList from "pages/AuditTrial/List";
import ProbeConfig from "pages/Settings/ProbeConfig";
import ProbeSetsList from "pages/ProbeSets/List";
import ProbeSetsShow from "pages/ProbeSets/Detail";
import Documents from "pages/Settings/Documents";
import DataImport from "pages/DataImport";
import MessagesList from "pages/Messages/List";
import VisitDetail from "components/tabs/Visits/VisitDetail";
import Synevo from "pages/Settings/Synevo";
import Phone from "pages/Settings/Phone";
import ChangePassword from "pages/ChangePassword";
import Reports from "pages/Reports";

const AdminLayoutRoutes = [
  {
    component: UnitsList,
    path: Routes.Units.List,
  },
  {
    component: UnitCalendar,
    path: Routes.Units.Calendar(),
  },
  {
    component: DoctorsList,
    path: Routes.Doctors.List,
  },
  {
    component: AdminsList,
    path: Routes.Admins.List,
  },
  {
    component: PatientList,
    path: Routes.Patients.List,
  },
  {
    component: CreatePatient,
    path: Routes.Patients.Create,
  },
  {
    component: UpdatePatient,
    path: Routes.Patients.Edit(),
  },
  {
    component: PatientCard,
    path: Routes.Patients.Card(),
  },
  {
    component: ProbesList,
    path: Routes.Probes.List,
  },
  {
    component: ProbesShow,
    path: Routes.Probes.Show(),
  },
  {
    component: AuditTrialList,
    path: Routes.AuditTrial.List,
  },
  {
    component: ProbeConfig,
    path: Routes.Settings.ProbeConfig,
  },
  {
    component: Documents,
    path: Routes.Settings.Documents(),
  },
  {
    component: ProbeSetsList,
    path: Routes.ProbeSets.List
  },
  {
    component: ProbeSetsShow,
    path: Routes.ProbeSets.Show()
  },
  {
    component: DataImport,
    path: Routes.DataImport.List
  },
  {
    component: MessagesList,
    path: Routes.Messages.List
  },
  {
    component: VisitDetail,
    path: Routes.Patients.Visit()
  },
  {
    component: Synevo,
    path: Routes.Settings.Synevo
  },
  {
    component: Phone,
    path: Routes.Settings.Phone
  },
  {
    component: ChangePassword,
    title: "Dane osobowe i medyczne",
    path: Routes.ChangePassword,
  },
  {
    component: Reports,
    title: "Generuj raport",
    path: Routes.Reports,
  },
];

const AdminLayout = () => {
  return (
    <Switch>
      <Admin>
        {AdminLayoutRoutes.map((route, index) => <Route key={index} exact {...route} />)}
      </Admin>
    </Switch>
  );
};

const WithoutLayoutRoutes = [
  {
    component: Login,
    title: "Login",
    path: Routes.Auth.Login,
  },
];

const WithoutLayout = () => {
  return (
    <Switch>
      {WithoutLayoutRoutes.map((route, index) => <Route key={index} exact {...route} />)}
    </Switch>
  );
};

const RoutesRouter = () => {
  return (
    <Switch>
      <Redirect exact from="/" to={Routes.Auth.Login} />
      <Route
        path={AdminLayoutRoutes.map((route) => route.path)}
        exact
        component={AdminLayout}
      />
      <Route
        path={WithoutLayoutRoutes.map((route) => route.path)}
        exact
        component={WithoutLayout}
      />
      <Redirect to={Routes.Auth.Login} />
    </Switch>
  );
};

export default RoutesRouter;
