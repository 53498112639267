import React from "react";
import {
  Modal,
  Backdrop,
  Fade,
  Grid,
  Card,
  Button
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    padding: "25px",
    width: "400px",
  },
  button: {
    width: "100%",
    height: "100%",
    "& .MuiButton-label": {
      textTransform: "none",
      fontSize: "0.9375em",
    },
    "&:hover": {
      backgroundColor: "#003366",
    },
    padding: "4px",
    borderRadius: "8px",
    backgroundColor: "#003366",
    color: "#fff"
  },
}));

const DeleteModal = (props) => {
  const { openModal, onClose, onDelete, title } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openModal}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModal}>
        <Card className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={12}>{title}</Grid>
            <Grid item xs={6}>
              <Button variant="contained" className={classes.button} onClick={() => {
                onDelete();
                onClose();
              }}>
                {t('yes')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button variant="contained" className={classes.button} onClick={() => {
                onClose();
              }}>
                {t('no')}
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Fade>
    </Modal>
  );
};

export default withRouter(DeleteModal);
