import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  makeStyles,
  Typography,
} from "@material-ui/core";

import TrashIcon from "assets/images/icons/trash.svg";
import FileImage from "assets/images/pages/patient/file.png";

import API from "apis/API";
import formatFileSize from "utils/formatFileSize";
import Title from "components/text/Title";
import Dropzone from "components/form/Dropzone";
import PrimaryButton from "components/Buttons/PrimaryButton";
import Modal from "./Modal";
import { useHistory } from "react-router-dom";
import Storage from "utils/Storage";
import Routes from "router/Routes";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    maxWidth: 1200,
    margin: 'auto',
  },
  card: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(5),
    border: '1px solid #DDE3EE',
    borderRadius: theme.spacing(1),
  },
  subTitle: {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: 1.4,
    color: '#454D5F'
  },
  file: {
    display: "flex",
    alignItems: "flex-start",
    marginBottom: 24,
    "& img": {
      width: 31,
      marginRight: 14,
    },
    [theme.breakpoints.up("lg")]: {
      "& img": {
        width: 62,
        marginRight: 24,
      },
    },
  },
  name: {
    fontWeight: 700,
    fontSize: 14,
    color: "#003366",
    textDecoration: "underline",
    marginBottom: 3,
    cursor: "pointer",
    "&:hover": {
      opacity: 0.8,
    },
  },
  deleteFile: {
    marginTop: 20,
    marginRight: 8,
    width: 24,
    height: 24,
    minWidth: 24,
    border: "1px solid #DDE3EE",
    background: "#F7F8FB",
    borderRadius: 6,
    "& img": {
      width: 12.5,
      height: 12.5,
    },
  },
}));

const DataImport = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const [saving, setSaving] = useState(false);
  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (JSON.parse(Storage.get("user")).changed_password_at === null)
      history.push(Routes.ChangePassword);
  }, []);

  const handleSelectFile = file => {
    setFiles(prev => {
      prev.push(file[0]);
      return [...prev];
    });
  };

  const handleDeleteFile = index => {
    setFiles(prev => {
      prev.splice(index, 1);
      return [...prev];
    });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setErrors(null);
  }

  const handleSave = type => () => {
    setSaving(true);
    let formData = new FormData();
    files.map((file, i) => formData.append(`files[${i}]`, file));
    API.dataImport.upload(formData, type).then((res) => {
      if(res.data.status === 'error') {
        setErrors(res.data.errors);
      }
      setSaving(false);

      if (type != 'flush')
      {
        setOpenModal(true);
      }
    });
  };

  return (
    <>
      <Box className={classes.root}>
        <Title>{t('data_import.list')}</Title>
        <Box className={classes.card}>
          <Typography className={classes.subTitle}>
            {t('data_import.import_file')}
          </Typography>

          <Box mt={2} mb={5}>
            <Dropzone
              accept=".xlsx,.xls,.xlsm,.zip,.csv,.xml"
              multiple={true}
              files={files}
              onChange={handleSelectFile}
            />
          </Box>
          {files.map((file, index) => (
            <Box display="flex" key={index}>
              <Button
                className={classes.deleteFile}
                onClick={() => handleDeleteFile(index)}
              >
                <img src={TrashIcon} alt="Delete file" />
              </Button>
              <Box className={classes.file}>
                <img src={FileImage} alt="File" />
                <Box>
                  <Typography className={classes.name}>
                    {file.name}
                  </Typography>
                  <Typography color="textSecondary">{formatFileSize(file.size)}</Typography>
                </Box>
              </Box>
            </Box>
          ))}
          <Box display="flex" alignItems="center" justifyContent="flex-end" mr={-1}>
            <PrimaryButton
              title={t('data_import.import_flush_data')}
              onClick={handleSave('flush')}
              disabled={saving}
            />
            <PrimaryButton
              title={t('data_import.import_results')}
              onClick={handleSave('results')}
              disabled={saving}
            />
            <PrimaryButton
              title={t('data_import.import_patient_data')}
              onClick={handleSave('patient_data')}
              disabled={saving}
            />
          </Box>
        </Box>
      </Box>
      <Modal
        openModal={openModal}
        onClose={handleCloseModal}
        errors={errors}
      />
    </>
  );
};

export default DataImport;
