import React, {useEffect, useState, useCallback} from "react";
import {useTranslation} from "react-i18next";
import _debounce from "lodash/debounce";
import {Box, Card, InputAdornment, Typography, TextField, Button, makeStyles} from "@material-ui/core";
import clsx from "clsx";
import SearchIcon from "assets/images/icons/search.svg";
import ArrowLeftIcon from "assets/images/icons/arrow-left.svg";
import ArrowRightIcon from "assets/images/icons/arrow-right.svg";
import Select from "components/form/Select";
import Table from "./Table";

const useStyles = makeStyles(theme => ({
    card: {
        background: "#FFF",
        border: "1px solid #DDE3EE",
        borderRadius: 8,
        boxShadow: "none",
        [theme.breakpoints.down("md")]: {
            border: "none",
        },
    },
    cardToolbar: {
        padding: 24,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid #DDE3EE",
        "& .MuiTypography-root": {
            fontWeight: 700,
            fontSize: 20,
            color: "#003366",
            marginRight: 16,
            whiteSpace: "nowrap"
        },
        "& .MuiTextField-root": {
            width: "100%",
            minWidth: 343,
            maxWidth: 360,
            borderRadius: 8,
            "& .MuiInputBase-input": {
                fontSize: 16,
            },
            background: "#F7F8FB",
            "& .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #DDE3EE",
                borderRadius: 8,
            },
        },
        "& .MuiButton-root": {
            color: "#003366",
            background: "#F7F8FB",
            padding: "10px 50px",
            border: "1px solid #DDE3EE",
            borderRadius: 10,

            "& .MuiButton-label": {
                fontWeight: 500,
                fontSize: 18.55,
                color: "#003366",
                textTransform: "initial"
            }
        },
        [theme.breakpoints.down("md")]: {
            flexWrap: "wrap",
            padding: theme.spacing(1, 2),
            "& .MuiTypography-root": {
                display: "none",
            },
        },
    },
    filterToolbar: {
        padding: "13px 32px 16px",
        borderBottom: "1px solid #DDE3EE",
        "& > .MuiTypography-root": {
            fontSize: 20,
            color: "#003366",
            fontWeight: 700,
            marginBottom: 3,
        },
        [theme.breakpoints.down("md")]: {
            "& > .MuiBox-root": {
                flexDirection: "column",
            },
        },
    },
    cardFooter: {
        padding: theme.spacing(3, 4),
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        [theme.breakpoints.down("md")]: {
            justifyContent: "flex-end",
            padding: theme.spacing(2),
            borderBottom: "1px solid #DDE3EE",
        },
        "& .MuiBox-root:first-child": {
            marginBottom: 0,
        }
    },
    mobileCardFooter: {
        [theme.breakpoints.down("md")]: {
            '& .MuiBox-root:first-child .MuiTypography-root': {
                display: 'none',
            }
        },
    },
    pagination: {
        display: "flex",
        alignItems: "center",
        "& .MuiButton-root": {
            minWidth: 24,
            width: 24,
            height: 24,
            background: "#F7F8FB",
            border: "1px solid #DDE3EE",
            "& img": {
                width: 7,
            },
            "&.Mui-disabled": {
                opacity: 0.7
            }
        },
        "& .MuiTypography-root": {
            fontSize: 14,
            color: "#454D5F",
            margin: "0 10px",
        },
    },
}));

const DataTable = props => {
    const {
        columns,
        totalCount,
        searchLabel,
        searchPlaceholder,
        onChangeFilters,
        renderRows,
        renderAdditionalFilters,
        mobileMode,
    } = props;
    const {t} = useTranslation();
    const classes = useStyles();

    const [openFilters, setOpenFilters] = useState(false);
    const [filters, setFilters] = useState({
        limit: 20,
        page: 1,
        order_by: "id",
        order_direction: "DESC",
        search: "",
    });
    const [startIndex, setStartIndex] = useState(1);
    const [endIndex, setEndIndex] = useState(1);

    const handleChangePage = flag => {
        setFilters(prev => ({
            ...prev,
            page: prev.page + flag
        }));
    };

    const handleChangeSort = flag => {
        setFilters(prev => ({
            ...prev,
            order_by: flag,
            order_direction: prev.order_by === flag ? (prev.order_direction === 'ASC' ? 'DESC' : 'ASC') : 'ASC',
        }));
    };

    const handleChangeFilters = e => {
        const target = e.target;
        setFilters(prev => ({...prev, [target.name]: target.value}));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleChangeSearch = useCallback(_debounce(value => {
        handleChangeFilters({target: {name: 'search', value}});
    }, 500), []);

    useEffect(() => {
        setStartIndex(prev => {
            prev = (filters.page - 1) * filters.limit + 1
            return prev;
        });

        setEndIndex(prev => {
            prev = totalCount < filters.limit ? totalCount : filters.limit * filters.page;
            return prev;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
        onChangeFilters(filters);
    }, [totalCount, filters]);

    return (
        <Card className={classes.card}>
            <Box className={classes.cardToolbar}>
                <Box display="flex" alignItems="center">
                    <Typography>{searchLabel}:</Typography>
                    <TextField
                        variant="outlined"
                        placeholder={searchPlaceholder}
                        onChange={e => handleChangeSearch(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <img src={SearchIcon} alt=":( Not Found"/>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                {renderAdditionalFilters &&
                    <Button onClick={() => setOpenFilters(prev => !prev)}>
                        {openFilters ? 'Zamknij filtry' : 'Otwórz filtry'}
                    </Button>
                }
            </Box>
            {renderAdditionalFilters && (
                <Box className={classes.filterToolbar} style={{display: openFilters ? '' : 'none'}}>
                    <Typography>Filtry:</Typography>
                    {renderAdditionalFilters(filters)}
                </Box>
            )}
            <Table
                columns={columns}
                renderRows={renderRows}
                onSort={handleChangeSort}
                mobileMode={mobileMode}
            />
            <Box className={clsx({[classes.cardFooter]: true, [classes.mobileCardFooter]: mobileMode})}>
                <Select
                    title={t('per_page_count')}
                    variant="outlined"
                    options={[
                        {label: 10, value: 10},
                        {label: 20, value: 20},
                        {label: 50, value: 50},
                        {label: 100, value: 100},
                    ]}
                    name="limit"
                    value={filters.limit}
                    onChange={handleChangeFilters}
                />
                <Box className={classes.pagination}>
                    <Button onClick={() => handleChangePage(-1)} disabled={filters.page < 2}>
                        <img src={ArrowLeftIcon} alt=":( Not Found"/>
                    </Button>
                    <Typography>
                        {startIndex}-{endIndex} {t('of')} {totalCount}
                    </Typography>
                    <Button onClick={() => handleChangePage(1)} disabled={endIndex >= totalCount}>
                        <img src={ArrowRightIcon} alt=":( Not Found"/>
                    </Button>
                </Box>
            </Box>
        </Card>
    );
};

export default DataTable;
