import React, {useState} from 'react';
import {Backdrop, Box, Card, Fade, makeStyles, Modal as MuiModal, Typography} from "@material-ui/core";
import IconButton from "../Buttons/IconButton";
import CloseIcon from "../../assets/images/icons/close.svg";
import {DateRange} from "react-date-range";
import pl from "date-fns/locale/pl";
import DefaultButton from "../Buttons/DefaultButton";
import PrimaryButton from "../Buttons/PrimaryButton";
import {useTranslation} from "react-i18next";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        padding: "25px",
        width: "500px",
    },
    title: {
        fontWeight: "bold",
        fontSize: 20,
        lineHeight: 1.4,
        color: "#003366"
    },
}));

export default function RangePickerModal({openModal, onClose}) {
    const {t} = useTranslation();
    const classes = useStyles();

    const [ranges, setRanges] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
            color: '#0066CC'
        }
    ]);

    const handleSave = () => onClose({
        from: moment(ranges[0].startDate).format('YYYY-MM-DD'),
        to: moment(ranges[0].endDate).format('YYYY-MM-DD')
    });

    return (
        <MuiModal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openModal}
            onClose={onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={openModal}>
                <Card className={classes.paper}>
                    <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography className={classes.title}>{t('audit_trial.filter_dates')}</Typography>
                        <IconButton icon={CloseIcon} onClick={onClose}/>
                    </Box>
                    <DateRange
                        locale={pl}
                        editableDateInputs={true}
                        moveRangeOnFirstSelection={false}
                        maxDate={new Date()}
                        dateDisplayFormat="dd.MM.yyyy"
                        onChange={item => setRanges([item.selection])}
                        ranges={ranges}
                    />
                    <Box display="flex" justifyContent="flex-end">
                        <DefaultButton
                            title={t('cancel')}
                            onClick={onClose}
                        />
                        <PrimaryButton
                            title={t('save')}
                            onClick={handleSave}
                        />
                    </Box>
                </Card>
            </Fade>
        </MuiModal>
    )
}
