import React from "react";
import { makeStyles, Modal as MuiModal, Backdrop, Fade, Card, Typography, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import CloseIcon from "assets/images/icons/close.svg";
import IconButton from "components/Buttons/IconButton";
import PrimaryButton from "components/Buttons/PrimaryButton";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: 'auto'
  },
  paper: {
    padding: "25px",
    width: "500px",
    maxHeight: "800px",
    overflowY: "auto",
    [theme.breakpoints.down('md')]: {
      marginInline: 10,
      height: 'calc(100% - 175px)',
      overflow: 'auto',
    },
  },
  title: {
    fontWeight: "bold",
    fontSize: 20,
    lineHeight: 1.4,
    color: "#003366"
  },
  status: {
    fontSize: 20,
    lineHeight: 1.4,
    color: "#003366",
    marginBottom: theme.spacing(2)
  }
}));

const Modal = props => {
  const { openModal, onClose, errors } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <MuiModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openModal}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModal}>
        <Card className={classes.paper}>
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography className={classes.title}>{t('data_import.import_of_results')}</Typography>
            <IconButton icon={CloseIcon} onClick={onClose} />
          </Box>
          <Box>
            <Typography className={classes.status}>
              {!errors && t('data_import.import_success')}
              {errors && t('data_import.import_error')}
            </Typography>
            {errors && errors.map((error, i) => <Typography key={i}>{error}</Typography>)}
          </Box>
          <Box display="flex" justifyContent="flex-end" mt={5}>
            <PrimaryButton
              title="OK"
              onClick={onClose}
            />
          </Box>
        </Card>
      </Fade>
    </MuiModal>
  );
};

export default Modal;
