import React, { useEffect, useState } from "react";
import {
  Box,
  makeStyles,
  Typography,
  Card,
  Button,
  LinearProgress,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import * as Actions from "store/actions/CardAction";
import Title from "components/text/Title";
import PrimaryButton from "components/Buttons/PrimaryButton";
import Moment from "react-moment";
import MessageModal from "components/modals/MessageModal";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "22px 32px",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2, 0, 10),
      background: "#FFF",
    },
  },
  toolbar: {
    padding: theme.spacing(0, 2),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 26,
    [theme.breakpoints.down("md")]: {
      marginBottom: 16,
      "& .MuiButton-root": {
        zIndex: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 68,
        height: 68,
        borderRadius: 50,
        position: "fixed",
        right: 16,
        bottom: 88,
        padding: 0,
        "& .MuiTypography-root": {
          display: "none",
        },
        "& img": {
          width: 36,
          marginRight: "0px!important",
        },
      },
    },
  },
  cardContent: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(5),
    border: '1px solid #DDE3EE',
    borderRadius: theme.spacing(1),
  },
  card: {
    border: "1px solid #DDE3EE",
    borderRadius: 8,
    boxShadow: "none",
    marginBottom: 16,
    background: "#F7F8FB",
    cursor: "pointer",
    [theme.breakpoints.up("lg")]: {
      background: "#FFF",
    },
  },
  cardHeader: {
    borderBottom: "1px solid #DDE3EE",
    padding: "18px 24px",
    display: "flex",
    alignItems: "center",
    "& img": {
      marginRight: 24,
    },
    [theme.breakpoints.up("lg")]: {
      padding: "18px 32px 15px",
      "& img": {
        marginRight: 30,
      },
    },
  },
  name: {
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: 16,
    color: "#003366",
    marginBottom: 8,
  },
  time: {
    fontFamily: "Lato",
    fontSize: 14,
    color: "#6C7689",
  },
  read: {
    border: '2px solid #D26360',
    /* "& p": {
       color: 'black',
       fontWeight: 800,
     },*/
  }
}));

const MessagesList = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const messages = useSelector((state) => state.card.globalMessageList);
  const totalCount = useSelector((state) => state.card.globalMessageCount);
  const isLoading = useSelector((state) => state.card.isLoading);
  const [openModal, setOpenModal] = useState(false);
  const [editingData, setEditingData] = useState({});
  const [status, setStatus] = useState("");
  const dispatch = useDispatch();

  const userId = 1;

  useEffect(() => {
    dispatch(Actions.getGlobalMessagesList({ user_id: userId }));
    
    window.scrollTo(0, 0);
  }, []);

  const handleCloseModal = () => {
    setEditingData({});
    setOpenModal(false);
  };

  const handleSave = (data) => {
    if (data.id !== undefined && data.id !== "") {
      dispatch(
          Actions.editGlobalMessage(data.id, {
            content: data.content,
            receiver_user_id: userId,
          })
      );
    } else {
      dispatch(
          Actions.sendGlobalMessage({ content: data.content })
      );
    }
  };

  const handleDelete = (data) => {
    dispatch(
        Actions.deleteGlobalMessage(data.id)
    );
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.toolbar}>
        <Title>{t('patients.message')} ({totalCount})</Title>
        <PrimaryButton
          onClick={() => {
            setStatus("send");
            setOpenModal(true);
          }}
          title={'Dodaj nowy komunikat'}
        />
      </Box>
      { isLoading && <LinearProgress /> }
      { !isLoading &&
        <Box className={classes.cardContent}>
          {messages.length == 0 && <Typography align={"center"}>Brak komunikatów.</Typography>}
          {messages.map((message, index) => {
            let tempAry = message.created_at.split("T");
            let dateAry = tempAry[0].split("-");
            let dateVal = dateAry.reverse().join(".");
            let timeVal = tempAry[1].split(".")[0];
            // const readClass = (message.read_at === null || message.read_at === '') ? ' ' + classes.read : '';
            return (
                <Card
                    // className={classes.card + readClass}
                    className={classes.card}
                    key={index}
                    onClick={() => {
                      setStatus("edit");
                      setOpenModal(true);
                      setEditingData(message);
                    }}
                >
                  <Box className={classes.cardHeader}>
                    <Box>
                      <Box>
                        <Typography className={classes.name}>{message.sender.doctor ? message.sender.doctor.firstname+" "+message.sender.doctor.surname : 'Administrator'}</Typography>
                        <Typography className={classes.time}>
                          {`Komunikat z dnia ${dateVal} , wysłane o ${timeVal}`}
                        </Typography>
                        <Typography className={classes.time}>
                          {`Przeczytane przez: ${message.readNum}`}
                        </Typography>
                      </Box>

                    </Box>
                  </Box>
                </Card>
            );
          })}
          <MessageModal
            openModal={openModal}
            onClose={handleCloseModal}
            editingData={editingData}
            onSave={handleSave}
            onDelete={handleDelete}
            status={status}
          />
        </Box>
      }
    </Box>
  );
};

export default MessagesList;
