const initial = {
  patientList: {
    total_count: 0,
    data: null
  },
  createPatient: {
    personal: null,
    inclusion: null,
    medicines: null,
    additional_medicines: null,
    exclusion: null,
  },
  updatePatient: {
    personal: null,
    inclusion: null,
    medicines: null,
    additional_medicines: null,
    exclusion: null,
  },
  criteriaGroupList: [],
  medicineList: [],
  exclusionCriteriaList: [],
  units: [],
  doctors: [],
};
const PatientReducer = (state = initial, action) => {
  switch (action.type) {
    case "SET_PERSONAL_DATA":
      return {
        ...state,
        createPatient: {
          ...state.createPatient,
          personal: {
            ...action.payload,
            pesel: action.payload.pesel,
          },
        },
      };
    case "SET_INCLUSION_DATA":
      return {
        ...state,
        createPatient: {
          ...state.createPatient,
          inclusion: action.payload,
        },
      };
    case "SET_MEDICINES_DATA":
      return {
        ...state,
        createPatient: {
          ...state.createPatient,
          medicines: action.payload,
        },
      };
    case "SET_ADDITIONAL_MEDICINES_DATA":
      return {
        ...state,
        createPatient: {
          ...state.createPatient,
          additional_medicines: action.payload,
        },
      };
    case "SET_EXCLUSION_DATA":
      return {
        ...state,
        createPatient: {
          ...state.createPatient,
          exclusion: action.payload,
        },
      };

    case "SET_UPDATE_PERSONAL_DATA":
      return {
        ...state,
        updatePatient: {
          ...state.updatePatient,
          personal: {
            ...action.payload,
            pesel: action.payload.pesel,
          },
        },
      };
    case "SET_UPDATE_INCLUSION_DATA":
      return {
        ...state,
        updatePatient: {
          ...state.updatePatient,
          inclusion: action.payload,
        },
      };
    case "SET_UPDATE_MEDICINES_DATA":
      return {
        ...state,
        updatePatient: {
          ...state.updatePatient,
          medicines: action.payload,
        },
      };
    case "SET_UPDATE_ADDITIONAL_MEDICINES_DATA":
      return {
        ...state,
        updatePatient: {
          ...state.updatePatient,
          additional_medicines: action.payload,
        },
      };
    case "SET_UPDATE_EXCLUSION_DATA":
      return {
        ...state,
        updatePatient: {
          ...state.updatePatient,
          exclusion: action.payload,
        },
      };
    case "SET_CLASSIFIED_PERSONAL_DATA":
      return {
        ...state,
        createPatient: {
          ...state.createPatient,
          personal: {
            ...state.createPatient.personal,
            is_classified: action.payload,
          },
        },
      };
    case "SET_ISABOVE18_PERSONAL_DATA":
      return {
        ...state,
        createPatient: {
          ...state.createPatient,
          personal: {
            ...state.createPatient.personal,
            is_above_18: action.payload,
          },
        },
      };
    case "SET_UPDATE_CLASSIFIED_PERSONAL_DATA":
      return {
        ...state,
        updatePatient: {
          ...state.updatePatient,
          personal: {
            ...state.updatePatient.personal,
            is_classified: action.payload,
          },
        },
      };
    case "SET_UPDATE_ISABOVE18_PERSONAL_DATA":
      return {
        ...state,
        updatePatient: {
          ...state.updatePatient,
          personal: {
            ...state.updatePatient.personal,
            is_above_18: action.payload,
          },
        },
      };
    case "GET_CRITERIA_GROUPS":
      return {
        ...state,
        criteriaGroupList: action.payload,
      };
    case "GET_MEDICINES":
      return {
        ...state,
        medicineList: action.payload,
      };
    case "GET_UNIT_LIST":
      return {
        ...state,
        units: action.payload,
      };
    case "GET_EXCLUSION_CRITERIAS":
      return {
        ...state,
        exclusionCriteriaList: action.payload,
      };
    case "GET_PATIENT_LIST":
      return {
        ...state,
        patientList: action.payload,
      };
    case "FORMAT_CREATE_PATIENT_DATA":
      return {
        ...state,
        createPatient: {
          ...state.createPatient,
          personal: null,
          inclusion: null,
          medicines: null,
          additional_medicines: null,
          exclusion: null,
        },
      };
    case "FORMAT_UPDATE_PATIENT_DATA":
      return {
        ...state,
        updatePatient: {
          ...state.updatePatient,
          personal: null,
          inclusion: null,
          medicines: null,
          additional_medicines: null,
          exclusion: null,
        },
      };
    case "INITIALIZE_UPDATE_PATIENT_DATA":
      return {
        ...state,
        updatePatient: action.payload,
      };
    case "GET_DOCTOR_LIST":
      return {
        ...state,
        doctors: action.payload,
      };
    default:
      return state;
  }
};
export default PatientReducer;
