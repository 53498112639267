import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useToasts } from "react-toast-notifications";
import API from "apis/API";
import Title from "components/text/Title";
import PrimaryButton from "components/Buttons/PrimaryButton";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "22px 32px",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2, 0, 10),
      background: "#FFF",
    },
  },
  container: {
    border: "1px solid #DDE3EE",
    borderRadius: 8,
    padding: 32,
    marginTop: 32
  },
  subTitle: {
    fontSize: 20,
    marginBottom: 15
  },
  dragBlock: {
    fontSize: 20,
    color: "#6C7689",
    marginBottom: 32
  },
  itemsList: {
    "& > div:last-child:not(.MuiBox-root)": {
      display: "none !important"
    }
  },
  item: {
    minWidth: "max-content",
    padding: "12px 24px",
    userSelect: "none",
    background: "#DDE3EE",
    borderRadius: 8,
    color: "#003366",
    fontSize: 20,
    fontWeight: "bold",
    marginRight: 15,
    marginBlock: 10,
    display: 'inline-block'
  },
  configuredList: {
    display: 'block',
    border: "2px solid #DDE3EE",
    borderRadius: 8,
    padding: "10px 20px"
  },
  notice: {
    background: "#F3F3F3",
    border: "2px dashed #A6AEBF",
    borderRadius: 8,
    padding: "12px 24px",
    marginBlock: 10,
    color: "#A6AEBF",
    fontWeight: "bold",
    fontSize: 20
  },
  disableTransform: {
    transform: 'none !important'
  }
}));

const ProbeConfig = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const items = [
    { label: t('settings.probe_config.sex'), value: 'sex' },
    { label: t('settings.probe_config.project_number'), value: 'project_number' },
    { label: t('settings.probe_config.unit_id'), value: 'unit_id' },
    { label: t('settings.probe_config.next_probe_number'), value: 'next_probe_number' },
    { label: t('settings.probe_config.group_id'), value: 'group_id' },
    { label: t('settings.probe_config.material_type'), value: 'material_type' },
    { label: t('settings.probe_config.collect_place'), value: 'collect_place' },
    { label: t('settings.probe_config.material_type_and_place'), value: 'material_type_and_place'}
  ];
  const numberItems = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
    { label: '7', value: '7' },
    { label: '8', value: '8' },
    { label: '9', value: '9' },
    { label: '0', value: '0' },
  ];
  const [data, setData] = useState([]);

  useEffect(() => {
    API.settings.probeConfig.show().then(res => {
      let value = res.data?.data?.value || [];
      let _data = value.map((val, i) => {
        let [item] = (isNaN(val) ? items : numberItems).filter(item => item.value === val);
        let id = i + 1;
        return { ...item, id, id_str: `configured_${id}` };
      });
      setData(_data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = () => {
    if (!data || data?.length < 1) return;

    let value = data.map(item => item.value);
    API.settings.probeConfig.store({ value }).then(res => {
      addToast(t('saved_message'), { appearance: 'success' });
    });
  }

  // a little function to help us with reordering in configured list.
  const reorder = (startIndex, endIndex) => {
    const result = [...data];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  // Copy an item from items list to configured list.
  const copy = (droppableSource, droppableDestination) => {
    const sourceIndex = droppableSource.index;
    const destClone = [...data];
    let item = sourceIndex < items.length ? items[sourceIndex] : numberItems[sourceIndex - items.length];

    let id = Math.max.apply(Math, destClone.map(o => o.id));
    id = id < 1 || !id ? 1 : id + 1;
    destClone.splice(droppableDestination.index, 0, { ...item, id, id_str: `configured_${id}` });
    return destClone;
  };

  const onDragEnd = result => {
    const { source, destination } = result;
    let _data = [];

    // dropped outside the list
    if (!destination) {
      // // if configured item dropped outside the list, remove it.
      if (source.droppableId === 'configured_items') {
        _data = [...data];
        _data.splice(source.index, 1);
        setData(_data);
      }

      return;
    }

    switch (source.droppableId) {
      case destination.droppableId:
        _data = reorder(source.index, destination.index);
        break;
      case 'items':
        _data = copy(source, destination);
        break;
      default:
        break;
    }
    setData(_data);
  };

  const renderDraggableItem = (item, index) => (
    <Draggable
      key={item.value}
      draggableId={item.value}
      index={index}>
      {(provided, snapshot) => (
        <React.Fragment>
          <Box
            className={clsx(classes.item, { [classes.disableTransform]: !snapshot.isDragging })}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            {item.label}
          </Box>
          {snapshot.isDragging && (
            <div className={classes.item}>{item.label}</div>
          )}
        </React.Fragment>
      )}
    </Draggable>
  );

  return (
    <Box className={classes.root}>
      <Title>{t('settings.probe_config.title')}</Title>
      <Box className={classes.container}>
        <Title className={classes.subTitle}>{t('settings.probe_config.available_variables')}:</Title>
        <Title className={classes.dragBlock}>{t('settings.probe_config.drag_block')}</Title>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="items" direction="horizontal" isDropDisabled={true}>
            {provided => (
              <Box className={classes.itemsList} ref={provided.innerRef}>
                <Box>
                  {items.map((item, index) => renderDraggableItem(item, index))}
                </Box>
                <Box>
                  {numberItems.map((item, index) =>
                    renderDraggableItem(item, index + items.length)
                  )}
                </Box>
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
          <Box mt={4}>
            <Title className={classes.subTitle}>{t('settings.probe_config.configured_number')}:</Title>
            <Droppable droppableId="configured_items" direction="horizontal">
              {(provided, snapshot) => (
                <Box className={classes.configuredList} ref={provided.innerRef}>
                  {data?.length > 0 &&
                    data.map((item, index) => (
                      <Draggable
                        key={item.id_str}
                        draggableId={item.id_str}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <Box
                            className={classes.item}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={provided.draggableProps.style}
                          >
                            {item.label}
                          </Box>
                        )}
                      </Draggable>
                    ))
                  }
                  {data?.length < 1 &&
                    <Box className={classes.notice}>{t('settings.probe_config.drop_block')}</Box>
                  }
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </Box>
        </DragDropContext>

        <Box mt={4} display="flex" justifyContent="flex-end">
          <PrimaryButton
            title={t('save')}
            onClick={handleSave}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ProbeConfig;
