import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  makeStyles,
  TableCell,
  TableRow,
  Typography,
  LinearProgress,
} from "@material-ui/core";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import _ from "lodash";

import { useToasts } from "react-toast-notifications";
import NumberFormat from 'react-number-format';

import API from "apis/API";
import PrimaryButton from "components/Buttons/PrimaryButton";
import DeleteButton from "components/Buttons/DeleteButton";
import DataTable from "components/DataTable";
import Title from "components/text/Title";

import PlusIcon from "assets/images/icons/plus.svg";

import AdminModal from "./Modal";
import MobileTableRow from "./MobileTableRow";
import DeleteModal from "components/modals/DeleteModal";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "22px 32px",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2, 0, 10),
      background: "#FFF",
    },
  },
  toolbar: {
    padding: theme.spacing(0, 2),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 26,
    [theme.breakpoints.down("md")]: {
      marginBottom: 16,
      "& .MuiButton-root": {
        zIndex: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 68,
        height: 68,
        borderRadius: 50,
        position: "fixed",
        right: 16,
        bottom: 88,
        padding: 0,
        "& .MuiTypography-root": {
          display: "none",
        },
        "& img": {
          width: 36,
          marginRight: "0px!important",
        },
      },
    },
  },
  deletedRow: {
    '& > td, & p': {
      color: '#959595 !important'
    }
  },
  addButton: {
    [theme.breakpoints.down("md")]: {
      zIndex: 10,
      width: 68,
      height: 68,
      borderRadius: 50,
      position: "fixed",
      right: 16,
      bottom: 88,
      padding: 0,
      "& .MuiTypography-root": {
        display: "none",
      },
      "& img": {
        width: 36,
        marginRight: "0px!important",
      },
    },
  },
  noWrap: {
    whiteSpace: 'nowrap'
  }
}));

const AdminsList = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const theme = useTheme();
  const mobileMatches = useMediaQuery(theme.breakpoints.down('md'))

  const columns = [
    { id: "id", label: "ID", sortable: true },
    { id: "login", label: t("admins.login"), sortable: true },
    { id: "action", label: "OPERACJE" },
  ];
  const [totalCount, setTotalCount] = useState(0);
  const [data, setData] = useState(null);
  const [editingData, setEditingData] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRestoreId, setSelectedRestoreId] = useState(null);
  const [filters, setFilters] = useState({
    limit: 20,
    page: 1,
    order_by: "id",
    order_direction: "DESC",
    search: "",
  });

  useEffect(() => {
    loadFilteredAdmins();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const handleChangeFilters = _filters => {
    if (!_.isEqual(_filters, filters)) {
      setFilters(_filters);
    }
  }

  const handleAdd = () => {
    setEditingData({});
    setOpenModal(true);
  }

  const handleEdit = id => {
    setEditingData(data.find(item => item.id === id));
    setOpenModal(true);
  }

  const handleCloseModal = () => {
    setEditingData(null);
    setOpenModal(false);
  }

  const loadFilteredAdmins = () => {
    API.admins.index(filters).then(res => {
      setData(res.data.data);
      setTotalCount(res.data.total);
    });
  }

  const handleDelete = id => {
    API.admins.delete(id).then(res => {
      addToast(t('saved_message'), { appearance: 'success' });
      loadFilteredAdmins();
      // For reload list.
      //setTotalCount(0);
    });
  }

  const handleSave = data => {
    (data?.id ? API.admins.update : API.admins.store)(data, data?.id).then(res => {
      if (res.data.status === 'exist_admin') {
        addToast(t('exist_message'), { appearance: 'error' });
        return;
      }

      addToast(t('saved_message'), { appearance: 'success' });
      loadFilteredAdmins();
      // For reload list.
      //setTotalCount(0);
    });
  }

  const handleRestore = () => {
    API.admins.restore(selectedRestoreId).then(res => {
      addToast(t('restore'), { appearance: 'success' });
      loadFilteredAdmins();
    })
  }

  const handleOpenRestoreModal = id => () => {
    setSelectedRestoreId(id);

  }

  const handleCloseRestoreModal = () => {
    setSelectedRestoreId(null);
  }

  const renderRows = () => (
    data.map((row, index) => (
      <TableRow key={row.id} className={row.deleted_at ? classes.deletedRow : ''}>
        {mobileMatches
          ? <TableCell colSpan={6}>
            <MobileTableRow
              index={index}
              row={row}
              onEdit={handleEdit}
              onDelete={handleDelete}
            />
          </TableCell >
          : <>
            <TableCell>{row.id}</TableCell>
            <TableCell>{row.login}</TableCell>
            <TableCell>
              {row.deleted_at &&
                <PrimaryButton
                  onClick={handleOpenRestoreModal(row.id)}
                  title={t('restore')}
                />
              }
              {!row.deleted_at &&
                <>
                  <Box display="flex">
                    <PrimaryButton
                      onClick={() => handleEdit(row.id)}
                      title={t('manage')}
                    />
                    <DeleteButton
                      onDelete={() => handleDelete(row.id)}
                      title={t('admins.confirm_delete')}
                    />
                  </Box>
                </>
              }
            </TableCell>
          </>
        }
      </TableRow>
    ))
  );

  return (
    <Box className={classes.root}>
      <Box className={classes.toolbar}>
        <Title>{t('admins.list')} ({totalCount})</Title>
        <PrimaryButton
          onClick={handleAdd}
          title={t('admins.add')}
          icon={PlusIcon}
        />
      </Box>

      {!data ? <LinearProgress /> : <DataTable
        searchLabel={t('admins.search')}
        searchPlaceholder={t('admins.enter_admin_detail')}
        columns={columns}
        totalCount={totalCount}
        onChangeFilters={handleChangeFilters}
        renderRows={renderRows}
        mobileMode
      />}
      <AdminModal
        openModal={openModal}
        onClose={handleCloseModal}
        editingData={editingData}
        onSave={handleSave}
      />
      {/* Restore modal */}
      <DeleteModal
        title={"Czy na pewno chcesz przywrócić wybrany element?"}
        openModal={selectedRestoreId}
        onClose={handleCloseRestoreModal}
        onDelete={handleRestore}
      />
    </Box>
  );
};

export default AdminsList;
