import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  makeStyles,
  LinearProgress,
  TableCell,
  TableRow,
  Typography,
  MenuItem,
  Checkbox,
  Select,
} from "@material-ui/core";
import moment from "moment";
import _ from "lodash";

import API from "apis/API";
// import Select from "components/form/Select";
import DataTable from "components/DataTable";
import Title from "components/text/Title";

import DateRangePickerModal from "./DateRangePickerModal";
import RangePickerSelect from "../../components/form/RangePickerSelect";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "22px 32px",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2, 0, 10),
      background: "#FFF",
    },
  },
  toolbar: {
    padding: theme.spacing(0, 2),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 26,
    [theme.breakpoints.down("md")]: {
      marginBottom: 16,
    },
  },
  dateCell: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    "& .MuiTypography-colorTextSecondary": {
      color: "#6C7689 !important",
    },
  },
  filterSelect: {
    flexDirection: "column",
    alignItems: "flex-start",
    marginRight: 10,
  },
  loadingTable: {
    display: "none",
  },
  formControl: {
    width: 392,
    marginRight: 24,
    "& .MuiInputBase-root": {
      width: "100%",
      "& .MuiSelect-root": {
        fontSize: 16,
        color: "#22272F",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #A6AEBF",
        borderRadius: 8,
      },
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));

const AuditTrialList = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const columns = [
    { id: "updated_at", label: "DATA ZMIANY", sortable: true },
    { id: "user_id", label: "kto dokonał zmiany", sortable: true },
    { id: "patient_id", label: "Pacjent", sortable: true },
    { id: "entity_name", label: "Miejsce dokonania zmiany", sortable: true },
    { id: "column_name", label: "DANE", sortable: true },
    { id: "old_value", label: "POPRZEDNIA WARTOŚĆ", sortable: true },
    { id: "new_value", label: "NOWA WARTOŚĆ", sortable: true },
  ];
  const dateRangeList = [
    { value: "from_beginning", label: t("audit_trial.from_beginning") },
    { value: "yesterday", label: t("audit_trial.yesterday") },
    { value: "today", label: t("audit_trial.today") },
    { value: "last_7days", label: t("audit_trial.last_7days") },
    { value: "last_14days", label: t("audit_trial.last_14days") },
    { value: "last_30days", label: t("audit_trial.last_30days") },
    { value: "custom_date_range", label: t("audit_trial.custom_date_range") },
  ];
  const [doctors, setDoctors] = useState([]);
  const [patients, setPatients] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState(null);
  const [additionalFilters, setAdditionalFilters] = useState({
    doctorIds: [],
    patientIds: [],
  });
  const [dateRangeValue, setDateRangeValue] = useState("from_beginning");
  const [openDateRangeModal, setOpenDateRangeModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedPatients, setSelectedPatients] = useState([]);
  const [selectedDoctors, setSelectedDoctors] = useState([]);

  useEffect(() => {
    API.doctors.all().then((res) => setDoctors(res.data?.data));
    API.patient.all().then((res) => setPatients(res.data?.data));
  }, []);

  useEffect(() => {
    setAdditionalFilters((prev) => {
      prev = {
        ...prev,
        patientIds: selectedPatients?.map((patient) => patient.id).join(","),
        doctorIds: selectedDoctors?.map((doctor) => doctor.id).join(","),
      };
      handleChangeFilters(prev);
      return prev;
    });
  }, [selectedPatients, selectedDoctors]);

  useEffect(() => {
    loadFileterdSets();
  }, [filters]);

  const loadFileterdSets = () => {
    API.auditTrial.index({ ...additionalFilters, ...filters }).then((res) => {
      setData(res.data.data);
      setTotalCount(res.data.total);
    });
  };

  const handleChangeDateRange = (date) => {
    setAdditionalFilters((prev) => {
      prev = { ...prev, from_date: date?.from, to_date: date?.to };
      handleChangeFilters(prev);
      return prev;
    });
  };

  // const handleCloseDateRangeModal = dateRange => {
  //     setOpenDateRangeModal(false);
  //     setAdditionalFilters(prev => {
  //         prev = {...prev, 'from_date': dateRange?.from, 'to_date': dateRange?.to};
  //         handleChangeFilters(prev);
  //         return prev;
  //     });
  // }

  // const handleChangeAdditionalFilters = e => {
  //     const target = e.target;
  //     setAdditionalFilters(prev => {
  //         prev = {...prev, [target.name]: target.value};
  //         handleChangeFilters(prev);
  //         return prev;
  //     });
  // }

  const handleChangeFilters = (_filters) => {
    console.log(12323223);
    if (!_.isEqual(_filters, filters)) {
      console.log(filters, "filters");
      console.log(_filters, "_filters");
      setFilters({ ...filters, ..._filters });
    }

    // setIsLoading(true);
    // API.auditTrial.index({...additionalFilters, ...filters, ..._filters}).then(res => {
    //     setData(res.data.data);
    //     setTotalCount(res.data.total);
    //     setIsLoading(false);
    // });
  };

  const renderPatientsValue = () => {
    let preparedString = "";

    selectedPatients?.forEach((selected, index, arr) => {
      let lastIndex = index === arr.length - 1;

      if (selectedPatients?.length > 1 && !lastIndex) {
        preparedString += selected?.firstname + " " + selected?.surname + ", ";
        return;
      }

      return (preparedString +=
        selected?.firstname + " " + selected?.surname + ".");
    });

    return preparedString;
  };

  const renderDoctorsValue = () => {
    let preparedString = "";

    selectedDoctors?.forEach((selected, index, arr) => {
      let lastIndex = index === arr.length - 1;

      if (selectedDoctors?.length > 1 && !lastIndex) {
        preparedString += selected?.firstname + " " + selected?.surname + ", ";
        return;
      }

      return (preparedString +=
        selected?.firstname + " " + selected?.surname + ".");
    });

    return preparedString;
  };

  const handleChangePatient = (patient) => {
    if (!!selectedPatients?.find((selected) => selected?.id === patient.id)) {
      setSelectedPatients(
        selectedPatients?.filter((selected) => selected?.id !== patient.id)
      );
      return;
    }

    setSelectedPatients([...selectedPatients, patient]);
  };

  const handleChangeDoctor = (doctor) => {
    if (!!selectedDoctors?.find((selected) => selected?.id === doctor.id)) {
      setSelectedDoctors(
        selectedDoctors?.filter((selected) => selected?.id !== doctor.id)
      );
      return;
    }

    setSelectedDoctors([...selectedDoctors, doctor]);
  };

  const renderAdditionalFilters = (additionalFilters) => {
    return (
      <Box display="flex" justifyContent="space-between" pr={10}>
        <Box className={classes.formControl}>
          {/* <Select
                        title={t('doctor')}
                        containerClassName={classes.filterSelect}
                        fullWidth
                        name="doctor_id"
                        valueField="id"
                        labelField={['firstname', 'surname']}
                        labelSuffix="Doktor"
                        options={[{id: 0, firstname: '-', surname: '', disabledLabelSuffix: true}, ...doctors]}
                        value={additionalFilters.doctor_id}
                        onChange={handleChangeAdditionalFilters}
                    /> */}
          <Typography className={classes.formLabel}>{t("doctor")}</Typography>
          <Select
            value={selectedDoctors}
            multiple
            variant="outlined"
            renderValue={renderDoctorsValue}
          >
            {doctors.map((doctor) => {
              return (
                <MenuItem
                  key={doctor.id}
                  value={doctor.id}
                  onClick={() => handleChangeDoctor(doctor)}
                >
                  <Checkbox
                    color="primary"
                    checked={
                      !!selectedDoctors?.find(
                        (selectedDoctor) => selectedDoctor.id === doctor.id
                      )
                    }
                  />
                  <Typography>
                    {doctor.firstname + " " + doctor.surname}
                  </Typography>
                </MenuItem>
              );
            })}
          </Select>
        </Box>
        <Box className={classes.formControl}>
          {/* <Select
                        title={t('patient')}
                        containerClassName={classes.filterSelect}
                        fullWidth
                        name="patient_id"
                        valueField="id"
                        labelField={['firstname', 'surname']}
                        options={[{id: 0, firstname: '-', surname: ''}, ...patients]}
                        value={additionalFilters.patient_id}
                        onChange={handleChangeAdditionalFilters}
                    /> */}
          <Typography className={classes.formLabel}>{t("patient")}</Typography>
          <Select
            value={selectedPatients}
            multiple
            variant="outlined"
            renderValue={renderPatientsValue}
          >
            {patients.map((patient) => {
              return (
                <MenuItem
                  key={patient.id}
                  value={patient.id}
                  onClick={() => handleChangePatient(patient)}
                >
                  <Checkbox
                    color="primary"
                    checked={
                      !!selectedPatients?.find(
                        (selectedPatient) => selectedPatient.id === patient.id
                      )
                    }
                  />
                  <Typography>
                    {patient.firstname + " " + patient.surname}
                  </Typography>
                </MenuItem>
              );
            })}
          </Select>
        </Box>
        <Box className={classes.formControl}>
          <Typography className={classes.formLabel}>
            {t("date_range")}
          </Typography>
          <RangePickerSelect onRangeSelect={handleChangeDateRange} />
        </Box>
      </Box>
    );
  };

  const renderRows = () =>
    data.map((row) => (
      <TableRow key={row.id}>
        <TableCell>
          <Box className={classes.dateCell}>
            <Typography>
              {moment(row.updated_at).format("DD.MM.YYYY")}
            </Typography>
            <Typography color="textSecondary">
              {moment(row.updated_at).format("HH:mm")}
            </Typography>
          </Box>
        </TableCell>
        <TableCell>{row.user?.login}</TableCell>
        <TableCell>
          <Typography noWrap>
            {row.patient
              ? row.patient?.surname + " " + row.patient?.firstname
              : "-"}
          </Typography>
        </TableCell>
        <TableCell>{row.entity_name}</TableCell>
        <TableCell>{row.column_name}</TableCell>
        <TableCell>
          {row.old_value !== "" ? row.old_value : "- brak -"}
        </TableCell>
        <TableCell>
          {row.new_value !== "" ? row.new_value : "- brak -"}
        </TableCell>
      </TableRow>
    ));

  return (
    <Box className={classes.root}>
      <Box className={classes.toolbar}>
        <Title>{t("audit_trial.list")}</Title>
      </Box>
      {isLoading && <LinearProgress />}
      <Box className={isLoading ? classes.loadingTable : ""}>
        <DataTable
          searchLabel={t("audit_trial.search")}
          searchPlaceholder={t("audit_trial.activity_name")}
          columns={columns}
          totalCount={totalCount}
          onChangeFilters={handleChangeFilters}
          renderRows={renderRows}
          renderAdditionalFilters={renderAdditionalFilters}
        />
        {/* <DateRangePickerModal
                    openModal={openDateRangeModal}
                    onClose={handleCloseDateRangeModal}
                /> */}
      </Box>
    </Box>
  );
};

export default AuditTrialList;
