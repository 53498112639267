import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { Box, makeStyles, Typography } from "@material-ui/core";
import UploadImage from "assets/images/pages/patient/upload.png";
import formatFileSize from "utils/formatFileSize";

const useStyles = makeStyles(() => ({
  root: {
    border: "2px dashed #99CCFF",
    borderRadius: 8,
    background: "#FFF",
    display: "flex",
    cursor: "pointer",
    "& > .MuiBox-root": {
      width: "100%",
      outline: "none",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: 30,
      "& img": {
        marginBottom: 24,
      },
      "& .MuiTypography-root": {
        textAlign: "center",
        fontFamily: "Lato",
        fontSize: 14,
      },
    },
  },
  title: {
    fontWeight: 700,
    color: "#003366",
    marginBottom: 8,
  },
  description: {
    color: "#6C7689",
  },
  file: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& img": {
      marginBottom: "10px!important",
    },
  },
  filename: {
    fontSize: "20px!important",
    color: "#0066CC",
  },
}));

const Dropzone = (props) => {
  const onDrop = useCallback((acceptedFiles) => {
    props.onChange(acceptedFiles);
  }, []);
  const { t } = useTranslation();
  const classes = useStyles();
  const { getRootProps, getInputProps } = useDropzone({
    accept: props.accept,
    onDrop,
    multiple: props.multiple === undefined ? true : props.multiple,
  });

  return (
    <Box className={classes.root}>
      <Box {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        {props.files?.length === 0 ? (
          <>
            <img src={UploadImage} alt=":( Not Found" />
            <Typography className={classes.title}>
              {t("patients.attachments.dropzone")}
            </Typography>
            <Typography className={classes.description}>
              {t("patients.attachments.support")} {props.accept}
            </Typography>
          </>
        ) : (
          <>
            {props.files?.map((file, index) => {
              return (
                <Box className={classes.file} key={index}>
                  <img src={UploadImage} alt=":( Not Found" />
                  <Typography className={classes.filename}>
                    {file.name}
                  </Typography>
                  <Typography>
                    {formatFileSize(file.size)}
                  </Typography>
                </Box>
              );
            })}
          </>
        )}
      </Box>
    </Box>
  );
};

export default Dropzone;
