import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useToasts } from "react-toast-notifications";
import Dropzone from "components/form/Dropzone";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import FileImage from "assets/images/pages/patient/file.png";
import CloseIcon from "assets/images/icons/close.svg";
import TrashIcon from "assets/images/icons/trash.svg";
import API from "apis/API";
import moment from "moment";
import Title from "components/text/Title";
import IconButton from "components/Buttons/IconButton";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "22px 32px",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2, 0, 10),
      background: "#FFF",
    },
  },
  container: {
    marginTop: 32,
    padding: "38px 48px",
    border: "1px solid #DDE3EE",
    borderRadius: 8
  },
  dropzoneTitle: {
    color: "#454D5F",
    fontSize: 20,
    marginBottom: 19
  },
  file: {
    display: "flex",
    alignItems: "flex-start",
    marginBottom: 24,
    "& img": {
      width: 31,
      marginRight: 14,
    },
    [theme.breakpoints.up("lg")]: {
      "& img": {
        width: 62,
        marginRight: 24,
      },
    },
  },
  name: {
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: 14,
    color: "#003366",
    textDecoration: "underline",
    marginBottom: 3,
    cursor: "pointer",
    "&:hover": {
      opacity: 0.8,
    },
  },
  date: {
    fontFamily: "Lato",
    fontSize: 14,
    color: "#6C7689",
    marginBottom: 8,
  },
  label: {
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: 10,
    color: "#003366",
    marginBottom: 5,
  },
  comment: {
    fontFamily: "Lato",
    fontSize: 14,
    color: "#454D5F",

    "& *": {
      margin: 0
    }
  },
  dialog: {
    "& .MuiPaper-root": {
      width: 870,
      maxWidth: 870,
      padding: "20px 32px",
    },
  },
  dialogHeader: {
    marginBottom: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiTypography-root": {
      fontFamily: "Lato",
      fontSize: 20,
      fontWeight: 700,
      color: "#003366",
    },
    "& .MuiButton-root": {
      minWidth: 32,
      width: 32,
      height: 32,
      background: "#FFF",
      border: "1px solid #DDE3EE",
      borderRadius: 8,
    },
  },
  dialogBody: {
    marginBottom: 20,
    "& .ck-content": {
      height: "350px!important",
    },
  },
  dialogFooter: {
    display: "flex",
    justifyContent: "flex-end",
    "& .MuiButton-root": {
      width: 140,
      height: 56,
      borderRadius: 8,
      fontFamily: "Lato",
      fontSize: 16,
      fontWeight: 700,
      textTransform: "none",
      marginLeft: theme.spacing(2),
      border: "1px solid #003366",
      "&:first-child": {
        color: "#003366",
      },
      "&:last-child": {
        color: "#FFF",
        background: "#003366",
      },
    },
  },
  deleteFile: {
    marginTop: 20,
    marginRight: 8,
    width: 24,
    height: 24,
    minWidth: 24,
    border: "1px solid #DDE3EE",
    background: "#F7F8FB",
    borderRadius: 6,
    "& img": {
      width: 12.5,
      height: 12.5,
    },
  },
}));

const Documents = props => {
  const { panel } = props.match.params;
  const { t } = useTranslation();
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [file, setFile] = useState([]);
  const [comment, setComment] = useState('');
  const [saving, setSaving] = useState(false);
  const [files, setFiles] = useState([]);
  const { addToast } = useToasts();

  useEffect(() => {
    API.settings.documents.show(panel).then(res => {
      setFiles(res.data.data);
    })
  }, [panel]);

  const handleSelectFile = selectedFile => {
    setOpenModal(true);
    setFile(selectedFile);
  };
  const handleClose = () => setOpenModal(false);

  const handleDeleteFile = fileID => {
    API.card.file.delete(fileID).then(res => {
      setFiles(prev => {
        let index = prev.findIndex(file => +file.id === +fileID);
        prev.splice(index, 1);
        return [...prev];
      });
    });
  };

  const handleDownloadFile = (fileID, filename) => {
    const blobInterceptor = API.interceptors.request.use(
      (config) => {
        config.responseType = 'blob';
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );
    API.card.file.download(fileID).then((res) => {
      API.interceptors.request.eject(blobInterceptor);
      var fileURL = window.URL.createObjectURL(new Blob([res.data]));
      var fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', filename);
      document.body.appendChild(fileLink);
      fileLink.click();
    });
  };

  const handleSave = () => {
    let formData = new FormData();
    formData.append('file', file[0]);
    formData.append('comment', comment);
    formData.append('ref_type', `${panel}_documents`);
    const formDataInterceptors = API.interceptors.request.use(
      config => {
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
        config.data = formData;
        return config;
      },
      error => Promise.reject(error)
    );
    setSaving(true);
    API.post('/files').then(res => {
      API.interceptors.request.eject(formDataInterceptors);
      setSaving(false);
      setOpenModal(false);
      setFile([]);
      setComment('');

      if (res.data.status != 'success')
      {
        addToast(t('file_unsuccessfully_uploaded'), { appearance: 'error' });
      } else {
        addToast(t('file_successfully_uploaded'), { appearance: 'success' });

        setFiles(prev => {
          prev.push(res.data.file);
          return [...prev];
        })
      }
    });
  };

  return (
    <>
      <Box className={classes.root}>
        <Title>{t(`settings.documents.${panel}_documents`)}</Title>
        <Box className={classes.container}>
          <Title className={classes.dropzoneTitle}>{t('settings.documents.documents_to_download')}</Title>
          <Box>
            <Dropzone
              accept=".txt, .pdf"
              multiple={false}
              files={file}
              onChange={handleSelectFile}
            />
          </Box>
          {files?.map((file, index) => (
            <Box display="flex" mt={4} key={index}>
              <Button
                className={classes.deleteFile}
                onClick={() => handleDeleteFile(file.id)}
              >
                <img src={TrashIcon} alt="Delete file" />
              </Button>
              <Box className={classes.file}>
                <img src={FileImage} alt="File" />
                <Box>
                  <Typography
                    className={classes.name}
                    onClick={() =>
                      handleDownloadFile(file.id, file.original_name)
                    }
                  >
                    {file.original_name}
                  </Typography>
                  <Typography className={classes.date}>
                    {t('patients.attachments.added')}{' '}
                    {moment(file.updated_at).format('DD.MM.YYYY, HH:mm')}
                  </Typography>
                  {file.comment && (
                    <Box>
                      <Typography className={classes.label}>
                        {t('patients.attachments.comment')}:
                      </Typography>
                      <Box
                        className={classes.comment}
                        dangerouslySetInnerHTML={{ __html: file.comment }}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <Dialog open={openModal} onClose={handleClose} className={classes.dialog}>
        <Box className={classes.dialogHeader}>
          <Typography>{t('new_note')}</Typography>
          <IconButton icon={CloseIcon} onClick={handleClose} />
        </Box>
        <Box className={classes.dialogBody}>
          <CKEditor
            editor={ClassicEditor}
            data={comment}
            onChange={(event, editor) => {
              const data = editor.getData();
              setComment(data);
            }}
          />
        </Box>
        <Box className={classes.dialogFooter}>
          <Button onClick={handleClose}>{t('cancel')}</Button>
          <Button onClick={handleSave}>
            {saving ? (
              <CircularProgress size={30} style={{ color: "#FFF" }} />
            ) : (
              <>{t('add')}</>
            )}
          </Button>
        </Box>
      </Dialog>
    </>
  );
};

export default Documents;
