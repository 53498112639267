import React from "react";
import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import * as Actions from "store/actions/CardAction";
import Routes from "router/Routes";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  card: {
    borderTop: "1px solid #DDE3EE",
    borderBottom: "1px solid #DDE3EE",
    marginBottom: theme.spacing(3),
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1, 2),
    borderBottom: "1px solid #DDE3EE",
    "& .MuiButton-root": {
      width: 38,
      minWidth: 38,
      height: 38,
      borderRadius: 8,
      border: "1px solid #DDE3EE",
      fontWeight: 500,
      fontSize: 16,
      color: "#003366",
    },
    "& .MuiTypography-root": {
      fontWeight: 700,
      fontSize: 16,
      color: "#003366",
    },
  },
  body: {
    padding: theme.spacing(2),
    "& > .MuiBox-root": {
      marginBottom: 12,
      borderRadius: 8,
      overflow: "hidden",
      border: "1px solid #DDE3EE",
      "& > .MuiBox-root": {
        "&:nth-child(odd)": {
          background: "#F1F3F8",
        },
        "&:nth-child(even)": {
          background: "#FFF",
        },
        padding: "12px 24px",
        "& .MuiTypography-root": {
          "&:first-child": {
            fontSize: 12,
            fontWeight: 700,
            textTransform: "uppercase",
            color: "#454D5F",
            marginBottom: theme.spacing(1),
          },
          fontSize: 16,
          color: "#003366",
        },
      },
    },
    "& > .MuiButton-root": {
      height: 56,
      background: "#003366",
      borderRadius: 8,
      fontWeight: 700,
      fontSize: 14,
      color: "#FFFFFF",
      textTransform: "none",
    },
  },
  name: {
    fontWeight: 700,
    fontSize: 20,
    color: "#003366",
    marginBottom: theme.spacing(1),
  },
  pesel: {
    fontSize: 16,
    color: "#6C7689",
    marginBottom: theme.spacing(2),
  },
}));

const MobileDataTable = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleViewCardPage = (id) => {
    dispatch(Actions.setSelectedPatientID(id));
    history.push(Routes.Patients.Card);
  };

  return (
    <Box className={classes.root}>
      {props.rows.map((row, index) => {
        return (
          <Box className={classes.card} key={index}>
            <Box className={classes.header}>
              <Button>{row.id}</Button>
              <Typography>
                Grupa{" "}
                {row.patient_inclusion_criteria_sub_group?.group?.nr},{" "}
                {row.patient_inclusion_criteria_sub_group?.subgroup?.name}
              </Typography>
            </Box>
            <Box className={classes.body}>
              <Typography className={classes.name}>
                {row.surname} {row.firstname}
              </Typography>
              <Typography className={classes.pesel}>
                PESEL: {row.pesel}
              </Typography>
              <Box>
                <Box>
                  <Typography>DANE KONTAKTOWE</Typography>
                  <Typography>{row.phone_number}</Typography>
                  {row.email && <Typography>{row.email}</Typography>}
                </Box>
                <Box>
                  <Typography>OSTATNIA WIZYTA</Typography>
                  <Typography>
                    {row.past_visit_date ? (
                      <>
                        {moment(row.past_visit_date.date).format("YYYY-MM-DD")},
                        {row.past_visit_date.started_at.slice(0, -3)}
                      </>
                    ) : (
                      <>-</>
                    )}
                  </Typography>
                </Box>
                <Box>
                  <Typography>NASTĘPNA WIZYTA</Typography>
                  <Typography>
                    {row.next_visit_date ? (
                      <>
                        {moment(row.next_visit_date.date).format("YYYY-MM-DD")},
                        {row.next_visit_date.started_at.slice(0, -3)}
                      </>
                    ) : (
                      <>-</>
                    )}
                  </Typography>
                </Box>
              </Box>
              <Button fullWidth onClick={() => handleViewCardPage(row.id)}>
                Przejdź do karty pacjenta
              </Button>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default MobileDataTable;
