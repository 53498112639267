import React, { useState } from "react";
import { Button, makeStyles } from "@material-ui/core";
import DeleteModal from "../modals/DeleteModal";

import DeleteIcon from "assets/images/icons/trash.svg";

const useStyles = makeStyles(theme => ({
  deleteButton: {
    width: 56,
    height: 56,
    background: "#D26360",
    borderRadius: theme.spacing(1),
    "& .MuiTypography-root": {
      color: "#FFF",
      fontSize: 14,
      marginLeft: 10,
    },
    "& img": {
      width: 22,
      height: 22,
      filter: "brightness(0) invert(10)",
    },
    "&:hover": {
      background: "#D26360",
    }
  },
}));

const DeleteButton = props => {
  const { onDelete, title } = props;
  const [openModal, setOpenModal] = useState(false);
  const classes = useStyles();

  const handleClose = () => setOpenModal(false);

  return (
    <>
      <Button className={classes.deleteButton} onClick={() => setOpenModal(true)}>
        <img src={DeleteIcon} alt=":( Not Found" />
      </Button>
      <DeleteModal
        title={title}
        openModal={openModal}
        onClose={handleClose}
        onDelete={onDelete}
      />
    </>
  );
};

export default DeleteButton;
