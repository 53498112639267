import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  makeStyles,
} from "@material-ui/core";
import { useToasts } from "react-toast-notifications";

import API from "apis/API";
import Validator, { Required, validate } from "utils/Validator";
import Title from "components/text/Title";
import FormInput from "components/form/FormInput";
import PrimaryButton from "components/Buttons/PrimaryButton";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "22px 32px",
    [theme.breakpoints.down("md")]: {
      background: "#FFF",
    },
  },
  container: {
    maxWidth: 500,
    border: "1px solid #DDE3EE",
    borderRadius: 8,
    padding: 32,
    marginTop: 32,
    margin: 'auto'
  },
}));

const Phone = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [data, setData] = useState({
    courier: '',
    support: '',
  });
  const [errors, setErrors] = useState(null);

  const Validators = {
    courier: new Validator(Required),
    support: new Validator(Required),
  };

  useEffect(() => {
    API.settings.phone.show().then(res => setData(prev => ({ ...prev, ...res.data.data[0]?.value })));
  }, []);

  useEffect(() => {
    API.settings.phone.show().then(res =>
      setData(prev => ({ ...prev, ...res.data.data[0]?.value }))
    );
  }, [addToast]);

  const handleChange = e => {
    const { name, value } = e.target;
    setData(prev => ({ ...prev, [name]: value }));
  }

  const handleSave = () => {
    let _errors = validate(data, Validators);
    setErrors(_errors);
    if (_errors) return;

    API.settings.phone.store(data).then(res => {
      addToast(t('saved_message'), { appearance: 'success' });
    });
  }

  return (
    <Box className={classes.root}>
      <Title>{t('settings.phone.title')}</Title>
      <Box className={classes.container}>
        <FormInput
          name="courier"
          title={t('settings.phone.courier')}
          value={data?.courier}
          onChange={handleChange}
          error={errors?.courier}
          required
        />
        <FormInput
          name="support"
          title={t('settings.phone.support')}
          value={data?.support}
          onChange={handleChange}
          error={errors?.support}
          required
        />

        <Box display="flex" justifyContent="flex-end" mt={3}>
          <PrimaryButton
            title={t('save')}
            onClick={handleSave}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Phone;
