import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Checkbox,
  FormControlLabel,
  makeStyles,
} from "@material-ui/core";
import { useToasts } from "react-toast-notifications";

import API from "apis/API";
import Validator, { Required, validate } from "utils/Validator";
import Title from "components/text/Title";
import FormInput from "components/form/FormInput";
import PrimaryButton from "components/Buttons/PrimaryButton";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "22px 32px",
    [theme.breakpoints.down("md")]: {
      background: "#FFF",
    },
  },
  container: {
    maxWidth: 500,
    border: "1px solid #DDE3EE",
    borderRadius: 8,
    padding: 32,
    marginTop: 32,
    margin: 'auto'
  },
}));

const Synevo = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { addToast, removeAllToasts } = useToasts();
  const [data, setData] = useState({
    login: '',
    password: '',
    unit_code: '',
    enable_results_import: true
  });
  const [errors, setErrors] = useState(null);

  const Validators = {
    login: new Validator(Required),
    password: new Validator(Required),
    unit_code: new Validator(Required),
  };

  useEffect(() => {
    API.settings.synevo.show().then(res =>
      setData(prev => ({ ...prev, ...res.data.data?.value }))
    );
    API.settings.synevo.getLastErrorMessage().then(res => {
      let errorMessage = res.data.data?.value;
      if (!errorMessage) return;
      addToast(errorMessage, {
        appearance: 'error',
        autoDismiss: false,
      })
    });
    return () => removeAllToasts()
  }, [addToast, removeAllToasts]);

  const handleChange = e => {
    const { name, value, checked, type } = e.target;
    setData(prev => ({ ...prev, [name]: type === 'checkbox' ? checked : value }));
  }

  const handleSave = () => {
    let _errors = validate(data, Validators);
    setErrors(_errors);
    if (_errors) return;

    API.settings.synevo.store(data).then(res => {
      addToast(t('saved_message'), { appearance: 'success' });
    });
  }

  return (
    <Box className={classes.root}>
      <Title>{t('settings.synevo.title')}</Title>
      <Box className={classes.container}>
        <FormInput
          name="login"
          title={t('settings.synevo.login')}
          value={data?.login}
          onChange={handleChange}
          error={errors?.login}
          required
        />
        <FormInput
          name="password"
          type="password"
          title={t('settings.synevo.password')}
          value={data?.password}
          onChange={handleChange}
          error={errors?.password}
          required
        />
        <FormInput
          name="unit_code"
          title={t('settings.synevo.unit_code')}
          value={data?.unit_code}
          onChange={handleChange}
          error={errors?.unit_code}
          required
        />
        <FormControlLabel
          control={
            <Checkbox
              name="enable_results_import"
              color="primary"
              checked={data.enable_results_import}
              onChange={handleChange}
            />
          }
          label={t('settings.synevo.enable_results_import')}
        />

        <Box display="flex" justifyContent="flex-end" mt={3}>
          <PrimaryButton
            title={t('save')}
            onClick={handleSave}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Synevo;
