import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Card, IconButton, makeStyles, Typography } from "@material-ui/core";
import Constants from 'utils/Constants';
import PillImage from "assets/images/pages/patient/pill.png";
import ChemicalFormulaImage from "assets/images/pages/patient/chemical-formula.png";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: 16,
    color: "#003366",
    margin: theme.spacing(3, 0),
    [theme.breakpoints.up("lg")]: {
      margin: theme.spacing(3, 0, 2),
      fontSize: 20,
      color: "#454D5F",
    },
  },
  card: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxShadow: "none",
    border: "1px solid #DDE3EE",
    borderRadius: 8,
    marginBottom: 24,
    padding: "18px 24px",
    background: "#F7F8FB",
    [theme.breakpoints.up("lg")]: {
      background: "#FFF",
      padding: "36px 32px",
      "& > .MuiBox-root": {
        "&:first-child": {
          "& > img": {
            width: 51,
            marginRight: 32,
          },
        },
      },
    },
    "& > .MuiBox-root": {
      display: "flex",
      "&:first-child": {
        display: "flex",
        alignItems: "center",
        width: '100%',
        "& > img": {
          width: 36,
          marginRight: 24,
        },
      },
    },
  },
  previous: {
    background: "#F1F3F8",
  },
  name: {
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: 20,
    color: "#003366",
    marginBottom: 8,
    [theme.breakpoints.up("lg")]: {
      fontSize: 24,
      marginBottom: 16,
    },
  },
  previousName: {
    marginBottom: "8px!important",
  },
  dose: {
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: 14,
    color: "#454D5F",
    [theme.breakpoints.up("lg")]: {
      fontSize: 16,
    },
  },
  iconButton: {
    minWidth: 41,
    width: 41,
    height: 41,
    background: "#F7F8FB",
    border: "1.70833px solid #DDE3EE",
    borderRadius: 8,
    marginLeft: 16,
    display: "none",
    [theme.breakpoints.up("lg")]: {
      display: "flex",
    },
  },
  detailButton: {
    textTransform: "none",
    height: 48,
    padding: "0 36px",
    borderRadius: 0,
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: 16,
    color: "#FFF",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    width: "100%",
    background: "#0066CC",
    [theme.breakpoints.up("lg")]: {
      border: "2px solid #003366",
      width: "auto",
      position: "relative",
      color: "#003366",
      background: "#FFF",
      borderRadius: 8,
      height: 56,
    },
  },
}));

const Medications = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    additional_medicines,
    medicines,
    patient_inclusion_criteria_sub_group,
  } = useSelector((state) => state.card.selectedPatient);

  const ChemicalFormulaButton = ({ medicineName }) => (
    <IconButton
      component="a"
      color="primary"
      variant="outlined"
      target="_blank"
      href={Constants.getFormulaLink(medicineName)}
    >
      <img src={ChemicalFormulaImage} alt="Chemical Formula" style={{ width: 32 }} />
    </IconButton>
  );

  return (
    <Box>
      <Typography className={classes.title}>
        {t("patients.medications.title")}
      </Typography>
      {medicines.map((row, index) => {
        return (
          <Card className={classes.card} key={index}>
            <Box>
              <img src={PillImage} alt=":( Not Found" />
              <Box flex={1}>
                <Typography className={classes.name}>
                  {row.medicine.name}
                </Typography>
                <Typography className={classes.dose}>
                  {t("patients.medications.dose")}: {row.dose} mg /{" "}
                  {t("patients.medications.time")}
                </Typography>
              </Box>
              <ChemicalFormulaButton medicineName={row.medicine.name} />
            </Box>
          </Card>
        );
      })}
      {additional_medicines.map((row, index) => {
        return (
          <Card className={classes.card} key={index}>
            <Box>
              <img src={PillImage} alt=":( Not Found" />
              <Box flex="1">
                <Typography className={classes.name}>{row.name}</Typography>
                <Typography className={classes.dose}>
                  {t("patients.medications.dose")}: {row.dose} mg /{" "}
                  {t("patients.medications.time")}
                </Typography>
              </Box>
              <ChemicalFormulaButton medicineName={row.name} />
            </Box>
          </Card>
        );
      })}
      {patient_inclusion_criteria_sub_group && patient_inclusion_criteria_sub_group.subgroup && (
        <Card className={classes.card}>
          <Box>
            <img src={PillImage} alt=":( Not Found" />
            <Box flex="1">
              <Typography className={classes.name}>
                {patient_inclusion_criteria_sub_group.subgroup?.medicines}
              </Typography>
              <Typography className={classes.dose}>
                {t("patients.medications.dose")}:{" "}
                {patient_inclusion_criteria_sub_group.dose} mg /{" "}
                {t("patients.medications.time")}
              </Typography>
            </Box>
            <ChemicalFormulaButton medicineName={patient_inclusion_criteria_sub_group.subgroup?.medicines} />
          </Box>
        </Card>
      )}
    </Box>
  );
};

export default Medications;
