import React from "react";

import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ToastProvider } from "react-toast-notifications";
import AppContainer from "./AppContainer";
import RoutesRouter from "router/RoutesRouter";
import { I18nextProvider } from "react-i18next";
import store from "store";
import i18n from "./i18n";


import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';


const App = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <ToastProvider autoDismiss autoDismissTimeout={6000}>
        <Provider store={store}>
          <BrowserRouter>
            <AppContainer>
              <RoutesRouter />
            </AppContainer>
          </BrowserRouter>
        </Provider>
      </ToastProvider>
    </I18nextProvider>
  );
};

export default App;
