import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, makeStyles, Typography } from "@material-ui/core";
import Routes from "router/Routes";
import ListActiveIcon from "assets/images/icons/mobile/list_active.svg";
import ListNormalIcon from "assets/images/icons/mobile/list_normal.svg";
import Storage from "utils/Storage";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: 76,
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 9999,
    background: "#F7F8FB",
    border: "1px solid #DDE3EE",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 20px",
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  item: {
    minWidth: 52,
    position: "relative",
    "& > .MuiBox-root": {
      textTransform: "none",
      "& > .MuiBox-root": {
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& .MuiTypography-root": {
          fontSize: 10,
          fontWeight: 700,
          color: "#003366",
          marginTop: 7,
        },
        "& img": {
          width: 24,
          height: 24,
          objectFit: "contain",
        },
      },
    },
  },
  disabled: {
    pointerEvents: "none",
    opacity: 0.7
  }
}));

const MobileNavbar = () => {
  const classes = useStyles();
  const location = useLocation();
  const { t } = useTranslation();
  const history = useHistory();
  const routes = [
    {
      name: t("doctors.list"),
      link: Routes.Doctors.List,
      activeImage: ListActiveIcon,
      normalImage: ListNormalIcon,
    },
    {
      name: t("patients.list"),
      link: Routes.Patients.List,
      activeImage: ListActiveIcon,
      normalImage: ListNormalIcon,
    },
    {
      name: t("units.list"),
      link: Routes.Units.List,
      activeImage: ListActiveIcon,
      normalImage: ListNormalIcon,
    },
  ];

  const handleRedirect = (route) => {
    history.push(route.link);
  };

  return (
    <Box className={`${classes.root} ${JSON.parse(Storage.get("user"))?.changed_password_at === null ? classes.disabled : ''}`}>
      {routes.map((route, index) => (
        <Box key={index} className={classes.item}>
          <Box onClick={() => handleRedirect(route)}>
            <Box>
              {route.link === location.pathname ? (
                <img src={route.activeImage} alt=":( Not Found" />
              ) : (
                <img src={route.normalImage} alt=":( Not Found" />
              )}
              <Typography
                style={{
                  color:
                    route.link === location.pathname ? "#0066CC" : "#003366",
                }}
              >
                {route.name}
              </Typography>
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default MobileNavbar;
