import React, {useEffect} from 'react';
import Select from "./Select";
import {useTranslation} from "react-i18next";
import {Box, Button, Input, makeStyles, Typography} from "@material-ui/core";
import moment from "moment";
import RangePickerModal from "../modals/RangePickerModal";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "22px 32px",
        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(2, 0, 10),
            background: "#FFF",
        },
    },
    toolbar: {
        padding: theme.spacing(0, 2),
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 26,
        [theme.breakpoints.down("md")]: {
            marginBottom: 16,
        },
    },
    dateCell: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        '& .MuiTypography-colorTextSecondary': {
            color: '#6C7689 !important'
        }
    },
    filterSelect: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginRight: 10,
        marginBottom: 0,
        "& .MuiSelect-root": {
            height: '56px'
        }
    },
}));

const format = 'YYYY-MM-DD';


export default function RangePickerSelect({onRangeSelect}) {
    const classes = useStyles();
    const {t} = useTranslation();
    let today = moment();
    let todayStr = today.format(format);
    const [range, setRange] = React.useState(null);
    const [option, setOption] = React.useState('from_beginning');
    const [showModal, setShowModal] = React.useState(false);

    const dateRangeList = [
        {value: 'from_beginning', label: t('audit_trial.from_beginning')},
        {value: 'yesterday', label: t('audit_trial.yesterday')},
        {value: 'today', label: t('audit_trial.today')},
        {value: 'last_7days', label: t('audit_trial.last_7days')},
        {value: 'last_14days', label: t('audit_trial.last_14days')},
        {value: 'last_30days', label: t('audit_trial.last_30days')},
        {value: 'custom_date_range', label: t('audit_trial.custom_date_range')}
    ];

    const handleCloseDateRangeModal = dateRange => {
        setShowModal(false);
        setRange(dateRange);
    };

    useEffect(() => {
        console.log(range, 'ramge')
        if (typeof onRangeSelect === 'function') {
            onRangeSelect(range);
        }
    },[range]);

    useEffect(() => {
        if (option === 'custom_date_range') {
            setShowModal(true);
        }
        let dateRange = null;
        let dateStr = today.subtract(1, 'days').format(format);

        const mappedOptions = {
            'yesterday': {from: dateStr, to: dateStr},
            'today': {from: todayStr, to: todayStr},
            'last_7days': {from: today.subtract(1, 'week').format(format), to: todayStr},
            'last_14days': {from: today.subtract(2, 'week').format(format), to: todayStr},
            'last_30days': {from: today.subtract(1, 'month').format(format), to: todayStr},
            'custom_date_range': {from: todayStr, to: todayStr}
        };

        if (mappedOptions[option]) {
            dateRange = mappedOptions[option];
        }

        setRange(dateRange);
    }, [option]);


    return (
        <>
            <Select
                // title={t('date_range')}
                containerClassName={classes.filterSelect}
                fullWidth
                options={dateRangeList}
                value={option}
                onChange={(e) => setOption(e.target.value)}
                customOption={option === 'custom_date_range' && (
                    <Button variant="outlined" style={{
                        height: '56px',
                        borderRadius: '8px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        paddingLeft: 15,
                        paddingRight: 0,
                        paddingTop: 0,
                        paddingBottom: 0
                    }}>
                        <Button onClick={() => setShowModal(true)}>
                            <Typography>
                                {moment(range?.from).format(format)} - {moment(range?.to).format(format)}
                            </Typography>
                        </Button>
                        <Button style={{height: 38, width: 40}} onClick={() => {
                            setOption('from_beginning');

                            if (typeof onRangeSelect === 'function') {
                                onRangeSelect(range);
                            }
                        }}>x</Button>
                    </Button>
                )}
            />
            <RangePickerModal
                openModal={showModal}
                onClose={handleCloseDateRangeModal}
            />
        </>
    )
}
