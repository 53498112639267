import axios from "axios";
import Storage from "utils/Storage";
import Routes from "router/Routes";

const API = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT_URI,
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});

API.interceptors.request.use(
  (config) => {
    if (config.url !== '/change_password' && config.url !== '/auth/validate_token/admin' && JSON.parse(Storage.get("user"))?.changed_password_at === null) {
      window.location.href = Routes.ChangePassword;
    }
    const token = Storage.get("token");
    if (token && token != "undefined") config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response?.status === 401) {
      if (error.response?.data.error === "Expired Token") {
        API.auth.refreshToken();
        return;
      }

      if (window.location.pathname !== Routes.Auth.Login) {
        window.location.href = Routes.Auth.Login;
      }
    }
    return Promise.reject(error);
  }
);

API.auth = {
  login: (data) => {
    const uninterceptedAxiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_ENDPOINT_URI,
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
    return uninterceptedAxiosInstance.post(`/auth/login/admin`, data);
  },
  validateToken: () => API.get(`/auth/validate_token/admin`),
};

API.units = {
  index: params => API.get(`/units`, { params }),
  all: () => API.get(`/units/all`),
  show: id => API.get(`units/${id}`),
  store: data => API.post(`/units`, data),
  update: (data, id) => API.put(`/units/${id}`, data),
  restore: id => API.put(`/units/restore/${id}`),
  delete: id => API.delete(`/units/${id}`)
};

API.unitCalendar = {
  getByFilters: params => API.get(`/units/calendar/get_by_filters`, { params }),
  getRulesCount: params => API.get(`/units/calendar/get_rules_count`, { params }),
  store: data => API.post(`/units/calendar`, data),
  update: (data, id) => API.put(`/units/calendar/${id}`, data),
  delete: id => API.delete(`/units/calendar/${id}`)
}

API.doctors = {
  index: params => API.get(`/doctors`, { params }),
  all: params => API.get(`/doctors/all`, { params }),
  printLoginDetail: id => API.get(`/doctors/print_login_detail/${id}`),
  store: data => API.post(`/doctors`, data),
  update: (data, id) => API.put(`/doctors/${id}`, data),
  restore: id => API.put(`/doctors/restore/${id}`),
  delete: id => API.delete(`/doctors/${id}`)
};

API.admins = {
  index: params => API.get(`/admins`, { params }),
  all: params => API.get(`/admins/all`, { params }),
  printLoginDetail: id => API.get(`/admins/print_login_detail/${id}`),
  store: data => API.post(`/admins`, data),
  update: (data, id) => API.put(`/admins/${id}`, data),
  restore: id => API.put(`/admins/restore/${id}`),
  delete: id => API.delete(`/admins/${id}`)
};

API.probes = {
  index: params => API.get(`/probes`, { params }),
  show: id => API.get(`/probes/${id}`),
  editAdverseEvents: (id, data) => API.put(`/probes/${id}`, data)
}

API.visits = {
  index: params => API.get(`/visits`, { params }),
  show: id => API.get(`/visits/${id}`)
}


API.probeSets = {
  index: params => API.get(`/probe-sets`, { params }),
  show: id => API.get(`/probe-sets/${id}`),
  setStatus: (id, params) => API.put(`/probe-sets/${id}`, params),
  getSetsList: id => API.get(`/probe-sets/patient-sets/${id}`),
  setSet: params => API.put(`/probe-sets/patient-sets`, params),
  editTracking: (id, params) => API.put(`/probe-sets/tracking/${id}`, params),
  getProbes: id => API.get(`/probe-sets/probes/${id}`),
}



API.auditTrial = {
  index: params => API.get(`/audit_trial`, { params })
}

API.settings = {
  probeConfig: {
    show: params => API.get(`/settings/probe_config`, { params }),
    store: data => API.post(`/settings/probe_config`, data),
  },
  documents: {
    show: panel => API.get(`/settings/documents/${panel}`)
  },
  synevo: {
    show: () => API.get('/settings/synevo'),
    store: value => API.post('/settings/synevo', { value }),
    getLastErrorMessage: () => API.get('/settings/synevo_last_error_message'),
  },
  phone: {
    show: () => API.get('/settings/phone'),
    store: value => API.post('/settings/phone', { value }),
  }
}

API.dataImport = {
  upload: (files, type) => API.post(`/data_import/${type}`, files, {
    'Content-Type': 'multipart/form-data'
  })
}






API.patient = {
  create: data => API.post(`/patients`, data),
  getList: params => API.get(`/patients`, { params }),
  all: params => API.get(`/patients/all`, { params }),
  delete: data => API.delete(`/patients/${data}`),
  update: (id, data) => API.put(`/patients/${id}`, data),
  resetPassword: (data) => API.get(`patients/print_password/${data}`),
  validatePeselCode: (data) =>
    API.get(`patients/validate_pesel`, { params: data }),
};

API.card = {
  getList: (data) => API.get(`/patients/cards`, { params: data }),
  getPatient: id => API.get(`/patients/${id}`),
  getPatientBasic: (data) => API.get(`/patients/${data}/basic`),
  getPatientProbes: (data) => API.get(`/patients/${data}/probes`),
  getPatientVisits: (data) => API.get(`/patients/${data}/visits`),
  file: {
    delete: (data) => API.delete(`/files/${data}`),
    download: (data) => API.get(`/files/${data}`),
  },
  note: {
    save: (data) => API.post(`/patients/notes`, data),
    delete: (data) => API.delete(`/patients/notes/${data}`),
  },
  visit: {
    getDataBySelectedDate: (data) => API.get(`/visits?date=${data}`),
    getVisitCount: (data) =>
      API.get(`/visits/count?year=${data.year}&month=${data.month}`),
    create: (data) => API.post(`/visits`, data),
    delete: (data) => API.delete(`/visits/${data}`),
    update: (data, id) => API.put(`/visits/${id}`, data),
  },
  probe: {
    getCarriers: () => API.get(`/probes/carriers`),
    create: (data) => API.post(`/probes`, data),
    generateCode: (data) => API.post(`/probes/generate_code/${data.patient_id}`, data),
  },
};

API.inclusionCriteriaGroup = {
  getList: () => API.get(`/inclusion_criteria_group`),
};

API.exclusionCriteriaGroup = {
  getList: () => API.get(`/exclusion_criteria`),
};

API.medicine = {
  getList: () => API.get(`/medicine`),
};

API.doctor = {
  getList: (data) => API.get(`/doctors`, data && { params: { unit_id: data } }),
};

API.message = {
  getList: params => API.get(`/notifications`, { params }),
  getGlobalList: () => API.get(`/notifications/global`),
  sendMessage: data => API.post(`/notifications`, data),
  sendGlobalMessage: data => API.post(`/notifications/global`, data),
  editMessage: (id, data) => API.put(`/notifications/${id}`, data),
  editGlobalMessage: (id, data) => API.put(`/notifications/global/${id}`, data),
  deleteMessage: (id) => API.delete(`/notifications/${id}`),
  deleteGlobalMessage: (id) => API.delete(`/notifications/global/${id}`),
};
API.changePassword = (data) => API.put('/change_password', data);
API.generateReports = (data) => API.post('/generate_reports', data);
export default API;
