import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Checkbox,
  LinearProgress,
  CircularProgress,
  Dialog,
  FormControlLabel,
  Grid,
  makeStyles,
  Select,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import TextInput from "components/form/TextInput";
import SwitchTab from "components/main/SwitchTab";
import CloseIcon from "assets/images/icons/close.svg";
import * as Actions from "store/actions/CardAction";
import * as PatientActions from "store/actions/PatientAction";
import API from "apis/API";
import ProbeTicket from "./ProbeTicket";
import Validator, { Required, validate, NotEmptyArray } from "utils/Validator";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: 16,
    color: "#454D5F",
    margin: theme.spacing(3, 0, 2),
    [theme.breakpoints.up("lg")]: {
      margin: theme.spacing(3, 0),
      fontSize: 20,
    },
  },
  addButton: {
    display: 'none',
    alignSelf: "flex-end",
    height: 56,
    marginBottom: 10,
    background: "#003366",
    borderRadius: 8,
    fontFamily: "Lato",
    fontSize: 16,
    fontWeight: 700,
    color: "#FFF",
    padding: "0 34px",
    textTransform: "none",
    width: "100%",
    "&:hover": {
      background: "#003366",
    },
    [theme.breakpoints.up("lg")]: {
      width: 280,
    },
  },
  dialog: {
    "& .MuiPaper-root": {
      width: 477,
    },
    "& .MuiGrid-item": {
      marginBottom: theme.spacing(1),
    },
  },
  dialogHeader: {
    padding: "20px 32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiTypography-root": {
      fontFamily: "Lato",
      fontWeight: 700,
      fontSize: 20,
      color: "#003366",
    },
  },
  closeButton: {
    width: 32,
    height: 32,
    minWidth: 32,
    border: "1px solid #DDE3EE",
    borderRadius: 8,
  },
  dialogBody: {
    padding: "20px 32px 0",
  },
  dialogFooter: {
    padding: "0 32px 40px",
    "& .MuiButton-root": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: 56,
      fontWeight: 700,
      fontWize: 16,
      textTransform: "none",
      borderRadius: 8,
      color: "#003366",
      border: "1px solid #A6AEBF",
    },
  },
  sendButton: {
    background: "#003366",
    color: "#FFF!important",
    border: "none!important",
    "&:hover": {
      background: "#003366",
    },
  },
  labelTxt: {
    fontSize: 14,
    color: "#6C7689",
    marginBottom: 2,
  },
  selectBox: {
    marginBottom: 16,
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 8,
      border: "1px solid #A6AEBF",
    },
  },
  groupTitle: {
    fontWeight: 700,
    fontSize: 16,
    color: "#454D5F",
    marginBottom: 18,
  },
  marginLinear: {
    marginTop: 20,
  }
}));

const Samples = () => {
  const { carriers, medicines, selectedPatient, selectedPatientID, isTabLoading } = useSelector((state) => {
    return {
      selectedPatient: state.card.selectedPatient,
      medicines: state.patient.medicineList,
      carriers: state.card.carrierList,
      selectedPatientID: state.card.selectedPatientID,
      isTabLoading: state.card.isTabLoading,
    };
  }, shallowEqual);
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [open, setOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState(null);
  const [code, setCode] = useState('');

  const [formData, setFormData] = useState({
    medicine_ids: [],
    date_of_sample: "",
    carrier_id: "",
    is_cito: true,
    type_of_test: "biochemistry",
    type_of_material: "venous",
    ordered_by_firstname: "",
    ordered_by_surname: "",
    ordered_at: "",
    patient_firstname: selectedPatient?.firstname,
    patient_surname: selectedPatient?.surname,
    patient_address_street: selectedPatient?.address_street,
    patient_address_building: selectedPatient?.address_building,
    patient_address_postcode: selectedPatient.address_postcode,
    patient_address_city: selectedPatient?.address_city,
    code: selectedPatient
      ? `${`000${selectedPatient.id}`.slice(-3)}${selectedPatient.probes_count + 1
      }`
      : "",
    patient_id: selectedPatient.id,
  });

  const testTypes = [
    {
      id: "biochemistry",
      name: "Biochemia",
    },
    {
      id: "drug-concentration",
      name: "Stężenie leków",
    },
  ];

  const materialTypes = [
    {
      id: "venous",
      name: "Żylna",
    },
    {
      id: "capillary",
      name: "Włośniczkowa",
    },
  ];

  const Validators = {
    medicine_ids: new Validator(Required),
    date_of_sample: new Validator(Required),
    carrier_id: new Validator(Required),
    ordered_by_firstname: new Validator(Required),
    ordered_by_surname: new Validator(Required),
    ordered_at: new Validator(Required),
    patient_firstname: new Validator(Required),
    patient_surname: new Validator(Required),
    patient_address_street: new Validator(Required),
    patient_address_building: new Validator(Required),
    patient_address_postcode: new Validator(Required),
    patient_address_city: new Validator(Required),
  };


  const handleChangeFormData = (e) => {
    setFormData({
      ...formData,
      [e.target.name]:
        e.target.name === "is_cito" ? e.target.checked : e.target.value,
    });
  };

  const handleChangeSwitch = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleClose = () => {
    setOpen(false);

    API.card.probe.generateCode(formData).then(res => {
      if (res.data?.code != "")
      {
        setCode(res.data.code);
      }
    });
  };

  useEffect(() => {
    dispatch(Actions.getCarrierList());
    dispatch(PatientActions.getMedicineList());
    dispatch(Actions.getSelectedPatientProbes(selectedPatientID));
    /*API.card.probe.generateCode(formData).then(res => {
      setFormData(prev => ({ ...prev, code: res.data.code }));
    });*/
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    API.card.probe.generateCode(formData).then(res => {
      if (res.data?.code != "")
      {
        setCode(res.data.code);
      }
    });
  }, [formData]);

  const handleSend = () => {
    let _errors = validate(formData, Validators);
    setErrors(_errors);
    if (_errors) {
      addToast(t('validation_error'), { appearance: 'error' });
      return;
    }

    setIsSaving(true);

    API.card.probe.create(formData).then((res) => {
      setIsSaving(false);
      dispatch(Actions.updateProbes(res.data.probe));
      handleClose();
    });
  };

  const handleRedirect = () => {
    window.open("https://e-nadawca.poczta-polska.pl/nadaj/home/", "_blank");
  };

  return (
    <>
      {isTabLoading && <LinearProgress className={classes.marginLinear} />}
      {!isTabLoading && <>
          <Box display="flex" flexDirection="column">
            <Typography className={classes.title}>
              {t("patients.samples.title")}
            </Typography>
            {selectedPatient?.probes?.map((sample, index) => {
              return <ProbeTicket {...sample} key={index} />;
            })}
            <Button className={classes.addButton} onClick={() => setOpen(true)}>
              {t("patients.samples.add")}
            </Button>
            <Button className={classes.addButton} onClick={handleRedirect}>
              {t("patients.samples.order")}
            </Button>
          </Box>
          <Dialog onClose={handleClose} className={classes.dialog} open={open}>
            <Box className={classes.dialogHeader}>
              <Typography>Wyślij próbkę do analizy</Typography>
              <Button onClick={handleClose} className={classes.closeButton}>
                <img src={CloseIcon} alt=":( Not Found" />
              </Button>
            </Box>
            <Box className={classes.dialogBody}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography className={classes.labelTxt}>
                    Leki do analizy
                  </Typography>
                  <Select
                    value={formData.medicine_ids}
                    multiple
                    fullWidth
                    name="medicine_ids"
                    onChange={(e) => handleChangeFormData(e)}
                    variant="outlined"
                    className={classes.medicine}
                    error={!!errors?.medicine_ids}
                  >
                    {medicines.map((medicine) => {
                      return (
                        <MenuItem key={medicine.id} value={medicine.id}>
                          {medicine.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    value={formData.date_of_sample}
                    name="date_of_sample"
                    onChange={(e) => handleChangeFormData(e)}
                    type="datetime-local"
                    label="Data pobrania próbki"
                    error={!!errors?.date_of_sample}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.groupTitle}>
                    Typ badania
                  </Typography>
                  <SwitchTab
                    items={testTypes}
                    onChange={(e) => handleChangeSwitch("type_of_test", e)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.groupTitle}>
                    Rodzaj materiału
                  </Typography>
                  <SwitchTab
                    items={materialTypes}
                    onChange={(e) => handleChangeSwitch("type_of_material", e)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.labelTxt}>Przewoźnik</Typography>
                  <Select
                    value={formData.carrier_id}
                    fullWidth
                    name="carrier_id"
                    onChange={(e) => handleChangeFormData(e)}
                    variant="outlined"
                    className={classes.selectBox}
                    error={!!errors?.carrier_id}
                  >
                    {carriers.map((carrier) => {
                      return (
                        <MenuItem key={carrier.id} value={carrier.id}>
                          {carrier.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={formData.is_cito}
                        name="is_cito"
                        onChange={(e) => handleChangeFormData(e)}
                      />
                    }
                    label="CITO"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.groupTitle}>
                    Dane zlecającego analizę próbki
                  </Typography>
                  <TextInput
                    value={formData.ordered_by_firstname}
                    name="ordered_by_firstname"
                    onChange={(e) => handleChangeFormData(e)}
                    label="Imię (Imiona)*"
                    error={!!errors?.ordered_by_firstname}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    value={formData.ordered_by_surname}
                    name="ordered_by_surname"
                    onChange={(e) => handleChangeFormData(e)}
                    label="Nazwisko*"
                    error={!!errors?.ordered_by_surname}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    value={formData.ordered_at}
                    name="ordered_at"
                    onChange={(e) => handleChangeFormData(e)}
                    type="datetime-local"
                    label="Data zlecenia"
                    error={!!errors?.ordered_at}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.groupTitle}>
                    Dane pacjenta(wczytane z karty)
                  </Typography>
                  <TextInput
                    value={formData.patient_firstname}
                    name="patient_firstname"
                    onChange={(e) => handleChangeFormData(e)}
                    label="Imię (Imiona)*"
                    error={!!errors?.patient_firstname}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    value={formData.patient_surname}
                    name="patient_surname"
                    onChange={(e) => handleChangeFormData(e)}
                    label="Nazwisko*"
                    error={!!errors?.patient_surname}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    value={formData.patient_address_street}
                    name="patient_address_street"
                    onChange={(e) => handleChangeFormData(e)}
                    label="Ulica*"
                    error={!!errors?.patient_address_street}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    value={formData.patient_address_building}
                    name="patient_address_building"
                    onChange={(e) => handleChangeFormData(e)}
                    label="Budynek/lokal*"
                    error={!!errors?.patient_address_building}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    value={formData.patient_address_postcode}
                    name="patient_address_postcode"
                    onChange={(e) => handleChangeFormData(e)}
                    label="Kod pocztowy*"
                    error={!!errors?.patient_address_postcode}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    value={formData.patient_address_city}
                    name="patient_address_city"
                    onChange={(e) => handleChangeFormData(e)}
                    label="Miasto*"
                    error={!!errors?.patient_address_city}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.groupTitle}>
                    Wygenerowany kod próbki
                  </Typography>
                  <TextInput value={code} disabled />
                </Grid>
              </Grid>
            </Box>
            <Box className={classes.dialogFooter}>
              <Grid container spacing={3}>
                <Grid xs={6} item>
                  <Button onClick={handleClose}>{t("cancel")}</Button>
                </Grid>
                <Grid xs={6} item>
                  <Button className={classes.sendButton} onClick={handleSend}>
                    {isSaving ? (
                      <CircularProgress size={30} style={{ color: "#FFF" }} />
                    ) : (
                      <>{t("send")}</>
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Dialog>
        </>
      }
    </>
  );
};

export default Samples;
