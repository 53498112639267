import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  makeStyles,
  Typography,
  LinearProgress,
  Grid,
  TextField,
  Tooltip,
} from "@material-ui/core";

import BackIcon from "assets/images/icons/back.svg";
import PrintIcon from "assets/images/icons/print.svg";

import Routes from "router/Routes";
import Title from "components/text/Title";
import API from "apis/API";
import moment from "moment";
import PrimaryButton from "components/Buttons/PrimaryButton";
import DefaultButton from "components/Buttons/DefaultButton";
import ReasonModal from "components/modals/ReasonModal";
import DownloadLabelModal from './DownloadLabelModal';
import ZebraBrowserPrintWrapper from 'zebra-browser-print-wrapper';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "22px 32px",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2, 0, 10),
      background: "#FFF",
    },
  },
  goBack: {
    fontSize: 16,
  },
  backButton: {
    minWidth: 40,
    width: 40,
    height: 40,
    marginRight: 16,
    padding: 10,
    border: "1px solid #DDE3EE",
    borderRadius: 12,
  },
  container: {
    border: "1px solid #DDE3EE",
    background: "#fff",
    borderRadius: 8,
    "& > .MuiBox-root": {
      padding: 40,
    },
  },
  header: {
    borderBottom: "1px solid #DDE3EE",
  },
  description: {
    fontWeight: "normal",
    fontSize: 20,
    color: "#6C7689",
    marginTop: 16,
  },
  subTitle: {
    fontSize: 20,
    color: "#454D5F",
    marginLeft: 16,
  },
  subContainer: {
    border: "1px solid #DDE3EE",
    borderRadius: 8,
    padding: "12px 24px",
    marginTop: 24,
    marginBottom: 30,
    "&:last-child": {
      marginBottom: 0,
    },
  },
  item: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 16,
    "&:last-child": {
      marginBottom: 0,
    },
    "& > .MuiTypography-root": {
      "&:first-child": {
        fontSize: 16,
        color: "#454D5F",
      },
      "&:last-child": {
        fontSize: 20,
        color: "#003366",
      },
    },
  },
  editButton: {
    paddingInline: 15,
    height: 56,
    borderRadius: 8,
    textTransform: "none",
    border: "2px solid #003366",
    background: "#FFF",
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: 16,
    color: "#003366",
    marginRight: theme.spacing(3),
  },
  saveButton: {
    paddingInline: 15,
    height: 56,
    background: "#003366",
    borderRadius: 8,
    fontSize: 16,
    fontWeight: 700,
    color: "#FFF",
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      background: "#003366",
      boxShadow: "none",
    },
  },
  trackingCode: {
    marginTop: 5,
    marginBottom: 5,
  },
  trackingStatus: {
    marginBottom: 20,
    fontWeight: 'bold'
  }
}));

const ProbeSetsDetail = (props) => {
  const { id } = props.match.params;
  const classes = useStyles();
  const { t } = useTranslation();
  const [data, setData] = useState();
  const [setsData, setSetsData] = useState([]);
  const [orderStatus, setOrderStatus] = useState(true);
  const [disabledStatuses, setDisabledStatuses] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openDownloadLabelModal, setOpenDownloadLabelModal] = useState(false);
	const [probes, setProbes] = useState([]);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handlePrint = probesList => () => {
		if (typeof probesList === 'number') {
			probesList = { [probesList]: 1 };
		} else {
			probesList = {};
			Object.entries(probes).forEach(entry => {
				const [key, value] = entry;
				probesList[value.id] = value.amount;
			});
		}

		let codeToPrint = "\xef\xbb\xbf";

		Object.keys(probesList).forEach((singleProbe, key) => {
			let probe = probes.find(el => el.id == singleProbe);
			let amount = probesList[singleProbe];

			let hintText = '';

			if (probe.collect_place === 'clinic') {
				hintText = 'POR - ';
			} else {
				hintText = 'DOM - ';
			}
			if (probe.type_of_material === 'venous') {
				hintText += 'ŻYL';
			} else {
				hintText += 'WŁO';
			}

			// Check if the printer is ready

			for (let c = 0; c < amount; c++) {
				codeToPrint += `^XA
					^FO375,50,0
					^BXN,4,200
					^FD${probe.code}^FS
					^CF0,35
					^FO320,110,0
					^CI31
					^FD${hintText}^FS
					^CF0,35
					^FO340,150,0
					^FD${probe.code}^FS
					^XZ`;
			}
		});

		console.log(codeToPrint);

		try {
			const browserPrint = new ZebraBrowserPrintWrapper();

			browserPrint.getDefaultPrinter().then((defaultPrinter) => {
				browserPrint.setPrinter(defaultPrinter);

				browserPrint.checkPrinterStatus().then((printerStatus) => {
					if (printerStatus.isReadyToPrint) {
						browserPrint.print(codeToPrint);
					} else {
						alert('Drukarka nie jest dostępna (#304): ' + printerStatus.errors.join(", "));
					}
				}).catch((error) => {
					alert('Drukarka nie jest dostępna (#204): ' + error);
				});
			}).catch((error) => {
				alert('Drukarka nie jest dostępna (#104): ' + error);
			});


		} catch (error) {
			alert('Błąd podczas inicjalizacji wydruku: ' + error);
		}
	};

  const handleSave = (status, data = null) => {
    const params = {
      status: status,
    };
    status === 2 && (params["reason"] = data.reason);

    API.probeSets.setStatus(id, params).then((res) => getInfo(res.data.data.id));
  };

  const handleChange = (e) => {
    const target = e.target;
    if (target.name === "order_waybill_number") {
      setData((prev) => ({ ...prev, shipment: {...prev.shipment, tracking_code: target.value} }));
    } else {
      const prevData = [...setsData];
      if (!prevData[target.name].shipment)
      {
        prevData[target.name].shipment = {last_status: '', status_history : {}};
      }

      prevData[target.name].shipment.tracking_code = target.value;
      setSetsData((prev) => ([...prevData]));
    }
  };

  const handleEdit = (index = null) => {
    if (index === null) {
      setOrderStatus(!orderStatus);
    } else {
      let temp = [...disabledStatuses];
      temp[index] = !temp[index];
      setDisabledStatuses([...temp]);
    }
  };

  const handleSet = (index = null) => {
    if (index === null) {
      API.probeSets.editTracking(id, {'tracking_code': data.shipment.tracking_code}).then(() => {
        setOrderStatus(!orderStatus);
        getInfo(id);
      });
    } else {
      API.probeSets.setSet(setsData[index]).then(() => {
        let temp = [...disabledStatuses];
        temp[index] = !temp[index];
        setDisabledStatuses([...temp]);
        getInfo(id);
      });
    }
  }

  const getInfo = (id) => {
    API.probeSets.show(id).then((res) => {
      setData(res.data.data);
      if (res.data.data.shipment?.tracking_code === null || res.data.data.shipment?.tracking_code === '') {
        setOrderStatus(false);
      }
      if (res.data.data.status === 1) {
        API.probeSets.getSetsList(id).then((res1) => {
          console.log(res1.data.data, "res1.data.data");
          setSetsData(res1.data.data);
          let tempAry = [];
          const tempLen = res1.data.data.length;
          for (var i = 0; i < tempLen; i++) {
            if (!res1.data.data[i].shipment || res1.data.data[i].shipment?.tracking_code === null || res1.data.data[i].shipment?.tracking_code === '')
              tempAry.push(false)
            else
              tempAry.push(true)
          }
          setDisabledStatuses([...tempAry]);
        });
        API.probeSets.getProbes(id).then((res2) => {
          setProbes(res2.data.data);
        });
      }
    });
  }


  useEffect(() => {
    getInfo(id);
  }, [id]);

  return !data ? (
    <LinearProgress />
  ) : (
    <>
      <Box className={classes.root}>
        <Box display="flex" alignItems="center" mb={2}>
          <Link to={Routes.ProbeSets.List}>
            <Button variant="outlined" className={classes.backButton}>
              <img src={BackIcon} alt="Go back" />
            </Button>
          </Link>
          <Title className={classes.goBack}>{t("go_back")}</Title>
        </Box>
        <Box className={classes.container}>
          <Box className={classes.header}>
            <Title>
              {t("probe-sets.detail")} {t("probe-sets.probe-set")}
            </Title>
            <Title className={classes.description}>
              {t("probe-sets.create_date")}:{" "}
              {moment(data?.created_at).format("DD.MM.YYYY, HH:mm")}
            </Title>
          </Box>
          <Box>
            <Title className={classes.subTitle}>{t("probes.basic_data")}</Title>
            <Box className={classes.subContainer}>
              <Box className={classes.item}>
                <Typography>{t("probe-sets.patient")}</Typography>
                <Typography>
                  {data?.patient?.surname} {data?.patient?.firstname}
                </Typography>
              </Box>
              <Box className={classes.item}>
                <Typography>{t("probe-sets.address")}</Typography>
                <Typography>
                  {data?.street} {data?.building}, {data?.postcode} {data?.city}
                </Typography>
              </Box>
              <Box className={classes.item}>
                <Typography>{t("probe-sets.amount")}</Typography>
                <Typography>{data?.amount}</Typography>
              </Box>
              <Box className={classes.item}>
                <Typography>{t("probe-sets.created_at")}</Typography>
                <Typography>
                  {moment(data?.created_at).format("DD.MM.YYYY")}
                </Typography>
              </Box>
              <Box className={classes.item}>
                <Typography>{t("probe-sets.status")}</Typography>
                <Typography>
                  {t(`probe-sets.status-${parseInt(data?.status)}`)}
                </Typography>
              </Box>
              {data?.reason && <Box className={classes.item}>
                <Typography>{t("probe-sets.reject_reason")}</Typography>
                <Typography>
                  {data?.reason}
                </Typography>
              </Box>}
            </Box>
            {data?.status === 0 && (
              <Box display="flex" justifyContent="flex-end">
                <DefaultButton
                  title={t("probe-sets.reject_order")}
                  onClick={() => setOpenModal(true)}
                />
                <PrimaryButton
                  title={t("probe-sets.accept_order")}
                  onClick={() => handleSave(1)}
                />
              </Box>
            )}
          </Box>
          {data?.status === 1 && (
            <Box>
              <Title className={classes.subTitle}>{t("probes.waybills")}</Title>
              <Box className={classes.subContainer}>
                <Typography>{t("probes.order_waybill_number")}</Typography>
                <Grid container spacing={2} className={classes.trackingCode}>
                  <Grid xs={9} lg={5} item>
                    <TextField
                      variant="outlined"
                      name="order_waybill_number"
                      fullWidth={true}
                      value={data.shipment?.tracking_code ? data.shipment?.tracking_code : ""}
                      onChange={handleChange}
                      required
                      disabled={orderStatus}
                    />
                  </Grid>
                  <Grid xs={3} lg={2} item>
                    {
                      orderStatus
                      ? (
                        <Button
                          className={classes.editButton}
                          onClick={() => handleEdit()}
                        >
                          {t("edit")}
                        </Button>
                      )
                      : (
                        <Button
                          className={classes.saveButton}
                          onClick={() => handleSet()}
                        >
                          {t("save")}
                        </Button>
                      )
                    }
                  </Grid>
                </Grid>
                <Tooltip placement="top-start" arrow title={<h3>{data?.shipment?.last_status_date}</h3>}><Typography className={classes.trackingStatus}>{data?.shipment?.last_status ? 'Status: '+data?.shipment?.last_status : ''}</Typography></Tooltip>
                {setsData.map((item, index) => {
                  return (
                    <Box key={index}>
                      <Typography>
                        {t("probes.consignment_note_number_set") + (index + 1)}
                      </Typography>
                      <Grid
                        container
                        spacing={2}
                        className={classes.trackingCode}
                      >
                        <Grid xs={9} lg={5} item>
                          <TextField
                            variant="outlined"
                            name={String(index)}
                            fullWidth={true}
                            value={item.shipment?.tracking_code ? item.shipment?.tracking_code : ""}
                            onChange={handleChange}
                            required
                            disabled={disabledStatuses[index]}
                          />
                        </Grid>
                        <Grid xs={3} lg={2} item>
                          {
                            disabledStatuses[index]
                            ? (
                              <Button
                                className={classes.editButton}
                                onClick={() => handleEdit(index)}
                              >
                                {t("edit")}
                              </Button>
                            )
                            : (
                              <Button
                                className={classes.saveButton}
                                onClick={() => handleSet(index)}
                              >
                                {t("save")}
                              </Button>
                            )
                          }
                        </Grid>
                      </Grid>
                      <Tooltip placement="top-start" arrow title={<h3>{item?.shipment?.last_status_date}</h3>}><Typography className={classes.trackingStatus}>{item?.shipment?.last_status ? 'Status: '+item?.shipment?.last_status : ''}</Typography></Tooltip>
                    </Box>
                  );
                })}
              </Box>
              <PrimaryButton
                onClick={() => setOpenDownloadLabelModal(true)}
                style={{color: 'white'}}
              >
                <img src={PrintIcon} alt={t('probe-sets.printLabel')} />
                {t('probe-sets.printLabel')}
              </PrimaryButton>
            </Box>
          )}
        </Box>
        <ReasonModal
          openModal={openModal}
          onClose={handleCloseModal}
          onSave={handleSave}
        />
        <DownloadLabelModal
          open={openDownloadLabelModal}
          onClose={() => setOpenDownloadLabelModal(false)}
          defaultProbes={probes}
          printProbes={handlePrint}
        />
      </Box>
    </>
  );
};

export default ProbeSetsDetail;
