const initial = {
  erorr: null,
  isLoading: false,
  isTabLoading: false,
  list: [],
  selectedPatient: null,
  selectedPatientID: null,
  visitsBySelectedDate: null,
  visitCount: null,
  carrierList: [],
  selectedTab: null,
  selectedVisit: null,
  messageList: [],
  globalMessageList: [],
  globalMessageCount: 0,
};

const CardReducer = (state = initial, action) => {
  switch (action.type) {
    case "GET_PATIENT_LIST":
      return {
        ...state,
        list: action.payload,
      };
    case "SET_SELECTED_PATIENT_ID":
      return {
        ...state,
        selectedPatientID: action.payload,
      };
    case "SET_LOADING":
      return {
        ...state,
        isLoading: action.payload,
      };
    case "SET_TAB_LOADING":
      return {
        ...state,
        isTabLoading: action.payload,
      };
    case "SET_ERROR":
      return {
        ...state,
        erorr: action.payload,
      };
    case "GET_SELECTED_PATIENT_DATA":
      return {
        ...state,
        selectedPatient: {
          ...action.payload,
          probes: state.selectedPatient?.probes,
          visits: state.selectedPatient?.visits,
          past_visits: state.selectedPatient?.past_visits,
          future_visits: state.selectedPatient?.future_visits,
        },
      };
    case "GET_SELECTED_PATIENT_PROBES":
      return {
        ...state,
        selectedPatient: {
          ...state.selectedPatient,
          probes: action.payload.probes
        },
      };
    case "GET_SELECTED_PATIENT_VISITS":
      return {
        ...state,
        selectedPatient: {
          ...state.selectedPatient,
          visits: action.payload.visits,
          past_visits: action.payload.past_visits,
          future_visits: action.payload.future_visits,
        },
      };
    case "REMOVE_PATIENT_DATA":
      const clonedPatientList = [...state.list.data];
      let selectedIndex = clonedPatientList.findIndex(item => item.id === action.payload);

      clonedPatientList.splice(selectedIndex, 1);
      return {
        ...state,
        selectedPatient: null,
        selectedPatientID: null,
        list: {
          total_count: state.list.total_count - 1,
          data: clonedPatientList,
        },
      };
    case "REMOVE_SELECTED_PATIENT_DATA":
      return {
        ...state,
        selectedPatient: null,
        selectedPatientID: null,
      };
    case "ADD_ATTACHMENT_FILE":
      let attachmentFiles = state.selectedPatient.files;
      attachmentFiles.push(action.payload);
      return {
        ...state,
        selectedPatient: {
          ...state.selectedPatient,
          files: attachmentFiles,
        },
      };
    case "REMOVE_ATTACHMENT_FILE":
      let files = state.selectedPatient.files;
      let index = files.findIndex(file => file.id === action.payload);

      files.splice(index, 1);
      return {
        ...state,
        selectedPatient: {
          ...state.selectedPatient,
          files: files,
        },
      };
    case "UPDATE_DOCTOR_NOTE":
      let notes = state.selectedPatient.notes;
      notes.push(action.payload);
      return {
        ...state,
        selectedPatient: {
          ...state.selectedPatient,
          notes,
        },
      };
    case "DELETE_DOCTOR_NOTE":
      let doctorNotes = state.selectedPatient.notes;
      
      let ind = doctorNotes.findIndex(note => note.id === action.payload);
      doctorNotes.splice(ind, 1);

      return {
        ...state,
        selectedPatient: {
          ...state.selectedPatient,
          notes: doctorNotes,
        },
      };
    case "GET_VISITS_BY_SELECTED_DATE":
      return {
        ...state,
        visitsBySelectedDate: action.payload,
      };
    case "DELETE_FUTURE_VISIT":
      const futureVisits = state.selectedPatient.future_visits;
      let visitIndex = futureVisits.findIndex(visit => visit.id === action.payload);

      futureVisits.splice(visitIndex, 1);
      return {
        ...state,
        selectedPatient: {
          ...state.selectedPatient,
          future_visits: futureVisits,
        },
      };
    case "GET_VISIT_COUNT":
      return {
        ...state,
        visitCount: action.payload,
      };
    case "ADD_FUTURE_VISITS":
      let fVisits = state.selectedPatient.future_visits;
      fVisits.push(action.payload);
      return {
        ...state,
        selectedPatient: {
          ...state.selectedPatient,
          future_visits: fVisits,
        },
      };
    case "UPDATE_FUTURE_VISITS":
      let uFutureVisits = state.selectedPatient.future_visits;
      let ufIndex = uFutureVisits.findIndex(visit => visit.id === action.payload.id);

      uFutureVisits.splice(ufIndex, 1, action.payload);
      return {
        ...state,
        selectedPatient: {
          ...state.selectedPatient,
          future_visits: uFutureVisits,
        },
      };
    case "GET_CARRIER_LIST":
      return {
        ...state,
        carrierList: action.payload,
      };
    case "UPDATE_PROBES":
      let probes = state.selectedPatient.probes;
      probes.push(action.payload);
      return {
        ...state,
        selectedPatient: {
          ...state.selectedPatient,
          probes,
        },
      };
    case "SET_SELECTED_TAB":
      return {
        ...state,
        selectedTab: action.payload,
      };
    case "DELETE_SELECTED_TAB":
      return {
        ...state,
        selectedTab: null,
      };
    case "SELECT_VISIT":
      return {
        ...state,
        selectedVisit: action.payload,
      };
    case "DELETE_SELECTED_VISIT":
      return {
        ...state,
        selectedVisit: null,
      };
    case "GET_MESSAGES_LIST":
      return {
        ...state,
        messageList: action.payload,
      };
    case "GET_GLOBAL_MESSAGES_LIST":
      return {
        ...state,
        globalMessageList: action.payload.data,
        globalMessageCount: action.payload.total_count,
      };
    case "SEND_MESSAGE":
      let messages = [...state.messageList];
      messages.unshift(action.payload);
      return {
        ...state,
        messageList: [...messages],
      };
    case "SEND_GLOBAL_MESSAGE":
      if (action.payload) {
        let globalMessages = [...state.globalMessageList];
        globalMessages.unshift(action.payload);
        return {
          ...state,
          globalMessageList: [...globalMessages],
          globalMessageCount: state.globalMessageCount + 1,
        };
      }
    case "EDIT_MESSAGE":
      let oldMessages = [...state.messageList];
      console.log(oldMessages);
      oldMessages.map((item, index) => {
        if (item.id === action.payload.id) {
          oldMessages[index] = {...action.payload};
        }
      });
      console.log(action.payload);
      console.log(oldMessages);
      return {
        ...state,
        messageList: oldMessages,
      };
    case "EDIT_GLOBAL_MESSAGE":
      let oldGlobalMessages = [...state.globalMessageList];
      oldGlobalMessages.map((item, index) => {
        if (item.id === action.payload.id) {
          oldGlobalMessages[index] = {...action.payload};
        }
      });
      return {
        ...state,
        globalMessageList: oldGlobalMessages,
      };
    case "DELETE_MESSAGE":
      let oldMessagesList = [...state.messageList];

      oldMessagesList.map((item, index) => {
        if (item.id === action.payload.id) {
          oldMessagesList.splice(index, 1);
        }
      });

      return {
        ...state,
        messageList: oldMessagesList,
      };
    case "DELETE_GLOBAL_MESSAGE":
      let oldGlobalMessagesList = [...state.globalMessageList];

      oldGlobalMessagesList.map((item, index) => {
        if (item.id === action.payload.id) {
          oldGlobalMessagesList.splice(index, 1);
        }
      });

      return {
        ...state,
        globalMessageList: oldGlobalMessagesList,
      };
    case "SET_SELECTED_SUB_TAB":
      return {
        ...state,
        selectedSubTab: action.payload,
      };
    default:
      return state;
  }
};
export default CardReducer;
