import React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useToasts } from "react-toast-notifications";
import NumberFormat from 'react-number-format';

import PrimaryButton from "components/Buttons/PrimaryButton";
import DeleteButton from "components/Buttons/DeleteButton";


const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTypography-colorTextSecondary': {
      color: '#00000070'
    }
  },
  index: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 38,
    height: 38,
    minWidth: 38,
    color: '#003366',
    fontSize: 16,
    fontWeight: 500,
    border: '1px solid #DDE3EE',
    borderRadius: 8
  },
  buttonContainer: {
    '& .MuiButton-root': {
      width: '100%'
    }
  }
}));

const MobileTableRow = ({ index, row, onEdit, onDelete }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { addToast } = useToasts();

  return (
    <Box className={classes.root}>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <Box className={classes.index}>{row.id}</Box>
      </Box>
      <Box mb={1}>
        <Typography color="textSecondary">{t('admins.login')}</Typography>
        {row.login}
      </Box>

      {!row.deleted_at &&
      <Box display="flex" className={classes.buttonContainer}>
        <PrimaryButton
          onClick={() => onEdit(row.id)}
          title={t('manage')}
        />
        <DeleteButton
          onDelete={() => onDelete(row.id)}
          title={t('units.confirm_delete')}
        />
      </Box>}
    </Box>
  );
};

export default MobileTableRow;
