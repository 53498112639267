import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  stepTitle: {
    fontSize: 32,
    fontWeight: 700,
    color: "#454D5F",
    marginBottom: theme.spacing(6),
    letterSpacing: "-1px",
    [theme.breakpoints.down("md")]: {
      fontSize: 20,
      marginBottom: theme.spacing(2),
    },
  },
}));

const StepContent = (props) => {
  const classes = useStyles();

  return (
    <Box>
      <Typography className={classes.stepTitle}>{props.title}</Typography>
      <Box>{props.children}</Box>
    </Box>
  );
};

export default StepContent;
