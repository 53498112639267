import API from "apis/API";

export const getPatientList = (params) => async (dispatch) => {
  dispatch({ type: "SET_LOADING", payload: true });
  API.card
    .getList(params)
    .then((res) => {
      dispatch({ type: "GET_PATIENT_LIST", payload: res.data });
      dispatch({ type: "SET_LOADING", payload: false });
    })
    .catch((err) => {
      dispatch({ type: "SET_ERROR", err });
      dispatch({ type: "SET_LOADING", payload: false });
    });
};

export const setSelectedPatientID = (param) => async (dispatch) => {
  dispatch({ type: "SET_SELECTED_PATIENT_ID", payload: param });
};

export const getSelectedPatientData = (param) => async (dispatch) => {
  API.card.getPatient(param).then((res) => {
    dispatch({ type: "GET_SELECTED_PATIENT_DATA", payload: res.data.data });
  });
};

export const getSelectedPatientBasicData = (param) => async (dispatch) => {
  API.card.getPatientBasic(param).then((res) => {
      dispatch({type: "GET_SELECTED_PATIENT_DATA", payload: res.data.data});
  });
};

export const getSelectedPatientProbes = (param) => async (dispatch) => {
  dispatch({type: "SET_TAB_LOADING", payload: true});
  API.card.getPatientProbes(param).then((res) => {
      dispatch({type: "GET_SELECTED_PATIENT_PROBES", payload: res.data.data});
      dispatch({type: "SET_TAB_LOADING", payload: false});
  });
}

export const getSelectedPatientVisits = (param) => async (dispatch) => {
  dispatch({type: "SET_TAB_LOADING", payload: true});
  API.card.getPatientVisits(param).then((res) => {
      dispatch({type: "GET_SELECTED_PATIENT_VISITS", payload: res.data.data});
      dispatch({type: "SET_TAB_LOADING", payload: false});
  });
}

export const deletePatient = (param) => async (dispatch) => {
  API.patient.delete(param).then(() => {
    dispatch({ type: "REMOVE_PATIENT_DATA", payload: param });
  });
};

export const deleteSelectedPatientData = () => async (dispatch) => {
  dispatch({ type: "REMOVE_SELECTED_PATIENT_DATA" });
};

export const addAttachmentFile = (data) => async (dispatch) => {
  dispatch({ type: "ADD_ATTACHMENT_FILE", payload: data });
};

export const deleteFile = (data) => async (dispatch) => {
  API.card.file.delete(data).then((res) => {
    dispatch({ type: "REMOVE_ATTACHMENT_FILE", payload: data });
  });
};

export const updateNote = (data) => async (dispatch) => {
  dispatch({ type: "UPDATE_DOCTOR_NOTE", payload: data });
};

export const deleteNote = (data) => async (dispatch) => {
  dispatch({ type: "DELETE_DOCTOR_NOTE", payload: data });
};

export const getVisitsBySelectedDate = (data) => async (dispatch) => {
  API.card.visit.getDataBySelectedDate(data).then((res) => {
    dispatch({ type: "GET_VISITS_BY_SELECTED_DATE", payload: res.data.data });
  });
};

export const deleteFutureVisit = (data) => async (dispatch) => {
  dispatch({ type: "DELETE_FUTURE_VISIT", payload: data });
};

export const getVisitCount = (data) => async (dispatch) => {
  API.card.visit.getVisitCount(data).then((res) => {
    dispatch({ type: "GET_VISIT_COUNT", payload: res.data.data });
  });
};

export const addFutureVisits = (data) => async (dispatch) => {
  dispatch({ type: "ADD_FUTURE_VISITS", payload: data });
};

export const updateFutureVisits = (data) => async (dispatch) => {
  dispatch({ type: "UPDATE_FUTURE_VISITS", payload: data });
};

export const getCarrierList = () => async (dispatch) => {
  API.card.probe.getCarriers().then((res) => {
    dispatch({ type: "GET_CARRIER_LIST", payload: res.data.data });
  });
};

export const updateProbes = (data) => async (dispatch) => {
  dispatch({ type: "UPDATE_PROBES", payload: data });
};

export const setSelectedTab = (data) => async (dispatch) => {
  dispatch({ type: "SET_SELECTED_TAB", payload: data });
};

export const deleteSelectedTab = () => async (dispatch) => {
  dispatch({ type: "DELETE_SELECTED_TAB" });
};

export const selectVisit = (data) => async (dispatch) => {
  dispatch({ type: "SELECT_VISIT", payload: data });
};

export const deleteSelectedVisit = () => async (dispatch) => {
  dispatch({ type: "DELETE_SELECTED_VISIT" });
};

export const getMessagesList = (params) => async (dispatch) => {
  API.message.getList(params).then((res) => {
    dispatch({type: "GET_MESSAGES_LIST", payload: res.data});
  });
};

export const getGlobalMessagesList = () => async (dispatch) => {
  dispatch({ type: "SET_LOADING", payload: true });
  API.message.getGlobalList().then((res) => {
    dispatch({type: "GET_GLOBAL_MESSAGES_LIST", payload: res.data});
    dispatch({ type: "SET_LOADING", payload: false });
  });
};

export const sendMessage = (data) => async (dispatch) => {
  API.message.sendMessage(data).then((res) => {
    dispatch({type: "SEND_MESSAGE", payload: res.data.data});
  });
};

export const sendGlobalMessage = (data) => async (dispatch) => {
  API.message.sendGlobalMessage(data).then((res) => {
    dispatch({type: "SEND_GLOBAL_MESSAGE", payload: res.data.data});
  });
};

export const editMessage = (id, data) => async (dispatch) => {
  API.message.editMessage(id, data).then((res) => {
    dispatch({type: "EDIT_MESSAGE", payload: res.data.data});
  });
};

export const editGlobalMessage = (id, data) => async (dispatch) => {
  API.message.editGlobalMessage(id, data).then((res) => {
    dispatch({type: "EDIT_GLOBAL_MESSAGE", payload: res.data.data});
  });
};

export const deleteMessage = (id) => async (dispatch) => {
  API.message.deleteMessage(id).then((res) => {
    dispatch({type: "DELETE_MESSAGE", payload: res.data.data});
  });
};

export const deleteGlobalMessage = (id) => async (dispatch) => {
  API.message.deleteGlobalMessage(id).then((res) => {
    dispatch({type: "DELETE_GLOBAL_MESSAGE", payload: res.data.data});
  });
};

export const setSubTab = data => async dispatch => {
  dispatch({type: "SET_SELECTED_SUB_TAB", payload: data});
};