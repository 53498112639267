import React from 'react';
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    PDFViewer,
    Link,
    Font
} from "@react-pdf/renderer";

Font.register({
    family: "Roboto",
    src:
        "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf"
});

// Create styles
const styles = StyleSheet.create({
    page: {
        fontFamily: "Roboto",
        padding: 20,
        backgroundColor: "#f5f5f5",
        color: "white",
    },
    section: {
        margin: 10,
        padding: 10,
    },
    viewer: {
        width: window.innerWidth, //the pdf viewer will take up all of the width and height
        height: window.innerHeight,
    },
});

export default function CredentialsDocument({creds}) {
    return (
        <PDFViewer style={styles.viewer}>
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={{
                        flex: 0.2,
                        backgroundColor: '#3f51b5',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Text style={{
                            marginBottom: 20,
                        }}>Dane pacjenta do logowania</Text>
                        <Text style={{fontSize: 16}}>To są wygenerowane dane uwierzytelniające</Text>
                        <Text style={{fontSize: 16}}>
                            do zalogowania się na koncie pacjenta
                        </Text>
                    </View>
                    <View style={{
                        flex: 0.8,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <View
                            style={{
                                backgroundColor: '#e0e0e0',
                                padding: 10,
                                borderRadius: 15,
                            }}
                        >
                            <Text style={{fontSize: 25, color: '#212121', marginBottom: 20}}>Login: {creds.login}</Text>
                            <Text style={{fontSize: 25, color: '#212121'}}>Hasło: {creds.password}</Text>
                        </View>
                        <View style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: 50
                        }}>
                            <Text style={{fontSize: 25, color: '#212121', textAlign: 'center'}}>Adres URL do zalogowania:</Text>
                            <Text style={{fontSize: 25, color: '#212121'}}>{process.env.REACT_APP_PATIENT_LOGIN_URL}</Text>
                        </View>
                    </View>
                </Page>
            </Document>
        </PDFViewer>
    )
}
