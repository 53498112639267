import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import TrashIcon from "assets/images/icons/trash.svg";
import moment from "moment";
import * as Actions from "store/actions/CardAction";
import API from "apis/API";

const useStyles = makeStyles((theme) => ({
  card: {
    border: "1px solid #DDE3EE",
    borderRadius: 8,
    boxShadow: "none",
    marginBottom: 16,
    background: "#F7F8FB",
    [theme.breakpoints.up("lg")]: {
      background: "#FFF",
    },
  },
  cardHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #DDE3EE",
    padding: "16px 24px",
    [theme.breakpoints.up("lg")]: {
      padding: "18px 32px 15px",
    },
  },
  name: {
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: 20,
    color: "#003366",
    marginBottom: 8,
    [theme.breakpoints.up("lg")]: {
      fontSize: 16,
    },
  },
  time: {
    fontFamily: "Lato",
    fontSize: 14,
    color: "#6C7689",
  },
  cardBody: {
    padding: "16px 24px",
    [theme.breakpoints.up("lg")]: {
      padding: 32,
    },
    "& p": {
      margin: 0,
    },
    "& img": {
      width: "100%",
    },
  },
  description: {
    fontFamily: "Lato",
    fontSize: 16,
    color: "#003366",
  },
  deleteButton: {
    width: 32,
    minWidth: 32,
    height: 32,
    background: "#D26360",
    borderRadius: 8,
    "&:hover": {
      background: "#D26360",
    },
    "& img": {
      width: 12.5,
      height: 12.5,
      filter: "brightness(0) invert(1)",
    },
  },
}));

const DoctorComment = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isDeleting, setIsDeleting] = useState(false);
  const handleDelete = (id) => {
    setIsDeleting(true);
    API.card.note
      .delete(id)
      .then((res) => {
        setIsDeleting(false);
        dispatch(Actions.deleteNote(id));
      })
      .catch((err) => {
        setIsDeleting(false);
        console.log(err);
      });
  };

  return (
    <Card className={classes.card}>
      <Box className={classes.cardHeader}>
        <Box>
          <Typography className={classes.name}>
            {props.author?.firstname} {props.author?.surname}
          </Typography>
          <Typography className={classes.time}>
            Dodano: {moment(props.updated_at).format("DD.MM.YYYY")}
          </Typography>
        </Box>
        <Button
          className={classes.deleteButton}
          onClick={() => handleDelete(props.id)}
        >
          {isDeleting ? (
            <CircularProgress size={18} style={{ color: "#FFF" }} />
          ) : (
            <img src={TrashIcon} alt=":( Not Found" />
          )}
        </Button>
      </Box>
      <Box className={classes.cardBody}>
        <Box
          className={classes.description}
          dangerouslySetInnerHTML={{ __html: props.content }}
        />
      </Box>
    </Card>
  );
};

export default DoctorComment;
