import React, { useEffect, useState } from "react";
import { makeStyles, Modal as MuiModal, Backdrop, Fade, Card, Typography, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import CloseIcon from "assets/images/icons/close.svg";
import IconButton from "components/Buttons/IconButton";
import FormInput from "components/form/FormInput";
import PrimaryButton from "components/Buttons/PrimaryButton";

import Validator, { Required, validate } from 'utils/Validator';
import DefaultButton from "components/Buttons/DefaultButton";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    padding: "25px",
    width: "500px",
  },
  title: {
    fontWeight: "bold",
    fontSize: 20,
    lineHeight: 1.4,
    color: "#003366"
  },
  subTitle: {
    fontWeight: "bold",
    color: "#454D5F",
    marginBottom: theme.spacing(1)
  },
}));

const Modal = props => {
  const { openModal, onClose, onSave } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const [data, setData] = useState({reason: ''});
  const [errors, setErrors] = useState(null);

  const Validators = {
    reason: new Validator(Required),
  };

  const handleSave = () => {
    let _errors = validate(data, Validators);
    setErrors(_errors);
    if (_errors) return;

    onSave(2, data);
    onClose();
  }

  const handleChange = e => {
    const target = e.target;
    setData(prev => ({ ...prev, [target.name]: target.value }));
  }

  return (
    <MuiModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openModal}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModal}>
        <Card className={classes.paper}>
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography className={classes.title}>{t(`probe-sets.reject_order`)}</Typography>
            <IconButton icon={CloseIcon} onClick={onClose} />
          </Box>

          <FormInput
            name="reason"
            title={'Powód odrzucenia'}
            value={data?.reason}
            onChange={handleChange}
            error={errors?.reason}
            multiline={true}
            minRows={6}
            required
          />

          <Box display="flex" justifyContent="flex-end">
            <DefaultButton
              title={t('cancel')}
              onClick={onClose}
            />
            <PrimaryButton
              title={t('send')}
              onClick={handleSave}
            />
          </Box>
        </Card>
      </Fade>
    </MuiModal>
  );
};

export default Modal;
