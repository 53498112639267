import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  makeStyles,
  TableCell,
  TableRow,
  Typography,
  LinearProgress
} from "@material-ui/core";
import { useToasts } from "react-toast-notifications";
import { Link } from "react-router-dom";
import _ from "lodash";

import API from "apis/API";
import Routes from "router/Routes";
import PrimaryButton from "components/Buttons/PrimaryButton";
import DeleteButton from "components/Buttons/DeleteButton";
import DataTable from "components/DataTable";
import Title from "components/text/Title";
import DeleteModal from "components/modals/DeleteModal";

import PlusIcon from "assets/images/icons/plus.svg";

import UnitModal from "../../components/modals/Modal";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "22px 32px",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2, 0, 10),
      background: "#FFF",
    },
  },
  toolbar: {
    padding: theme.spacing(0, 2),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 26,
    [theme.breakpoints.down("md")]: {
      marginBottom: 16,
    },
  },
  deletedRow: {
    '& > td, & p': {
      color: '#959595 !important'
    }
  },
}));

const UnitsList = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const columns = [
    { id: "id", label: "ID", sortable: true },
    { id: "name", label: "PLACÓWKA", sortable: true },
    { id: "address", label: "ADRES" },
    { id: "action", label: "" },
  ];
  const [totalCount, setTotalCount] = useState(0);
  const [data, setData] = useState(null);
  const [editingData, setEditingData] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [selectedRestoreId, setSelectedRestoreId] = useState(null);
  const [filters, setFilters] = useState({
    limit: 20,
    page: 1,
    order_by: "id",
    order_direction: "DESC",
    search: "",
  });

  useEffect(() => {
    loadFilteredUnits();
  }, [filters]);

  const handleChangeFilters = _filters => {
    if (!_.isEqual(_filters, filters)) {
      setFilters(_filters);
    }
  }

  const loadFilteredUnits = () => {
    API.units.index(filters).then(res => {
      setData(res.data.data);
      setTotalCount(res.data.total);
    });
  }

  const handleAdd = () => {
    setEditingData(null);
    setOpenModal(true);
  }

  const handleEdit = id => {
    setEditingData(data.find(item => item.id === id));
    setOpenModal(true);
  }

  const handleCloseModal = () => {
    setEditingData({});
    setOpenModal(false);
  }

  const handleDelete = id => {
    API.units.delete(id).then(res => {
      addToast(t('saved_message'), { appearance: 'success' });
      loadFilteredUnits();
      // For reload list.
      //setTotalCount(0);
    });
  }

  const handleSave = data => {
    (data?.id ? API.units.update : API.units.store)(data, data?.id).then(res => {
      addToast(t('saved_message'), { appearance: 'success' });
      loadFilteredUnits();
      // For reload list.
      //setTotalCount(0);
    });
  }

  const handleRestore = () => {
    API.units.restore(selectedRestoreId).then(res => {
      addToast(t('restore'), { appearance: 'success' });
      loadFilteredUnits();
    })
  }

  const handleOpenRestoreModal = id => () => {
    setSelectedRestoreId(id);

  }

  const handleCloseRestoreModal = () => {
    setSelectedRestoreId(null);
  }

  const renderRows = () => (
    data.map((row, index) => (
      <TableRow key={row.id} className={row.deleted_at ? classes.deletedRow : ''}>
        <TableCell>{row.id}</TableCell>
        <TableCell>{row.name}</TableCell>
        <TableCell>
          <Typography>{row.postcode ? row.postcode + ", " : ""} {row.city}</Typography>
          <Typography>{row.street}</Typography>
        </TableCell>

        <TableCell>
          {row.deleted_at &&
            <PrimaryButton
              onClick={handleOpenRestoreModal(row.id)}
              title={t('restore')}
            />
          }
          {!row.deleted_at &&
            <Box display="flex">
              <Link to={Routes.Units.Calendar(row.id)}>
                <PrimaryButton title={t('units.booking_availability_hours')} />
              </Link>
              <PrimaryButton
                onClick={() => handleEdit(row.id)}
                title={t('edit')}
              />
              <DeleteButton
                onDelete={() => handleDelete(row.id)}
                title={t('units.confirm_delete')}
              />
            </Box>
          }
        </TableCell>
      </TableRow>
    ))
  );

  return (
    <Box className={classes.root}>
      <Box className={classes.toolbar}>
        <Title>{t('units.list')} ({totalCount})</Title>
        <PrimaryButton
          onClick={handleAdd}
          title={t('units.add')}
          icon={PlusIcon}
        />
      </Box>
      {!data ? <LinearProgress /> :
        <DataTable
          searchLabel={t('units.search')}
          searchPlaceholder={t('units.enter_unit_name')}
          columns={columns}
          totalCount={totalCount}
          onChangeFilters={handleChangeFilters}
          renderRows={renderRows}
        />}
      <UnitModal
        openModal={openModal}
        onClose={handleCloseModal}
        editingData={editingData}
        onSave={handleSave}
      />
      {/* Restore modal */}
      <DeleteModal
        title={"Czy na pewno chcesz przywrócić wybrany element?"}
        openModal={selectedRestoreId}
        onClose={handleCloseRestoreModal}
        onDelete={handleRestore}
      />
    </Box>
  );
};

export default UnitsList;
