import React, {useState} from "react";
import {Box, Button, makeStyles} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        width: "100%",
        borderRadius: 8,
        border: "1px solid #DDE3EE",
    },
    tabButton: {
        width: "50%",
        height: 56,
        borderRadius: 8,
        fontFamily: "Lato",
        fontSize: 16,
        color: "#6C7689",
        textTransform: "none",
        padding: 0,
        [theme.breakpoints.up("lg")]: {
            padding: "0 32px",
        },
    },
    selectedTabButton: {
        background: "#E5F2FF",
        fontWeight: 700,
        color: "#0066CC",
    },
}));

const SwitchTab = props => {
    const {onChange, items} = props;
    const classes = useStyles();
    const [selectedTab, setSelectedTab] = useState(items[0].id);
    const handleChangeTab = (tabID) => {
        setSelectedTab(tabID);
        onChange(tabID);
    };

    return (
        <Box className={classes.root}>
            {props.items.map((tab) => (
                <Button
                    key={tab.id}
                    className={clsx(
                        classes.tabButton,
                        {[classes.selectedTabButton]: tab.id === selectedTab}
                    )}
                    onClick={() => handleChangeTab(tab.id)}
                >
                    {tab.name}
                </Button>
            ))}
        </Box>
    );
};

export default SwitchTab;
