import React from "react";
import { Box, makeStyles, Switch as MuiSwitch, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.spacing(5.5),
    height: theme.spacing(2.75),
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: "#CBD6EA",
    '&$checked': {
      transform: 'translateX(22px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 18,
    height: 18,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid #DDE3EE`,
    borderRadius: 34,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}));

const Switch = props => {
  const { label, appendLabel = false, ...rest } = props;
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center">
      {!appendLabel && <Typography style={{ marginRight: 16 }}>{label}</Typography>}
      <MuiSwitch classes={classes} {...rest}></MuiSwitch>
      {appendLabel && <Typography style={{ marginLeft: 16 }}>{label}</Typography>}
    </Box>
  );
};

export default Switch;
