import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  makeStyles,
  Typography,
  TableRow,
  TableCell,
} from "@material-ui/core";
import clsx from "clsx";
import moment from "moment";

import CloseIcon from "assets/images/icons/close.svg";
import PrintIcon from "assets/images/icons/print.svg";
import { useTranslation } from "react-i18next";
import TextInput from "components/form/TextInput";
import ProbesTable from "./ProbesTable";

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiPaper-root": {
      width: 800,
      maxWidth: 800
    },
  },
  dialogHeader: {
    padding: "20px 32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiTypography-root": {
      fontFamily: "Lato",
      fontWeight: 700,
      fontSize: 20,
      color: "#003366",
    },
  },
  dialogBody: {
    padding: "20px 32px 0",
  },
  closeButton: {
    width: 32,
    height: 32,
    minWidth: 32,
    border: "1px solid #DDE3EE",
    borderRadius: 8,
  },
  button: {
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2, 3),
    fontFamily: "Lato",
    fontWeight: "bold",
    fontSize: theme.spacing(2),
    lineHeight: 1.5,
  },
  cancelButton: {
    border: "1px solid #A6AEBF",
    color: "#003366",
    marginRight: theme.spacing(2),
  },
  printButton: {
    background: "#003366",
    color: "#FFFFFF",
    "&:hover": {
      background: "#003366",
    }
  },
}));

const materialTypes = {
  venous: 'Żylna',
  capillary: "Włośniczkowa"
};

const DownloadLabelModal = ({ defaultProbes, open, onClose, printProbes, ...rest }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [probes, setProbes] = useState([]);
  const printProbesHandler = printProbes;

  useEffect(() => {
    if (!defaultProbes) return;

    setProbes(defaultProbes.map(probe => {
      probe.amount = 1;
      return probe;
    }));
  }, [defaultProbes]);

  const handleChange = i => e => {
    setProbes(prev => {
      prev[i].amount = e.target.value || 0;
      return [...prev];
    });
  };

  const renderRow = (probe, i) => (
    <TableRow key={i}>
      <TableCell>{probe.code}</TableCell>
      <TableCell>{materialTypes[probe.type_of_material]}</TableCell>
      <TableCell>{t('probes.'+probe.status)}</TableCell>
      <TableCell>
        <TextInput
          style={{ width: 70 }}
          value={probe.amount || 1}
          onChange={handleChange(i)}
          type="number"
          InputProps={{ inputProps: { min: 0, max: 10 } }}
          gutterBottom={false}
        />
      </TableCell>
    </TableRow>
  );

  return (
    <Dialog onClose={onClose} className={classes.dialog} open={open}>
      <Box className={classes.dialogHeader}>
        <Typography>{t('probe-sets.downloadLabel')}</Typography>
        <Button onClick={onClose} className={classes.closeButton}>
          <img src={CloseIcon} alt="Close" />
        </Button>
      </Box>
      <Box className={classes.dialogBody}>
        <ProbesTable
          probes={probes}
          renderRow={renderRow}
          downloadLabel
        />
      </Box>
      <Box display="flex" justifyContent="flex-end" p={4}>
        <Button onClick={onClose} className={clsx(classes.button, classes.cancelButton)}>
          {t('cancel')}
        </Button>
        <Button className={clsx(classes.button, classes.printButton)} onClick={printProbesHandler()}>
          <img src={PrintIcon} alt="print label" style={{ marginRight: 10 }} />
          {t('probe-sets.printLabel')}
        </Button>
      </Box>
    </Dialog>
  );
};

export default DownloadLabelModal;
