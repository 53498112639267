import React, { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
import Routes from "router/Routes";
import Storage from "utils/Storage";
import { ReactComponent as BookmarkActiveImage } from "assets/images/icons/bookmark_active.svg";
import { ReactComponent as BookmarkNormalImage } from "assets/images/icons/bookmark_normal.svg";
import { ReactComponent as LogoutIcon } from "assets/images/icons/logout.svg";
import {ReactComponent as KeyIcon} from "assets/images/icons/key.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100% - 90px)",
    padding: 0,
    paddingRight: '15px',
    overflowY: "auto",
    "& a": {
      textDecoration: "none",
    },
    "& .MuiListItem-root": {
      height: 55,
      borderRadius: 8,
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 3),
      "& .MuiListItemIcon-root": {
        minWidth: "auto",
        marginRight: theme.spacing(2),
      },
      "& .MuiTypography-root": {
        fontFamily: "Lato",
        fontWeight: 700,
        fontSize: 14,
        color: "#454D5F",
      },
    },
    "& .MuiCollapse-root": {
      marginBlock: 5,
      "& .MuiListItem-root": {
        marginLeft: 15,
      }
    }
  },
  active: {
    background: "#E5F2FF",
    "& .MuiTypography-root": {
      color: "#0066CC!important",
    },
  },
  logoutButton: {
    width: "auto",
    height: 55,
    position: "absolute",
    bottom: 10,
    left: 16,
    right: 16,
    borderRadius: 8,
    border: "1px solid #9F302D",
    "& .MuiTypography-root": {
      fontFamily: "Lato",
      fontWeight: 700,
      fontSize: 14,
      color: "#9F302D!important",
    },
  },
  passwordButton: {
    width: "auto",
    position: "absolute",
    bottom: 80,
    left: 16,
    right: 16,
    border: "1px solid #F1F3F8",
    background: "#F1F3F8",
    "& .MuiTypography-root": {
        color: "#003366!important",
    },
  },
  disabled: {
    pointerEvents: "none",
    opacity: 0.7
  }
}));

const Sidebar = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const navLinks = [
    {
      name: t("doctors.list"),
      link: Routes.Doctors.List,
    },
    {
      name: t("admins.list"),
      link: Routes.Admins.List,
    },
    {
      name: t("patients.list"),
      link: Routes.Patients.List,
    },
    {
      name: t("units.list"),
      link: Routes.Units.List,
    },
    {
      name: t("probe-sets.list"),
      link: Routes.ProbeSets.List,
    },
    {
      name: t("probes.list"),
      link: Routes.Probes.List,
    },
    {
      name: t("audit_trial.list"),
      link: Routes.AuditTrial.List,
    },
    {
      name: t("settings.title"),
      link: '/settings',
      children: [
        {
          name: t("settings.probe_config.title"),
          link: Routes.Settings.ProbeConfig
        },
        {
          name: t("settings.documents.doctor_documents"),
          link: Routes.Settings.Documents('doctor')
        },
        {
          name: t("settings.documents.patient_documents"),
          link: Routes.Settings.Documents('patient')
        },
        {
          name: t("settings.synevo.title"),
          link: Routes.Settings.Synevo
        },
        {
          name: t("settings.phone.title"),
          link: Routes.Settings.Phone
        },
      ]
    },
    {
      name: t("data_import.list"),
      link: Routes.DataImport.List,
    },
    {
      name: t("patients.message"),
      link: Routes.Messages.List,
    },
    {
      name: t("reports.name"),
      link: Routes.Reports,
    },
  ];
  const [openedMenu, setOpenedMenu] = useState();

  const handleLogout = () => {
    Storage.remove("token");
    Storage.remove("user");
    history.push(Routes.Auth.Login);
  };

  const handleChangePassword = () => {
    history.push(Routes.ChangePassword);
  };

  const handleMenuClick = (e, menu) => {
    if (!menu?.children) return;
    e.preventDefault();
    setOpenedMenu(prev => prev === menu.link ? '' : menu.link);
  };

  const renderMenu = (menuList) => menuList.map((menu, index) => {
    const isMatch = location.pathname.indexOf(menu.link) > -1;
    return (
      <React.Fragment key={index}>
        <Link
          to={menu.link}
          onClick={e => handleMenuClick(e, menu)}
        >
          <ListItem button className={clsx({ [classes.active]: isMatch })}>
            <ListItemIcon>
              {isMatch ? <BookmarkActiveImage /> : <BookmarkNormalImage />}
            </ListItemIcon>
            <ListItemText>{menu.name}</ListItemText>
          </ListItem>
        </Link>
        {menu?.children &&
          <Collapse in={openedMenu === menu.link} timeout="auto" unmountOnExit>
            {renderMenu(menu?.children)}
          </Collapse>
        }
      </React.Fragment>
    );
  });

  return (
    <>
      <List component="nav" className={`${classes.root} ${JSON.parse(Storage.get("user"))?.changed_password_at === null ? classes.disabled : ''}`}>
        {renderMenu(navLinks)}
      </List>
      <ListItem button onClick={handleChangePassword} className={classes.passwordButton}>
        <ListItemIcon>
          <KeyIcon/>
        </ListItemIcon>
        <ListItemText>{t("layout.changePassword")}</ListItemText>
      </ListItem>
      <ListItem button onClick={handleLogout} className={classes.logoutButton}>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText>{t("login.logout")}</ListItemText>
      </ListItem>
    </>
  );
};

export default Sidebar;
