const Routes = {
  Auth: {
    Login: "/login",
  },
  Units: {
		List: '/units',
		Calendar: (id = ':unitId') => `/units/calendar/${id}`
	},
  Doctors: {
		List: '/doctors',
		Edit: (id = ':id') => `/doctors/${id}/edit`,
		Create: '/doctors/create',
	},
  Admins: {
		List: '/admins',
		Edit: (id = ':id') => `/admins/${id}/edit`,
		Create: '/admins/create',
	},
	Patients: {
		List: '/patients',
		Edit: (id = ':id') => `/patients/${id}/edit`,
		Create: '/patients/create',
		Card: (patient_id = ':patient_id') => `/patients/card/${patient_id}`,
		Visit: (patient_id = ':patient_id', id = ':id') => `/patients/${patient_id}/visits/${id}`,
	},
	Probes: {
		List: '/probes',
		Show: (id = ':id') => `/probes/${id}`,
	},
	ProbeSets : {
		List: '/probe-sets',
		Show: (id = ':id') => `/probe-sets/${id}`,
	},
	AuditTrial: {
		List: '/audit_trial'
	},
	Settings: {
		ProbeConfig: '/settings/probe_config',
		Documents: (panel = ':panel') => `/settings/documents/${panel}`,
		Synevo: '/settings/synevo',
		Phone: '/settings/phone',
	},
	DataImport: {
		List: '/data_import'
	},
	Messages: {
		List: '/messages',
	},
	ChangePassword: "/changepassword",
	Reports: "/reports",
};

export default Routes;
