import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Modal as MuiModal, Backdrop, Fade, Card, Typography, Box, Select, MenuItem } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import NumberFormat from 'react-number-format';
import ReactToPrint from "react-to-print";

import CloseIcon from "assets/images/icons/close.svg";
import IconButton from "components/Buttons/IconButton";
import FormInput from "components/form/FormInput";
import PrimaryButton from "components/Buttons/PrimaryButton";
import DefaultButton from "components/Buttons/DefaultButton";
import Validator, { Email, Required, validate } from 'utils/Validator';
import API from "apis/API";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: 'auto'
  },
  paper: {
    padding: "25px",
    width: "500px",
    [theme.breakpoints.down('md')]: {
      marginInline: 10,
      height: 'calc(100% - 175px)',
      overflow: 'auto',
    },
  },
  title: {
    fontWeight: "bold",
    fontSize: 20,
    lineHeight: 1.4,
    color: "#003366"
  },
  subTitle: {
    fontWeight: "bold",
    color: "#454D5F",
    margin: theme.spacing(2, 0, 1)
  },
}));

const Modal = props => {
  const { openModal, onClose, onSave, editingData } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [units, setUnits] = useState();
  const [data, setData] = useState();
  const [errors, setErrors] = useState(null);
  const [printData, setPrintData] = useState(null);
  const printPDFRef = useRef();
  const printButtonRef = useRef();

  const Validators = {
    firstname: new Validator(Required),
    surname: new Validator(Required),
    email: new Validator(Required, Email),
    phone_number: new Validator(Required),
    unit_id: new Validator(Required),
  };

  useEffect(() => {
    API.units.all().then(res => {
      let _data = res.data.data;
      setUnits(_data);
      setData(prev => ({ ...prev, unit_id: _data?.[0]?.id }));
    });
  }, []);

  useEffect(() => {
    setData(prev => {
      prev = editingData?.id
        ? editingData
        : {
          firstname: '',
          surname: '',
          email: '',
          phone_number: '',
          unit_id: units?.[0]?.id,
        }
      return prev;
    });
    setErrors(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingData]);

  const handleSave = () => {
    let _errors = validate(data, Validators);
    setErrors(_errors);
    if (_errors) return;

    onSave(data);
    onClose();
  }

  const handleChange = e => {
    const target = e.target;
    setData(prev => ({ ...prev, [target.name]: target.value }));
  }

  const printLoginDetail = () => {
    API.doctors.printLoginDetail(editingData?.id).then(res => {
      setPrintData(res.data);
      printButtonRef.current.click();
    });
  }

  return (
    <MuiModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openModal}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModal}>
        <Card className={classes.paper}>
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography className={classes.title}>{editingData?.id ? t('doctors.edit') : t('doctors.add')}</Typography>
            <IconButton icon={CloseIcon} onClick={onClose} />
          </Box>
          <Typography className={classes.subTitle}>{t('personal_information')}</Typography>

          <FormInput
            name="firstname"
            title={t('doctors.firstname')}
            value={data?.firstname}
            onChange={handleChange}
            error={errors?.firstname}
            required
          />
          <FormInput
            name="surname"
            title={t('doctors.surname')}
            value={data?.surname}
            onChange={handleChange}
            error={errors?.surname}
            required
          />
          <FormInput
            name="email"
            title={t('doctors.email')}
            value={data?.email}
            onChange={handleChange}
            error={errors?.email}
            required
          />

          <NumberFormat
            name="phone_number"
            title={t('doctors.phone_number')}
            format="+## ###-###-###"
            allowEmptyFormatting
            customInput={FormInput}
            onValueChange={values => handleChange({ target: { name: 'phone_number', value: values.value } })}
            value={data?.phone_number}
            error={errors?.phone_number}
            required />

          <Typography className={classes.subTitle}>{t('units.list')}</Typography>
          <Select
            variant="outlined"
            name="unit_id"
            value={data?.unit_id}
            onChange={handleChange}
            fullWidth
          >
            {units?.map(unit => (
              <MenuItem value={unit.id} key={unit.id}>
                {unit.name} {unit.street} {unit.building_nr} {unit.postcode} {unit.city}
              </MenuItem>
            ))}
          </Select>

          {editingData?.id &&
            <>
              <Typography className={classes.subTitle}>{t('operations')}</Typography>
              <DefaultButton
                fullWidth
                style={{ marginInline: 0 }}
                title={t('generate_login_detail')}
                onClick={printLoginDetail}
              />
              <Box display="none">
                <ReactToPrint
                  trigger={() => (
                    <button ref={printButtonRef} style={{ display: "none" }}>
                      print
                    </button>
                  )}
                  content={() => printPDFRef.current}
                  documentTitle={printData?.login}
                />

                <Box p={2} ref={printPDFRef}>
                  <Typography>Login: {printData?.login}</Typography>
                  <Typography>Password: {printData?.password}</Typography>
                </Box>
              </Box>
            </>
          }

          <Box display="flex" justifyContent="flex-end" mt={3}>
            <DefaultButton
              title={t('cancel')}
              onClick={onClose}
            />
            <PrimaryButton
              title={t('save')}
              onClick={handleSave}
            />
          </Box>
        </Card>
      </Fade>
    </MuiModal>
  );
};

export default Modal;
