import React, { useEffect, useState } from "react";
import { makeStyles, Modal as MuiModal, Backdrop, Fade, Card, Typography, Box, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import moment from "moment";
import CloseIcon from "assets/images/icons/close.svg";
import Messages from "utils/Messages";
import Validator, { Required, validate } from 'utils/Validator';
import FormInput from "components/form/FormInput";
import Select from "components/form/Select";
import IconButton from "components/Buttons/IconButton";
import PrimaryButton from "components/Buttons/PrimaryButton";
import DefaultButton from "components/Buttons/DefaultButton";
import Switch from "components/form/Switch";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    padding: "25px",
    width: "500px",
  },
  title: {
    fontWeight: "bold",
    fontSize: 20,
    lineHeight: 1.4,
    color: "#003366"
  },
  subTitle: {
    fontWeight: "bold",
    color: "#454D5F",
    marginBottom: theme.spacing(1)
  },
}));

const RulesModal = props => {
  const { openModal, onClose, onSave, editingData, dayName } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const initData = {
    started_at: '',
    ended_at: '',
    type: 'enabled',
    is_global: false,
  };
  const [data, setData] = useState(initData);
  const [errors, setErrors] = useState(null);
  const typesList = [
    { label: t('units.unblock_hours'), value: 'enabled' },
    { label: t('units.block_hours'), value: 'disabled' },
  ];

  const Validators = {
    started_at: new Validator(Required),
    ended_at: new Validator(Required),
  };

  useEffect(() => {
    setData(editingData);
    setErrors(null);
  }, [editingData]);

  const handleClose = () => {
    setData(initData);
    onClose();
  }

  const handleSave = () => {
    let _errors = validate(data, Validators);

    if (!_errors) {
      let today = moment().format('YYYY-MM-DD');
      let startedAt = data.started_at ? moment(`${today} ${data.started_at}`) : '';
      let endedAt = data.ended_at ? moment(`${today} ${data.ended_at}`) : '';
      if (startedAt > endedAt) {
        _errors = {
          ended_at: Messages.AfterStartDate
        };
      }
    }

    setErrors(_errors);
    if (_errors) return;

    onSave(data);
    handleClose();
  }

  const handleChange = e => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    setData(prev => ({ ...prev, [target.name]: value }));
  }

  return (
    <MuiModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openModal}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModal}>
        <Card className={classes.paper}>
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography className={classes.title}>{t('units.make_changes')}</Typography>
            <IconButton icon={CloseIcon} onClick={handleClose} />
          </Box>
          <Typography className={classes.subTitle}>{t('units.choose_hours')}</Typography>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <FormInput
                title={t('from')}
                name="started_at"
                type="time"
                value={data?.started_at}
                onChange={handleChange}
                required={false}
                error={errors?.started_at}
              />
            </Grid>
            <Grid item md={6}>
              <FormInput
                title={t('to')}
                name="ended_at"
                type="time"
                value={data?.ended_at}
                onChange={handleChange}
                required={false}
                error={errors?.ended_at}
              />
            </Grid>
          </Grid>
          <Typography className={classes.subTitle}>{t('units.operation')}</Typography>
          <Select
            options={typesList}
            name="type"
            value={data?.type}
            onChange={handleChange}
            fullWidth
          />
          <Switch
            name="is_global"
            checked={data?.is_global}
            onChange={handleChange}
            label={`${t('units.apply_for_all_days')}: ${dayName}`}
            appendLabel
          />

          <Box display="flex" justifyContent="flex-end" mt={5}>
            <DefaultButton
              title={t('cancel')}
              onClick={handleClose}
            />
            <PrimaryButton
              title={t('save')}
              onClick={handleSave}
            />
          </Box>
        </Card>
      </Fade>
    </MuiModal>
  );
};

export default RulesModal;
