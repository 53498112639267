import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Moment from "moment";

import API from "apis/API";
import * as Actions from "store/actions/AuthAction";
import Storage from "utils/Storage";

const AppContainer = ({ children }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [validating, setValidating] = useState(true);

  Moment.locale('pl');

  useEffect(() => {
    setValidating(true);
    API.auth.validateToken()
      .then(res => {
        dispatch(Actions.setAuthenticatedUserData(res.data.user));
        Storage.set('token', res.data.access_token);
        Storage.set('user', JSON.stringify(res.data.user));
        setValidating(false);
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          Storage.remove('token');
          Storage.remove('user');
          history.push('/login');
          setValidating(false);
          return;
        }

        setValidating(false);
        throw error;
      });
  }, [history]);

  return !validating && children;
}

export default AppContainer;