import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  makeStyles,
  Typography,
} from "@material-ui/core";
import TextInput from "components/form/TextInput";
import InclusionDose from "./InclusionDose";
import * as Actions from "store/actions/PatientAction";
import Validator, { Email, Required, validate, NotEmptyArray } from "utils/Validator";


import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";

const useStyles = makeStyles((theme) => ({
  formGroupTitle: {
    marginBottom: 40,
    "& > .MuiTypography-root": {
      fontSize: 20,
      fontWeight: 700,
      color: "#454D5F",
    },
  },
  formGroupContent: {
    paddingLeft: 40,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
    },
  },
  ticket: {
    marginBottom: 56,
  },
  ticketBody: {
    padding: theme.spacing(0, 5),
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
  checkbox: {
    display: "flex",
    alignItems: "center",
    "& > .MuiFormControlLabel-root": {
      marginRight: theme.spacing(1),
      "& > .MuiTypography-root": {
        fontWeight: 700,
      },
    },
    "& .MuiTypography-root": {
      fontSize: 16,
      color: "#22272F",
    },
  },
  route: {
    display: "flex",
    alignItems: "center",
    "& .MuiTypography-root": {
      fontSize: 16,
      fontWeight: 700,
      color: "#003366",
    },
  },
  formLabel: {
    fontSize: 16,
    fontWeight: 700,
    marginRight: 24,
  },
  value: {
    fontSize: 16,
    fontWeight: 700,
    color: "#003366",
    marginRight: 6,
  },
  formFooter: {
    "& .MuiButton-root": {
      height: 56,
      borderRadius: 8,
      fontSize: 16,
      fontWeight: 700,
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
      },
    },
  },
  nextButton: {
    background: "#003366",
    color: "#FFF",
    "&:hover": {
      background: "#003366",
    },
  },
  prevButton: {
    background: "#FFF",
    color: "#003366",
    border: "1px solid #A6AEBF",
    "&:hover": {
      background: "#FFF",
    },
  },
}));

const InclusionCriteria = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const inclusionData = useSelector(
    (state) => state.patient.createPatient.inclusion
  );
  const [formData, setFormData] = useState(
    inclusionData || {
      group_id: null,
      subgroup_id: null,
      dose: null,
      interval: null,
    }
  );

  const { addToast } = useToasts();
  const { t } = useTranslation();

  const { criteriaGroupList } = useSelector((state) => state.patient);
  const handleChange = (e, dose, group_id) => {
    if (parseInt(formData.subgroup_id) === parseInt(e.target.value)) {
      setFormData({ ...formData, subgroup_id: null, dose: null });
    } else {
      setFormData({ ...formData, subgroup_id: e.target.value * 1, dose, group_id, interval: null });
    }
  };

  const handleChangeGroup = (e) => {

    if (parseInt(formData.group_id) === parseInt(e.target.value)) {
      setFormData({ ...formData, group_id: null, dose: null, subgroup_id: null });
    } else {
      setFormData({ ...formData, group_id: parseInt(e.target.value), subgroup_id: null, dose: null, interval: null });
    }
  };

  const handleChangeDose = (value, subGroupID, groupID) => {
    setFormData({ ...formData, dose: value, subgroup_id: subGroupID, group_id: groupID });
  };

  const handleChangeInterval = (e, subGroupID, groupID) => {
    setFormData({ ...formData, interval: e.target.value, subgroup_id: subGroupID, group_id: groupID  });
  };

  const handleNext = () => {
    if (!formData.subgroup_id) {
       addToast(t("patients.createPatient.inclusion.selectAnyInclusion"), {
         appearance: "warning",
       });
       return;
    }
    props.handleNext();
  };

  useEffect(() => {
    dispatch(Actions.setInclusionData(formData));
  }, [formData, dispatch]);

  return (
    <Box>
      {criteriaGroupList.map((group) => {
        return (
          <Box className={classes.formGroup} key={group.id}>
            <Box>
              <FormControlLabel
                control={<Radio
                  color="primary"
                  checked={group.id === formData.group_id}
                  name="group_id"
                  value={group.id}
                  onClick={(e) =>
                    handleChangeGroup(e)
                  }
                />}
                label={`${group.nr}. ${group.name}`}
                className={classes.formGroupTitle}
              />
            </Box>
            <Box className={classes.formGroupContent}>
              {group.sub_groups.map((subGroup, index) => {
                return (
                  <Box className={classes.ticket} key={index}>
                    <Grid container className={classes.ticketHeader}>
                      <Grid item xs={12} lg={6} className={classes.checkbox}>
                        <FormControlLabel
                          control={
                            <Radio
                              checked={subGroup.id === formData.subgroup_id}
                              color="primary"
                              name="sub_group_id"
                              value={subGroup.id}
                              onClick={(e) =>
                                handleChange(e, subGroup.doses[0], group.id)
                              }
                            />
                          }
                          label={subGroup.name}
                        />
                        <Typography> - {subGroup.description}</Typography>
                      </Grid>
                      <Grid item xs={12} lg={6} className={classes.route}>
                        <Typography>
                          {subGroup.medicines.split(",").join(" / ")}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Box className={classes.ticketBody}>

                        <Grid container alignItems="center">
                          {subGroup.is_fixed_dose === 1 && subGroup.id === formData.subgroup_id &&
                          <Grid item sm xs={12}>
                            <Grid container>
                              <Typography className={classes.formLabel}>
                                Dawka jednej tabletki:
                              </Typography>
                              <Typography className={classes.value}>
                                {`${subGroup.doses[0]} mg, `}
                              </Typography>
                            </Grid>
                          </Grid>}

                          {subGroup.is_fixed_dose !== 1 && subGroup.id === formData.subgroup_id &&
                              <Grid item xs={12}><InclusionDose
                                  subGroupID={subGroup.id}
                                  groupID={group.id}
                                  doses={subGroup.doses}
                                  onChange={handleChangeDose}
                              /></Grid>
                          }
                          {subGroup.allow_custom_interval === 1 && subGroup.id === formData.subgroup_id &&
                           <Grid item>
                            <Box display="flex" alignItems="center">
                              <Typography className={classes.formLabel}>
                                częstość dawkowania:
                              </Typography>
                              <TextInput
                                placeholder="Wprowadź wartość"
                                onChange={(e) => handleChangeInterval(e, subGroup.id, group.id)}
                                value={subGroup.id === formData.subgroup_id ? formData.interval : ''}
                              />
                            </Box>
                          </Grid>}
                        </Grid>

                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        );
      })}

      <Grid container spacing={2} className={classes.formFooter}>
        <Grid item xs={12} lg={6}>
          <Button
            className={classes.prevButton}
            onClick={props.handleBack()}
            fullWidth
          >
            Poprzedni krok
          </Button>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Button
            className={classes.nextButton}
            onClick={() => handleNext()}
            fullWidth
          >
            Następny krok
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InclusionCriteria;
