import React from "react";
import {
  Box,
  FormControlLabel,
  makeStyles,
  Radio,
  Typography,
} from "@material-ui/core";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  formLabel: {
    display: 'inline',
    fontSize: 16,
    fontWeight: 700,
    marginRight: 24,
    [theme.breakpoints.down("md")]: {
      display: 'block'
    },
  },
  radioGroup: {
    display: "inline",
   // flexDirection: "row",
    "& .MuiFormControlLabel-root": {
      marginRight: 40,
      "& .MuiTypography-root": {
        fontSize: 16,
        color: "#22272F",
      },
    },
  },
}));

const InclusionDose = (props) => {
  const classes = useStyles();
  const handleChange = (e) => {
    props.onChange(e.target.value * 1, props.subGroupID, props.groupID);
  };

  const { subgroup_id, dose } = useSelector((state) => {
    return {
      subgroup_id: state.patient.updatePatient.inclusion?.subgroup_id || null,
      dose: state.patient.updatePatient.inclusion?.dose || null,
    };
  });

  return (
    <>
      <Typography className={classes.formLabel}>Dawka:</Typography>
      <Box className={classes.radioGroup}>
        {props.doses.map((d) => {
          return (
            <FormControlLabel
              key={d}
              label={`${d} mg`}

              control={
                <Radio
                  checked={subgroup_id === props.subGroupID && dose === d}
                  color="primary"
                  name="dose"
                  value={d}
                  onChange={handleChange}
                />
              }
            />
          );
        })}
      </Box>
    </>
  );
};

export default InclusionDose;
