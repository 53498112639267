import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Grid,
  makeStyles,
  Typography,
  Tooltip,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactCalendar from "react-calendar";
import { useToasts } from "react-toast-notifications";
import moment from "moment";
import 'moment/locale/pl';
import clsx from "clsx";

import API from "apis/API";
import Routes from "router/Routes";
import Title from "components/text/Title";
import Constants from "utils/Constants";
import Switch from "components/form/Switch";
import IconButton from "components/Buttons/IconButton";
import ArrowRightImage from "assets/images/icons/arrow-right.svg";
import ArrowLeftImage from "assets/images/icons/arrow-left.svg";
import CirclePlusIcon from "assets/images/icons/plus.svg";
import DeleteIcon from "assets/images/icons/trash.svg";
import EditIcon from "assets/images/icons/edit.svg";
import RulesModal from "../../../components/modals/RulesModal";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
      background: "#FFF",
      paddingBottom: theme.spacing(10),
    },
  },
  card: {
    border: "1px solid #DDE3EE",
    borderRadius: theme.spacing(1),
    padding: theme.spacing(4),
    boxShadow: "none",
    [theme.breakpoints.down("md")]: {
      border: "none",
      padding: 0,
    },
  },
  calendar: {
    width: "100%!important",
    padding: 0,
    border: "none",
    background: "none",
    borderRadius: 0,
    [theme.breakpoints.up("lg")]: {
      borderRadius: 8,
    },
    [theme.breakpoints.down("md")]: {
      "& > div": {
        padding: "0px!important",
        justifyContent: "space-between",
        marginBottom: "0px!important",
      },
    },
  },
  tile: {
    padding: 1,
  },
  tileContent: {
    height: 132,
    background: "#FFFFFF",
    border: "1px solid #DDE3EE",
    borderRadius: 8,
    fontSize: 24,
    color: "#003366",
    transition: "0.1s",
    textAlign: "right",
    padding: theme.spacing(1),
    position: "relative",
    "&:hover": {
      opacity: 0.5,
    },
    [theme.breakpoints.down("lg")]: {
      height: 110,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 20,
      fontWeight: 700,
      color: "#003366",
    },
    [theme.breakpoints.down("md")]: {
      height: 50,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 20,
      fontWeight: 700,
      color: "#003366",
    },
  },
  activeTileContent: {
    background: "#E5F2FF!important",
    border: "1.5px solid #0066CC",
    [theme.breakpoints.down("md")]: {
      background: "#0066CC!important",
      border: "1.5px solid #0066CC",
      color: "#FFF",
    },
  },
  hasRuleTile: {
    [theme.breakpoints.down("md")]: {
      border: "1.5px solid #003366",
    },
  },
  navigationButton: {
    minWidth: "32px!important",
    width: 32,
    height: 32,
    borderRadius: 8,
    background: "#FFF!important",
    border: "1.5px solid #DDE3EE!important",
  },
  rules: {
    background: "#F7F8FB",
    border: "1px solid #DDE3EE",
    borderRadius: 8,
    marginLeft: theme.spacing(3),
    marginTop: 132,
    [theme.breakpoints.down("md")]: {
      background: "#FFF",
      marginTop: 24,
      marginLeft: 0,
      border: "none",
      borderRadius: 0,
      borderTop: "1px solid #DDE3EE",
    },
  },
  ruleDate: {
    padding: theme.spacing(3),
    color: "#003366",
    fontSize: 24,
    fontWeight: 700,
    borderBottom: "1px solid #DDE3EE",
    "& .MuiBox-root": {
      marginTop: 16,
      "& .MuiTypography-root": {
        color: "#003366",
        fontWeight: 700,
      }
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(3, 0, 2),
      fontSize: 16,
      borderBottom: "none",
    },
  },
  ruleList: {
    maxHeight: 555,
    minHeight: 555,
    overflowY: "scroll",
    padding: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      maxHeight: "auto",
      minHeight: "auto",
      padding: 0,
      overflowY: "auto",
    },
  },
  rule: {
    display: "flex",
    justifyContent: "space-between",
    border: "1px solid #DDE3EE",
    background: "#FFFFFF",
    borderRadius: 8,
    overflow: "hidden",
    marginBottom: 16,
    padding: 20,
    color: "#003366"
  },
  ruleTitle: {
    fontWeight: 700,
    fontSize: 20
  },
  ruleHours: {
    fontWeight: 700,
    fontSize: 16
  },
  ruleIconButton: {
    marginInline: 4,
    "& img": {
      width: 20,
      height: 20
    }
  },
  count: {
    position: "absolute",
    bottom: 15,
    left: 15,
    display: "flex",
    alignItems: "center",
    "& .MuiBox-root": {
      width: 30,
      height: 30,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: 9,
      fontWeight: 700,
      fontSize: 16,
      lineHeight: 1.5,
      color: "#FFFFFF",
      background: "#003366",
      borderRadius: "50%",
    },
    "& .MuiTypography-root": {
      fontWeight: 700,
      fontSize: 16,
      color: "#003366",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  blockedDate: {
    position: "absolute",
    bottom: 15,
    left: 15,
    display: "flex",
    padding: "3px 10px",
    background: "#D26360",
    borderRadius: 34,
    fontSize: 10,
    fontWeight: 700,
    color: "#fff"
  },
  addBtn: {
    width: "100%",
    minHeight: 100,
    background: "#E6F2FF",
    marginBottom: 8,
    border: "2px dashed #3399FF",
    borderRadius: 8,
    cursor: "pointer",
  }
}));

const UnitCalendar = props => {
  const { unitId } = props.match.params;
  const { t } = useTranslation();
  const classes = useStyles();
  const { addToast } = useToasts();
  const [unit, setUnit] = useState(null);
  const [dayName, setDayName] = useState('');
  const [blockDay, setBlockDay] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [rules, setRules] = useState([]);
  const [rulesCount, setRulesCount] = useState([]);
  const [editingData, setEditingData] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    API.unitCalendar.getByFilters({
      date: moment(selectedDate).format('YYYY-MM-DD'),
      unit_id: unitId
    }).then(res => setRules(res.data.data));

    let str = moment(selectedDate).format('dddd');
    setDayName(str);
  }, [selectedDate, unitId]);

  useEffect(() => {
    API.units.show(unitId).then(res => setUnit(res.data.data));

    getRulesCount(selectedDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitId]);

  useEffect(() => {
    setBlockDay(() =>
      !!rules.find(rule => rule.started_at.slice(0, -3) === '00:00' && rule.ended_at.slice(0, -3) === '23:59')
    );
  }, [rules]);

  const getRulesCount = date => {
    API.unitCalendar.getRulesCount({
      unit_id: unitId,
      year: moment(date).format('YYYY'),
      month: moment(date).format('MM')
    }).then(res => setRulesCount(res.data));
  };

  const handleBlockDay = () => {
    let blockedDay = !blockDay;

    if (blockedDay) {
      handleSave({
        started_at: '00:00',
        ended_at: '23:59',
        type: 'disabled',
        is_global: false,
      });
    } else {
      let id = rules.find(rule => rule.started_at.slice(0, -3) === '00:00' && rule.ended_at.slice(0, -3) === '23:59')?.id;
      handleDelete(id);
    }

    setBlockDay(blockedDay);
  }

  const handleCloseModal = () => {
    setOpenModal(false);
    setEditingData(null);
  }

  const handleEdit = rule => {
    setEditingData(rule);
    setOpenModal(true);
  }

  const handleSave = data => {
    data = { ...data, unit_id: unit.id, date: moment(selectedDate).format('YYYY-MM-DD') };
    (data?.id ? API.unitCalendar.update : API.unitCalendar.store)(data, data?.id).then(res => {
      if (res.data.status === 'success') {
        addToast(t('saved_message'), { appearance: 'success', });
        setRules(res.data.data);
        getRulesCount(selectedDate);
      }
    });
  }

  const handleDelete = id => {
    API.unitCalendar.delete(id).then(res => {
      setRules(prev => prev.filter(item => item.id !== id));
      getRulesCount(selectedDate);
    });
  }

  const NextLabel = () => (
    <Button component="span" className={classes.navigationButton}>
      <img src={ArrowRightImage} alt="Next" />
    </Button>
  );

  const PrevLabel = () => (
    <Button component="span" className={classes.navigationButton}>
      <img src={ArrowLeftImage} alt="Prev" />
    </Button>
  );

  const getTileContent = date => {
    let ruleCount = rulesCount?.data?.find(rule => rule.date === moment(date).format('YYYY-MM-DD'));
    let globalRule = rulesCount?.global_rules?.[moment(date).day()] || null;

    if (!ruleCount) {
      ruleCount = globalRule;
    } else {
      ruleCount = { ...ruleCount };
      ruleCount.count += globalRule?.count || 0;
    }

    if (ruleCount
      && ruleCount.count === 1
      && ruleCount.started_at.slice(0, -3) === '00:00'
      && ruleCount.ended_at.slice(0, -3) === '23:59') {
      ruleCount['blocked'] = true;
    }

    return (
      <Box
        className={clsx(
          classes.tileContent,
          { [classes.activeTileContent]: date.getTime() === new Date(selectedDate.toLocaleDateString()).getTime() },
          { [classes.hasRuleTile]: ruleCount && !ruleCount?.blocked }
        )}
      >
        {date.getDate()}
        {ruleCount && !ruleCount?.blocked && (
          <Tooltip
            arrow
            placement="top"
            title={'Ilość reguł: ' + ruleCount?.count}
            aria-label={'Ilość reguł: ' + ruleCount?.count}
          >
            <Box className={classes.count}>
              <Box>{ruleCount?.count}</Box>
              {/* <Typography>{t('rules')}</Typography> */}
            </Box>
          </Tooltip>
        )}
        {ruleCount && ruleCount?.blocked && (
          <Typography className={classes.blockedDate}>{t('blocked')}</Typography>
        )}
      </Box>
    );
  };

  return (
    <>
      <Box className={classes.root}>
        <Box display="flex" alignItems="center" mb={2}>
          <Box mr={2}>
            <Link to={Routes.Units.List}>
              <PrevLabel />
            </Link>
          </Box>
          <Title>{t('units.work_hours')} ({unit?.name})</Title>
        </Box>
        <Card className={classes.card}>
          <Grid container>
            <Grid item xs={12} lg={8}>
              <ReactCalendar
                locale = {"pl-PL"}
                formatDay={() => null}
                onChange={setSelectedDate}
                onActiveStartDateChange={({
                  action,
                  activeStartDate,
                  value,
                  view,
                }) => getRulesCount(activeStartDate)}
                value={selectedDate}
                className={classes.calendar}
                locale="pl-PL"
                tileClassName={classes.tile}
                tileContent={({ date, activeStartDate }) => getTileContent(date)}
                nextLabel={<NextLabel />}
                prevLabel={<PrevLabel />}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <Box className={classes.rules}>
                <Box className={classes.ruleDate}>
                  {dayName}, {moment(selectedDate).format('DD.MM.YYYY')}
                  <Switch checked={blockDay} onChange={handleBlockDay} label={t('units.block_whole_day')} />
                </Box>
                <Box className={classes.ruleList}>
                  {rules?.map(item => (
                    <Box className={classes.rule} key={item.id}>
                      <Box>
                        <Typography className={classes.ruleTitle}>
                          {item.type === 'enabled' ? t('units.unblocked') : t('units.blocked')}
                        </Typography>
                        <Typography className={classes.ruleHours}>
                          {item.started_at.slice(0, -3)} - {item.ended_at.slice(0, -3)}
                        </Typography>
                      </Box>
                      <Box>
                        <IconButton
                          icon={DeleteIcon}
                          className={classes.ruleIconButton}
                          onClick={() => handleDelete(item.id)}
                        />
                        <IconButton
                          icon={EditIcon}
                          className={classes.ruleIconButton}
                          onClick={() => handleEdit(item)}
                        />
                      </Box>
                    </Box>
                  ))}
                  <Button className={classes.addBtn} onClick={() => setOpenModal(true)}>
                    <img src={CirclePlusIcon} alt="Add new rules" />
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Card>
      </Box>
      <RulesModal
        openModal={openModal}
        onClose={handleCloseModal}
        onSave={handleSave}
        editingData={editingData}
        dayName={dayName}
      />
    </>
  );
};

export default UnitCalendar;
