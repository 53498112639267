import React from "react";
import {
    Box,
    Button,
    TextField,
    makeStyles,
    Typography,
} from "@material-ui/core";
import TrashIcon from "assets/images/icons/trash.svg";

const useStyles = makeStyles((theme) => ({
    item: {
        "& > .MuiBox-root": {
            marginBottom: 20,
            display: "flex",
            alignItems: "center",
            "& .MuiTextField-root": {
                margin: theme.spacing(0, 2),
                "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #A6AEBF",
                    borderRadius: 8,
                },
            },
            "&:first-child": {
                "& .MuiTypography-root": {
                    fontFamily: "Lato",
                    fontSize: 16,
                    fontWeight: 700,
                    color: "#003366",
                },
                "& .MuiButton-root": {
                    height: 34,
                    background: "#D26360",
                    borderRadius: 8,
                    padding: theme.spacing(0, 2),
                    textTransform: "none",
                    display: "flex",
                    alignItems: "center",
                    "& .MuiTypography-root": {
                        color: "#FFF",
                        fontSize: 14,
                        marginLeft: 10,
                    },
                    "& img": {
                        height: 14,
                        filter: "brightness(0) invert(10)",
                    },
                },
            },
            "&:last-child": {},
        },
    },
}));

const AdditionalMedicineForm = (props) => {
    const classes = useStyles();
    return (
        <Box className={classes.root}>
            {props.medicines.map((item, index) => {
                return (
                    <Box className={classes.item} key={index}>
                        <Box>
                            <Typography>{index + 1}.</Typography>
                            <TextField
                                value={item.name}
                                variant="outlined"
                                onChange={(e) =>
                                    props.handleChange(index, "name", e.target.value)
                                }
                            />
                            <Button onClick={() => props.handleRemove(index)}>
                                <img src={TrashIcon} alt=":("/>
                                <Typography>Skasuj lek</Typography>
                            </Button>
                        </Box>
                        <Box>
                            <Typography>Dawka:</Typography>
                            <TextField
                                variant="outlined"
                                value={item.dose}
                                placeholder="Wprowadź dawkę"
                                onChange={(e) =>
                                    props.handleChange(index, "dose", e.target.value * 1)
                                }
                            />
                            <Typography>mg</Typography>
                        </Box>
                    </Box>
                );
            })}
        </Box>
    );
};

export default AdditionalMedicineForm;
