import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {
  Box,
  Button,
  Card,
  Grid,
  makeStyles,
  TextField,
  Typography,
  LinearProgress
} from "@material-ui/core";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  BrowserRouter as Router,
  useHistory,
  useParams,
  Link,
} from "react-router-dom";


import BackIcon from "assets/images/icons/back.svg";
import FileImage from "assets/images/pages/patient/file.png";
import SampleImage from "assets/images/pages/patient/sample.png";
import * as Actions from "../../../store/actions/CardAction";
import API from "../../../apis/API";
import Routes from "../../../router/Routes";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    padding: 0,
    [theme.breakpoints.up("lg")]: {
      padding: 32,
    },
  },
  card: {
    width: "100%",
    maxWidth: 1062,
    border: "none",
    borderRadius: 0,
    boxShadow: "none",
    [theme.breakpoints.up("lg")]: {
      borderRadius: 8,
      border: "1px solid #DDE3EE",
    },
  },
  cardHeader: {
    borderBottom: "1px solid #DDE3EE",
    display: "flex",
    alignItems: "center",
    padding: "8px 16px",
    [theme.breakpoints.up("lg")]: {
      padding: "38px 64px 32px",
    },
  },
  backButton: {
    minWidth: 48,
    width: 48,
    height: 48,
    border: "1px solid #DDE3EE",
    borderRadius: 8,
    marginRight: 14,
    [theme.breakpoints.up("lg")]: {
      border: "none",
    },
  },
  pageTitle: {
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: 16,
    color: "#454D5F",
    letterSpacing: -2,
    [theme.breakpoints.up("lg")]: {
      fontSize: 32,
    },
  },
  cardSubHeader: {
    borderBottom: "1px solid #DDE3EE",
    padding: "14px 16px",
    "& .MuiTypography-root": {
      fontFamily: "Lato",
      fontWeight: 700,
      color: "#003366",
    },
    "& .MuiBox-root": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "20px 64px",
      "& .MuiBox-root": {
        flexDirection: "row",
        alignItems: "center",
      },
    },
  },
  name: {
    fontSize: 20,
    marginBottom: 9,
    letterSpacing: -2,
    [theme.breakpoints.up("lg")]: {
      fontSize: 32,
      marginBottom: 17,
    },
  },
  date: {
    fontSize: 20,
    marginRight: 27,
    [theme.breakpoints.up("lg")]: {
      fontSize: 24,
    },
  },
  time: {
    fontSize: 16,
  },
  cardBody: {
    padding: 16,
    [theme.breakpoints.up("lg")]: {
      padding: "32px 64px 78px",
    },
  },
  label: {
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: 20,
    color: "#454D5F",
    marginBottom: 16,
    [theme.breakpoints.up("lg")]: {
      marginBottom: 24,
    },
  },
  sampling: {
    marginBottom: 74,
    "& > .MuiBox-root": {
      border: "1px solid #DDE3EE",
      borderRadius: 8,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "space-between",
      padding: 16,
      "& > .MuiBox-root": {
        display: "flex",
        alignItems: "center",
        "& > img": {
          marginRight: 38,
          width: 32,
        },
        "& > .MuiBox-root": {
          "& > .MuiTypography-root": {
            fontFamily: "Lato",
            fontWeight: 700,
            fontSize: 16,
            color: "#454D5F",
            marginBottom: 10,
            "&:first-child": {
              fontSize: 24,
              color: "#003366",
              marginBottom: 8,
            },
            "&:last-child": {
              color: "#003366",
              marginBottom: 0,
            },
          },
        },
      },
      "& .MuiButton-root": {
        width: "100%",
        border: "2px solid #003366",
        borderRadius: 8,
        height: 56,
        padding: "0 36px",
        fontFamily: "Lato",
        fontWeight: 700,
        fontSize: 16,
        color: "#003366",
        textTransform: "none",
      },
    },
    [theme.breakpoints.up("lg")]: {
      "& > .MuiBox-root": {
        padding: "30px 24px 40px 32px",
        flexDirection: "row",
        alignItems: "center",
        "& > img": {
          width: 61,
        },
        "& .MuiButton-root": {
          width: "auto!important",
        },
      },
    },
  },
  datetimepicker: {
    marginBottom: 34,
    "& .MuiTextField-root": {
      "& .MuiInputBase-input": {
        fontFamily: "Lato",
        fontSize: 16,
        color: "#22272F",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #A6AEBF",
        borderRadius: 8,
      },
    },
  },
  commentHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 16,
    "& .MuiTypography-root": {
      letterSpacing: -1,
      "&:last-child": {
        fontFamily: "Lato",
        fontWeight: 700,
        fontSize: 20,
        color: "#9F302D",
      },
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: 20,
    },
  },
  commentBody: {
    minHeight: 387,
    padding: "42px 28px",
    background: "#FFFFFF",
    border: "1px solid #DDE3EE",
    borderRadius: 8,
  },
  commentTitle: {
    fontFamily: "Lato",
    fontWeight: 500,
    fontSize: 21,
    color: "#454D5F",
    marginBottom: 8,
  },
  commentContent: {
    fontFamily: "Lato",
    fontSize: 16,
    color: "#454D5F",
    lineHeight: "26px",
  },
  parameter: {
    marginBottom: 17,
    "& .MuiGrid-item": {
      marginBottom: 34,
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      "& .MuiTypography-root": {
        fontFamily: "Lato",
        fontWeight: 700,
        fontSize: 16,
        color: "#6C7689",
        marginRight: 10,
      },
      "& .MuiTextField-root": {
        width: 100,
        "& .MuiOutlinedInput-input": {
          fontFamily: "Lato",
          fontSize: 16,
          fontWeight: 700,
          color: "#6C7689",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "1px solid #A6AEBF",
          borderRadius: 8,
        },
      },
      "&:nth-child(even)": {
        paddingLeft: 0,
      },
      "&:nth-child(odd)": {
        paddingRight: 0,
      },
    },
    [theme.breakpoints.up("lg")]: {
      "& .MuiGrid-item": {
        justifyContent: "space-between",
        "&:nth-child(even)": {
          paddingLeft: "40px!important",
        },
        "&:nth-child(odd)": {
          paddingRight: "40px!important",
        },
      },
    },
  },
  ekg: {
    marginBottom: 50,
    "& > .MuiBox-root": {
      "& img": {
        width: 32,
      },
      display: "flex",
      alignItems: "center",
      "& > .MuiBox-root": {
        marginLeft: 14,
        "& .MuiTypography-root": {
          fontFamily: "Lato",
          fontSize: 14,
          color: "#6C7689",
          "&:first-child": {
            marginBottom: 3,
            fontWeight: 700,
            color: "#003366",
            textDecoration: "underline",
          },
        },
      },
    },
    [theme.breakpoints.up("lg")]: {
      "& > .MuiBox-root": {
        "& img": {
          width: 61,
        },
        "& > .MuiBox-root": {
          marginLeft: 24,
        },
      },
    },
  },
  table: {
    marginBottom: 37,
  },
  trackButton: {
    width: "100%",
    height: 56,
    border: "2px solid #003366",
    borderRadius: 8,
    textTransform: "none",
    "& .MuiTypography-root": {
      fontFamily: "Lato",
      fontWeight: 700,
      fontSize: 16,
      color: "#003366",
      marginLeft: 16,
    },
    [theme.breakpoints.up("lg")]: {
      width: 217,
    }
  },
  trackLink: {
    width: "100%",
    marginTop: '8px',
    [theme.breakpoints.up("lg")]: {
      marginTop: '0px',
      width: 217,
    }
  },
}));


const VisitCardDetail = ({onBack}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {patient_id, id} = useParams();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();
  const {selectedVisit} = useSelector(state => state.card);

  useEffect(() => {
    // API.visits.show(id).then((res) => {
    API.visits.show(selectedVisit.id).then((res) => {
      setData(res.data.data);
      setIsLoading(false);
    });
  }, []);

  const handleDownloadFile = (fileID, filename) => {
    const blobInterceptor = API.interceptors.request.use(
        (config) => {
          config.responseType = "blob";
          return config;
        },
        function (error) {
          return Promise.reject(error);
        }
    );
    API.card.file.download(fileID).then((res) => {
      API.interceptors.request.eject(blobInterceptor);
      var fileURL = window.URL.createObjectURL(new Blob([res.data]));
      var fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", filename);
      document.body.appendChild(fileLink);
      fileLink.click();
    });
  };

  return (
    <Box className={classes.root}>
      <Card className={classes.card}>
        <Box className={classes.cardHeader}>
          {/* <Button className={classes.backButton} onClick={history.goBack}> */}
          <Button className={classes.backButton} onClick={onBack}>
            <img src={BackIcon} alt=":( Not Found" />
          </Button>
          <Typography className={classes.pageTitle}>Karta wizyty</Typography>
        </Box>

        {isLoading && <LinearProgress/>}
        {!isLoading && <>
        <Box className={classes.cardSubHeader}>
          <Typography className={classes.name}>{data?.patient?.surname+" "+data?.patient?.firstname} </Typography>
          <Box>
            <Typography className={classes.date}>
              Wizyta: {data?.date}
            </Typography>
            <Typography className={classes.time}>{data?.started_at} - {data?.ended_at}</Typography>
          </Box>
        </Box>
        <Box className={classes.cardBody}>



          <Box className={classes.parameter}>
            {data.results.length > 0 && <>
            <Typography className={classes.label}>Parametry EKG</Typography>
            <Grid container>
              <Grid item xs={6}>
                <Typography>QT</Typography>
                <TextField inputProps={{ readOnly: true, }} variant="outlined" value={data.results[0]?.qt} />
              </Grid>
              <Grid item xs={6}>
                <Typography>RR</Typography>
                <TextField inputProps={{ readOnly: true, }} variant="outlined" value={data.results[0]?.rr} />
              </Grid>
              <Grid item xs={6}>
                <Typography>PQ</Typography>
                <TextField inputProps={{ readOnly: true, }} variant="outlined" value={data.results[0]?.pq} />
              </Grid>
              <Grid item xs={6}>
                <Typography>QRS</Typography>
                <TextField inputProps={{ readOnly: true, }} variant="outlined" value={data.results[0]?.qrs} />
              </Grid>
              <Grid item xs={6}>
                <Typography>QTcB</Typography>
                <TextField inputProps={{ readOnly: true, }} variant="outlined" value={data.results[0]?.qtcb} />
              </Grid>
              <Grid item xs={6}>
                <Typography>QTcFra</Typography>
                <TextField inputProps={{ readOnly: true, }} variant="outlined" value={data.results[0]?.qtcfra} />
              </Grid>
            </Grid></>}
          </Box>

          <Box className={classes.ekg}>
            {(data.files && data.files.length > 0) && <Typography className={classes.label}>EKG</Typography>}

            {data.files?.map((file, index) => {
              return (
                  <Box>
                    <img src={FileImage} alt=":( Not Found" />
                    <Box>
                      <Typography style={{cursor: 'pointer'}} onClick={() =>
                          handleDownloadFile(file.id, file.original_name)
                      }>{file.original_name}</Typography>
                      <Typography>Dodano: {moment(file.created_at).format('DD.MM.YYYY hh:mm')}</Typography>
                    </Box>
                  </Box>)})}
          </Box>

          <Box className={classes.sampling}>
            <Typography className={classes.label}>Próbki</Typography>

              {data.probes?.map((probe) => {
                return (
                    <Box className={classes.formGroup} key={probe.id} style={{marginTop: 10}}>
                      <Box>
                        <img src={SampleImage} alt=":( Not Found" />
                        <Box>
                          <Typography>{probe.code}</Typography>
                          <Typography>
                            Próbka utworzona: {moment(probe.created_at).format('DD.MM.YYYY')}
                          </Typography>
                          <Typography>
                            {t("probes."+probe.status)}
                          </Typography>
                        </Box>
                      </Box>
                      <Link to={Routes.Probes.Show(probe.id)} className={classes.trackLink}>
                        <Button className={classes.trackButton}>Szczegóły</Button>
                      </Link>
                    </Box>
                )})}
          </Box>
          <Box className={classes.comment}>
            <Box className={classes.commentHeader}>
              <Typography className={classes.label} style={{ marginBottom: 0 }}>
                Komentarz:
              </Typography>
            </Box>
            <Box className={classes.commentBody} dangerouslySetInnerHTML={{ __html: data.notes.length > 0 ? data.notes[0].content : '' }}>

            </Box>

          </Box>
        </Box></>}
      </Card>
    </Box>
  );
};

export default VisitCardDetail;
