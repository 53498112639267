import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import ReactToPrint from "react-to-print";
import {
    Box,
    Button,
    Card,
    Dialog,
    makeStyles,
    Typography,
    CircularProgress,
    LinearProgress,
    Modal
} from "@material-ui/core";
import CreateAppointmentModal from "components/modals/CreateAppointmentModal";
import TrashIcon from "assets/images/icons/trash.svg";
import ClockPlusIcon from "assets/images/icons/time-add.svg";
import CloseIcon from "assets/images/icons/close.svg";
import PasswordIcon from "assets/images/icons/password.svg";
import * as Actions from "store/actions/CardAction";
import * as PatientActions from "store/actions/PatientAction";
import Personal from "../../../components/tabs/Personal";
import Medications from "../../../components/tabs/Medications";
import Visits from "../../../components/tabs/Visits/Visits";
import DoctorComments from "../../../components/tabs/DoctorComments/DoctorComments";
import Attachments from "../../../components/tabs/Attachments";
import Samples from "../../../components/tabs/Samples";
import Messages from "../../../components/tabs/Messages";
import CredentialsDocument from "../../../components/documents/CredentialsDocument";
import Routes from "router/Routes";
import MobileHeader from "components/navigation/MobileHeader";
import API from "apis/API";

const useStyles = makeStyles((theme) => ({
    root: {
        // paddingLeft: 423,
        display: "flex",
        [theme.breakpoints.down("md")]: {
            paddingLeft: 0,
            paddingBottom: theme.spacing(10),
        },
    },
    printPDF: {
        padding: theme.spacing(4),
    },
    cardContainer: {
        paddingTop: theme.spacing(16),
        width: "100%",
        display: "flex",
        justifyContent: "center",
        "& > .MuiCard-root": {
            width: "100%",
            minHeight: "100vh",
        },
        [theme.breakpoints.up("lg")]: {
            padding: theme.spacing(4),
            "& .MuiCard-root": {
                width: "100%",
                maxWidth: 1017,
            },
        },
    },
    card: {
        boxShadow: "none",
        [theme.breakpoints.up("lg")]: {
            border: "1px solid #DDE3EE",
            borderRadius: 8,
        },
    },
    cardHeader: {
        padding: theme.spacing(2),
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #DDE3EE",
        "& > .MuiBox-root:first-child": {
            display: "none",
        },
        [theme.breakpoints.up("lg")]: {
            padding: theme.spacing(6),
            display: "flex",
            "& > .MuiBox-root:first-child": {
                display: "block",
            },
        },
    },
    title: {
        fontFamily: "Lato",
        fontWeight: 700,
        fontSize: 32,
        color: "#003366",
        letterSpacing: "-2px",
        marginBottom: theme.spacing(2),
    },
    pesel: {
        fontFamily: "Lato",
        fontSize: 20,
        color: "#6C7689",
    },
    toolbar: {
        display: "flex",
        flexDirection: "row",
        "& > .MuiBox-root": {
            display: "flex",
        },
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            "& > .MuiBox-root": {
                "&:first-child": {
                    marginBottom: theme.spacing(2),
                    justifyContent: "space-between",
                    "& .MuiButton-root:last-child": {
                        marginRight: 0,
                    },
                },
                "&:last-child": {
                    "& .MuiButton-root": {
                        width: "100%",
                    },
                },
            },
        },
    },
    printButton: {
        width: 60,
        minWidth: 60,
        background: "#6C7689!important",
        borderRadius: 8,
        marginRight: 16,
    },
    deleteButton: {
        width: 60,
        minWidth: 60,
        height: 56,
        borderRadius: 8,
        background: "#D26360",
        "&:hover": {
            background: "#D26360",
        },
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: theme.spacing(2),
        "& img": {
            filter: "brightness(0) invert(1)",
        },
    },
    editButton: {
        width: 176,
        height: 56,
        borderRadius: 8,
        textTransform: "none",
        border: "2px solid #003366",
        background: "#FFF",
        fontFamily: "Lato",
        fontWeight: 700,
        fontSize: 16,
        color: "#003366",
        marginRight: theme.spacing(3),
    },
    appointmentButton: {
        width: 178,
        height: 56,
        textTransform: "none",
        borderRadius: 8,
        background: "#003366",
        "&:hover": {
            background: "#003366",
        },
        "& .MuiTypography-root": {
            marginLeft: theme.spacing(2),
            fontFamily: "Lato",
            fontWeight: 700,
            fontSize: 16,
            color: "#FFF",
        },
    },
    cardBody: {
        padding: theme.spacing(0, 2, 3),
        [theme.breakpoints.up("lg")]: {
            padding: theme.spacing(2, 6, 8, 6),
        },
    },
    tab: {
        height: 56,
        padding: "0 20px",
        fontFamily: "Lato",
        fontSize: 16,
        color: "#6C7689",
        textTransform: "none",
        letterSpacing: "-1px",
        borderRadius: 8,
        display: "none",
        [theme.breakpoints.up("lg")]: {
            display: "inline-flex",
        },
    },
    activeTab: {
        background: "#E5F2FF",
        fontWeight: 700,
        color: "#0066CC",
    },
    removeModal: {
        "& .MuiPaper-root": {
            width: 600,
            padding: theme.spacing(3, 4, 4),
            "& .MuiBox-root": {
                "&:first-child": {
                    display: "flex",
                    justifyContent: "flex-end",
                },
                "&:last-child": {
                    display: "flex",
                    justifyContent: "flex-end",
                },
            },
            "& .MuiTypography-root": {
                padding: theme.spacing(5, 0),
                fontFamily: "Lato",
                fontWeight: 700,
                fontSize: 16,
                color: "#454D5F",
            },
        },
    },
    yesBtn: {
        width: 140,
        height: 56,
        background: "#D26360",
        borderRadius: 8,
        color: "#FFF",
        fontFamily: "Lato",
        fontSize: 16,
        fontWeight: 700,
        marginLeft: theme.spacing(3),
        "&:hover": {
            background: "#D26360",
        },
    },
    noBtn: {
        width: 140,
        height: 56,
        background: "#FFF",
        border: "1px solid #003366",
        borderRadius: 8,
        color: "#003366",
        fontFamily: "Lato",
        fontSize: 16,
        fontWeight: 700,
    },
}));

const PatientCard = (props) => {
    const { patient_id } = props.match.params;
    const {t} = useTranslation();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const printPDFRef = useRef();
    const [filename, setFilename] = useState();
    const printButtonRef = useRef();
    const {selectedPatient, selectedPatientID, selectedTab} = useSelector(
        (state) => state.card
    );
    const [patientLoginInfo, setPatientLoginInfo] = useState({
        login: "",
        password: "",
    });
    const classes = useStyles();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [removeModalOpen, setRemoveModalOpen] = useState(false);
    const tabs = [
        {
            id: "personal",
            name: t("patients.personal_medical_data"),
            component: <Personal/>,
        },
        {
            id: "medications",
            name: t("patients.medication_taken"),
            component: <Medications/>,
        },
        {
            id: "visits",
            name: t("patients.visit"),
            component: <Visits/>,
        },
        {
            id: "comments",
            name: t("patients.doctor_comment"),
            component: <DoctorComments/>,
        },
        {
            id: "attachments",
            name: t("patients.attachment"),
            component: <Attachments/>,
        },
        {
            id: "samples",
            name: t("patients.sample"),
            component: <Samples/>,
        },
        {
            id: "messages",
            name: t("patients.message"),
            component: <Messages/>,
        },
    ];

    const [activeTab, setActiveTab] = useState(selectedTab || tabs[0]);
    const [isShowSidebar, setIsShowSidebar] = useState(true);
    const [showCreds, setShowCreds] = useState(false);

    useEffect(() => {
        dispatch(Actions.setSelectedPatientID(patient_id));
    }, [patient_id]);

    useEffect(() => {
        setActiveTab(selectedTab || tabs[0]);
    }, [selectedTab]);

    const handleClose = () => setOpen(false);

    const handleCloseRemoveModal = () => setRemoveModalOpen(false);

    const handleRemove = () => {
        dispatch(Actions.deletePatient(selectedPatientID));
        handleCloseRemoveModal();

        history.push(Routes.Patients.List);
    };

    const handleSelectTab = (data) => {
        setActiveTab(data);
        dispatch(Actions.setSelectedTab(data));
    };

    useEffect(() => {
        selectedPatientID &&
        dispatch(Actions.getSelectedPatientBasicData(selectedPatientID, true));
    }, [selectedPatientID]);

    const handleEdit = (data) => {
        dispatch(PatientActions.initializeUpdatePatientData(data));
        history.push(Routes.Patients.Edit(data.id));
    };

    useEffect(() => {
        if (window.innerWidth < 1280 && selectedPatientID) {
            setIsShowSidebar(false);
        }
        window.addEventListener("resize", () => {
            if (window.innerWidth > 1280) {
                setIsShowSidebar(true);
            }
        });
        window.scrollTo(0, 0);
    }, []);

    const handleResetPassword = () => {
        setIsLoading(true);
        API.patient.resetPassword(selectedPatientID).then((res) => {
            setFilename(res.data.login);
            setPatientLoginInfo(res.data);
            // printButtonRef.current.click();
            setShowCreds(true);
            setIsLoading(false);
        });
    };

    return (
        !selectedPatient ? <LinearProgress/> : <Box className={classes.root}>
            <MobileHeader title={activeTab?.name}/>
            {selectedPatient && (
                <Box className={classes.cardContainer}>
                    <Card className={classes.card}>
                        <Box className={classes.cardHeader}>
                            <Box>
                                <Typography className={classes.title}>
                                    {selectedPatient?.surname} {selectedPatient?.firstname}
                                </Typography>
                                <Typography className={classes.pesel}>
                                    PESEL: {selectedPatient?.pesel}
                                </Typography>
                            </Box>
                            <Box className={classes.toolbar}>
                                <Box>
                                    <Button
                                        className={classes.printButton}
                                        onClick={handleResetPassword}
                                    >
                                        {isLoading ? (
                                            <CircularProgress size={25} style={{color: "#FFF"}}/>
                                        ) : (
                                            <img src={PasswordIcon} alt=":( Not Found"/>
                                        )}
                                    </Button>
                                    <Button
                                        className={classes.deleteButton}
                                        onClick={() => setRemoveModalOpen(true)}
                                    >
                                        <img src={TrashIcon} alt=":( Not Found"/>
                                    </Button>
                                    <Button
                                        className={classes.editButton}
                                        onClick={() => handleEdit(selectedPatient)}
                                    >
                                        {t("patients.edit")}
                                    </Button>
                                </Box>
                                <Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box className={classes.cardBody}>
                            <Box>
                                {Object.keys(tabs).map((key) => {
                                    const tab = tabs[key];
                                    return (
                                        <Button
                                            className={`${classes.tab} ${
                                                activeTab.id === tab.id ? classes.activeTab : ``
                                            }`}
                                            onClick={() => handleSelectTab(tab)}
                                            key={key}
                                        >
                                            {tab.name}
                                        </Button>
                                    );
                                })}
                            </Box>
                            {activeTab.component}
                        </Box>
                    </Card>
                </Box>
            )}
            <CreateAppointmentModal open={open} handleClose={handleClose}/>
            <Dialog
                onClose={handleCloseRemoveModal}
                className={classes.removeModal}
                open={removeModalOpen}
            >
                <Box>
                    <img
                        src={CloseIcon}
                        alt=":( Not Found"
                        style={{cursor: "pointer"}}
                        onClick={handleCloseRemoveModal}
                    />
                </Box>
                <Typography>Czy na pewno chcesz usunąć dane tego pacjenta?</Typography>
                <Box>
                    <Button className={classes.noBtn} onClick={handleCloseRemoveModal}>
                        Nie
                    </Button>
                    <Button
                        className={classes.yesBtn}
                        onClick={() => handleRemove(selectedPatientID)}
                    >
                        Tak
                    </Button>
                </Box>
            </Dialog>
            <Box style={{width: 0, height: 0, overflow: "hidden"}}>
                <ReactToPrint
                    trigger={() => (
                        <button ref={printButtonRef} style={{display: "none"}}>
                            print
                        </button>
                    )}
                    content={() => printPDFRef.current}
                    documentTitle={filename}
                />
                <Box className={classes.printPDF} ref={printPDFRef}>
                    <Typography>Login: {patientLoginInfo.login}</Typography>
                    <Typography>Password: {patientLoginInfo.password}</Typography>
                </Box>
            </Box>
            <Modal open={showCreds} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <>
                    <CredentialsDocument creds={patientLoginInfo}/>
                    <Button
                        style={{
                            left: 10,
                            top: 10,
                            height: 60,
                            width: 50,
                            borderRadius: 40,
                            background: 'white',
                            position: 'absolute'
                        }}
                        //ToDo finish creds generator
                        onClick={() => setShowCreds(false)}
                    >
                        <img
                            src={CloseIcon}
                            alt=":( Not Found"
                            style={{cursor: "pointer", color: 'white'}}
                        />
                    </Button>
                </>
            </Modal>
        </Box>
    );
};

export default PatientCard;
