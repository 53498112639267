import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import Validator, {Email, LengthLimit, Required, validate} from "utils/Validator";
import TextInput from "components/form/TextInput";
import * as Actions from "store/actions/PatientAction";
import API from "apis/API";

const useStyles = makeStyles((theme) => ({
  formGroupContent: {
    marginTop: "0!important",
    marginBottom: "0!important",
  },
  formGroupTitle: {
    fontSize: 20,
    fontWeight: 700,
    color: "#454D5F",
    marginTop: 10,
    marginBottom: 24,
  },
  formGroupItem: {
    paddingTop: "0!important",
    paddingBottom: "8px!important",
  },
  radioGroup: {
    marginBottom: 10,
    "& .MuiTypography-root": {
      fontSize: 16,
      color: "#454D5F",
    },
  },
  checkbox: {
    marginBottom: 75,
    "& .MuiTypography-root": {
      fontSize: 16,
      fontWeight: 700,
      color: "#22272F",
      letterSpacing: "-0.5px",
    },
  },
  nextButton: {
    height: 56,
    background: "#003366",
    borderRadius: 8,
    fontSize: 16,
    fontWeight: 700,
    color: "#FFF",
    boxShadow: "none",
    "&:hover": {
      background: "#003366",
      boxShadow: "none",
    },
  },
}));

const Validators = {
  firstname: new Validator(Required),
  surname: new Validator(Required),
  pesel: new Validator(Required, LengthLimit(11)),
  phone_number: new Validator(Required, LengthLimit(9)),
  email: new Validator(Email),
};

const PersonalMedicalData = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const personal = useSelector((state) => state.patient.createPatient.personal);
  const [doctors, setDoctors] = useState([]);
  const [units, setUnits] = useState([]);
  const [errors, setErrors] = useState({});
  const [personalData, setPersonalData] = useState(
    personal || {
      firstname: "",
      surname: "",
      pesel: "",
      address_street: "",
      address_building: "",
      address_apartment: "",
      address_postcode: "",
      address_city: "",
      phone_number: "",
      email: "",
      weight: "",
      height: "",
      project_number: "",
      is_above_18: true,
      is_classified: true,
      acceptance: false,
      doctor_id: 1,
      unit_id: 1,
    }
  );
  const [birthday, setBirthday] = useState("");
  useEffect(() => {
    dispatch(Actions.getCriteriaGroupList());
    dispatch(Actions.getMedicineList());
    dispatch(Actions.getExclusionCriteriaGroup());

    API.units.all().then(res => setUnits(res.data.data));
  }, [dispatch]);

  useEffect(() => {
    API.doctors.all({ unit_id: personalData.unit_id }).then(res => setDoctors(res.data.data));
  }, [personalData.unit_id]);

  const handleChange = (e) => {
    setPersonalData({
      ...personalData,
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "pesel") {
      const code = peselDecode(e.target.value);
      setBirthday(code.valid ? moment(code.date).format("YYYY-MM-DD") : "");
    }
  };

  useEffect(() => {
    const code = peselDecode(String(personalData.pesel));
    setBirthday(code.valid ? moment(code.date).format("YYYY-MM-DD") : "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeAcceptance = (e) => {
    setPersonalData({ ...personalData, acceptance: e.target.checked });
  };

  const handleNext = () => {
    setErrors({});
    const formValidation = validate(personalData, Validators);
    if (formValidation) {
      setErrors(formValidation);
      return;
    }
    if (!personalData.acceptance) {
      addToast("Pacjent musi wyrazić zgodę na przetwarzanie danych osobowych", {
        appearance: "warning",
      });
      return;
    }
    API.patient.validatePeselCode({ pesel: personalData.pesel }).then((res) => {
      if (res.data.status) {
        dispatch(Actions.setPersonalData(personalData));
        props.handleNext();
      } else {
        addToast("Pacjent o podanym numerze PESEL istnieje już w bazie.", {
          appearance: "warning",
        });
      }
    });
  };

  const peselDecode = (pesel) => {
    var rok = parseInt(pesel.substring(0, 2), 10);
    var miesiac = parseInt(pesel.substring(2, 4), 10) - 1;
    var dzien = parseInt(pesel.substring(4, 6), 10);
    if (miesiac > 80) {
      rok = rok + 1800;
      miesiac = miesiac - 80;
    } else if (miesiac > 60) {
      rok = rok + 2200;
      miesiac = miesiac - 60;
    } else if (miesiac > 40) {
      rok = rok + 2100;
      miesiac = miesiac - 40;
    } else if (miesiac > 20) {
      rok = rok + 2000;
      miesiac = miesiac - 20;
    } else {
      rok += 1900;
    }
    var urodzony = new Date();
    urodzony.setFullYear(rok, miesiac, dzien);
    var wagi = [9, 7, 3, 1, 9, 7, 3, 1, 9, 7];
    var suma = 0;
    for (var i = 0; i < wagi.length; i++) {
      suma += parseInt(pesel.substring(i, i + 1), 10) * wagi[i];
    }
    suma = suma % 10;
    var valid = suma === parseInt(pesel.substring(10, 11), 10);
    var plec = "k";
    if (parseInt(pesel.substring(9, 10), 10) % 2 === 1) {
      plec = "m";
    }
    return { valid: valid, sex: plec, date: urodzony };
  };

  return (
    <Box>
      <Box>
        <Typography className={classes.formGroupTitle}>
          Dane podstawowe
        </Typography>
        <Grid container className={classes.formGroupContent} spacing={3}>
          <Grid item xs={12} lg={6} className={classes.formGroupItem}>
            <TextInput
              error={errors.hasOwnProperty("firstname")}
              label="Imię (Imiona)*"
              value={personalData.firstname}
              name="firstname"
              helperText={errors?.firstname}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={6} className={classes.formGroupItem}>
            <TextInput
              error={errors.hasOwnProperty("surname")}
              label="Nazwisko*"
              value={personalData.surname}
              name="surname"
              helperText={errors?.surname}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={6} className={classes.formGroupItem}>
            <TextInput
              type="text"
              error={errors.hasOwnProperty("pesel")}
              label="PESEL*"
              value={personalData.pesel}
              name="pesel"
              helperText={errors?.pesel}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={6} className={classes.formGroupItem}>
            <TextInput label="Data urodzenia" value={birthday} disabled />
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.formGroup}>
        <Typography className={classes.formGroupTitle}>
          Adres zamieszkania
        </Typography>
        <Grid container className={classes.formGroupContent} spacing={3}>
          <Grid item xs={12} lg={6} className={classes.formGroupItem}>
            <TextInput
              error={errors.hasOwnProperty("address_street")}
              label="Ulica"
              value={personalData.address_street}
              name="address_street"
              helperText={errors?.address_street}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={3} className={classes.formGroupItem}>
            <TextInput
              error={errors.hasOwnProperty("address_building")}
              type="number"
              label="Budynek"
              value={personalData.address_building}
              name="address_building"
              helperText={errors?.address_building}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={3} className={classes.formGroupItem}>
            <TextInput
              type="number"
              label="Lokal (opcjonalne)"
              value={personalData.address_apartment}
              name="address_apartment"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={6} className={classes.formGroupItem}>
            <TextInput
              error={errors.hasOwnProperty("address_postcode")}
              label="Kod pocztowy"
              value={personalData.address_postcode}
              name="address_postcode"
              helperText={errors?.address_postcode}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={6} className={classes.formGroupItem}>
            <TextInput
              error={errors.hasOwnProperty("address_city")}
              label="Miasto"
              value={personalData.address_city}
              name="address_city"
              helperText={errors?.address_city}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.formGroup}>
        <Typography className={classes.formGroupTitle}>
          Dane kontaktowe
        </Typography>
        <Grid container className={classes.formGroupContent} spacing={3}>
          <Grid item xs={12} lg={6} className={classes.formGroupItem}>
            <TextInput
              error={errors.hasOwnProperty("phone_number")}
              label="Numer telefonu*"
              value={personalData.phone_number}
              name="phone_number"
              helperText={errors?.phone_number}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={6} className={classes.formGroupItem}>
            <TextInput
              error={errors.hasOwnProperty("email")}
              type="email"
              label="Adres e-mail (opcjonalne)"
              value={personalData.email}
              name="email"
              onChange={handleChange}
              helperText={errors?.email}
            />
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.formGroup}>
        <Typography className={classes.formGroupTitle}>
          Dane antropometryczne
        </Typography>
        <Grid container className={classes.formGroupContent} spacing={3}>
          <Grid item xs={12} lg={6} className={classes.formGroupItem}>
            <TextInput
              type="number"
              label="Wzrost"
              value={personalData.height}
              name="height"
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">cm</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} lg={6} className={classes.formGroupItem}>
            <TextInput
              type="number"
              label="Waga (kg)"
              value={personalData.weight}
              name="weight"
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">kg</InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.formGroup}>
        <Typography className={classes.formGroupTitle}>
          Wybór lekarza i ośrodka
        </Typography>
        <Grid container className={classes.formGroupContent} spacing={3}>
          <Grid item xs={12} lg={6} className={classes.formGroupItem}>
            <Typography className={classes.formControlTitle}>
              Wybierz lekarza prowadzącego
            </Typography>
            <Select
              value={personalData.doctor_id}
              fullWidth
              variant="outlined"
              className={classes.formControlSelectBox}
              onChange={handleChange}
              name="doctor_id"
              helperText={errors?.doctor_id}
              error={errors.hasOwnProperty("doctor_id")}
            >
              {doctors?.map((doctor) => {
                return (
                  <MenuItem key={doctor.id} value={doctor.id}>
                    {doctor.firstname} {doctor.surname}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          <Grid item xs={12} lg={6} className={classes.formGroupItem}>
            <Typography className={classes.formControlTitle}>
              Ośrodek prowadzący
            </Typography>
            <Select
              value={personalData.unit_id}
              fullWidth
              variant="outlined"
              className={classes.formControlSelectBox}
              onChange={handleChange}
              name="unit_id"
              helperText={errors?.unit_id}
              error={errors.hasOwnProperty("unit_id")}
            >
              {units?.map((unit) => {
                return (
                  <MenuItem key={unit.id} value={unit.id}>
                    {unit.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.formGroup}>
        <Typography className={classes.formGroupTitle}>
          Zgoda pacjenta
        </Typography>
        <Box className={classes.formGroupContent}>
          <FormControlLabel
            className={classes.checkbox}
            control={
              <Checkbox
                checked={personalData.acceptance}
                onChange={handleChangeAcceptance}
                color="primary"
              />
            }
            label="Potwierdzam, że pacjent udzielił zgodę na udział w programie"
          />
        </Box>
      </Box>
      <Button
        className={classes.nextButton}
        onClick={() => handleNext()}
        fullWidth
        variant="contained"
        color="primary"
      >
        Następny krok
      </Button>
    </Box>
  );
};

export default PersonalMedicalData;
