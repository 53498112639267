import React from "react";
import {makeStyles, Typography, Select as MuiSelect, Box, MenuItem} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    title: {
        marginBottom: theme.spacing(1)
    },
    select: {
        display: "flex",
        alignItems: "center",
        marginBottom: theme.spacing(2),
        "& > .MuiTypography-root": {
            fontSize: 14,
            color: "#454D5F",
            marginRight: 6,
        },
        "& .MuiSelect-root": {
            display: "flex",
            alignItems: "center",
            minWidth: 60,
            height: 40,
            color: "#181819",
            fontSize: 14,
        },
        "& .MuiOutlinedInput-input": {
            padding: theme.spacing(0, 1),
            boxSizing: "border-box",
        },
    },
    fullWidth: {
        width: "100%"
    }
}));

const Select = ({
                    title,
                    options,
                    labelField = 'label',
                    valueField = 'value',
                    labelSuffix = '',
                    fullWidth = false,
                    containerClassName,
                    renderValue,
                    customOption,
                    ...rest
                }) => {
    const classes = useStyles();

    return (
        <Box className={clsx(classes.select, {[classes.fullWidth]: fullWidth}, containerClassName)}>
            {title && <Typography>{title}: </Typography>}
            {!!customOption ? (
                customOption
            ) : (
                <MuiSelect
                    variant="outlined"
                    fullWidth={fullWidth}
                    {...rest}
                >
                    {
                        options.map(option => (
                            <MenuItem key={option[valueField]} value={option[valueField]}>
                                {Array.isArray(labelField)
                                    ? labelField.map((field, i) => <React.Fragment
                                        key={i}>{option[field]} </React.Fragment>)
                                    : option[labelField]
                                }
                                {labelSuffix && !option?.disabledLabelSuffix && labelSuffix}
                            </MenuItem>
                        ))
                    }
                </MuiSelect>
            )}
        </Box>
    );
};

export default Select;
